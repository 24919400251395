import styled, { css } from 'styled-components';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

export const navUnderlineHighlightCss = css`
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.25s ease-in-out 0s;
    transition: all 0.25s ease-in-out 0s;
    border-radius: 2px;
    top: 25px;

    background: ${resolveThemeValue(tokens.color.text.textLink)};
    height: 2px;
  }

  &:hover {
    &::before {
      visibility: visible;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }

    &.logout {
      color: #fd253f;
    }
  }
`;

const NavItem = styled.a<{ isActive?: boolean }>`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${(props) => (props.isActive ? ' #1f2244' : 'var(--color-dark-grey)')};

  margin-left: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;

  position: relative;
  text-decoration: none;
  transition: all 0.25s ease;
  white-space: nowrap;
  display: flex !important;
  align-items: center !important;

  .isFWC & {
    ${navUnderlineHighlightCss};
  }

  &:hover {
    color: #1f2244;
  }

  &::before {
    visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
    transform: ${(props) => (props.isActive ? 'scaleX(1)' : 'scaleX(0)')};
    -webkit-transform: ${(props) => (props.isActive ? 'scaleX(1)' : 'scaleX(0)')};
  }

  &.isLightColor {
    color: ${resolveThemeValue(tokens.color.text.textButton, 'var(--color-beige)')};

    &:hover {
      color: ${resolveThemeValue(tokens.color.text.textButton, 'var(--color-beige)')};
    }

    &::before {
      background-color: ${resolveThemeValue(tokens.color.buttons.buttonPrimary, 'white')};
    }
  }

  &.logout {
    display: flex;
    align-items: center;
    color: #fd253f !important;

    span {
      margin-left: 8px;
    }
  }

  .languageIcon {
    margin-right: 8px;
  }

  span.iconRight {
    margin-left: auto;
  }

  span.iconLeft {
    margin-right: 12px;
  }
`;

const UserNavItem = styled.span`
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${resolveThemeValue(tokens.color.text.textPrimary, '#1f2244')};
  margin-left: 8px;
  cursor: pointer;
  text-decoration: none;
  .isCAMPIRI & {
    transition: all 0.25s ease;
  }

  margin-top: 3px;

  &.darkText {
    color: #1f2244;
  }

  &:hover {
    color: #1f2244;
  }

  &.isLightColor {
    color: ${resolveThemeValue(tokens.color.text.textButton, 'var(--color-beige)')};

    &:hover {
      color: ${resolveThemeValue(tokens.color.text.textButton, 'var(--color-beige)')};
    }

    &::before {
      background-color: white;
    }
  }
`;

export { NavItem, UserNavItem };
