import styled, { css } from 'styled-components';

import { motion } from 'framer-motion';
import { deviceMinWidth } from '~/src/styles/breakpoints';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

export const MenuContentWrapper = styled.div<{
  isBackgroundFilled?: boolean;
  isDividerDisplayedAtMobileView?: boolean;
  isLarge?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: ${({ isLarge }) => (isLarge ? 'fit-content' : '312px')};

  :not(:first-of-type) {
    padding: 0 0 24px 0;
  }

  @media ${deviceMinWidth.phone} {
    padding: 24px;

    :not(:first-of-type) {
      padding: 24px;
    }
  }

  ${({ isDividerDisplayedAtMobileView }) =>
    isDividerDisplayedAtMobileView &&
    css`
      :last-of-type {
        border-bottom: 1px solid #edeae3;
      }

      @media ${deviceMinWidth.phone} {
        border-bottom: none;
      }
    `}

  ${({ isBackgroundFilled }) =>
    isBackgroundFilled &&
    css`
      @media ${deviceMinWidth.phone} {
        background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, '#f9f8f6')};
      }
    `}
`;

export const MobileMenuContentWrapper = styled.div<{ isDividerDisplayed?: boolean }>`
  ${({ isDividerDisplayed }) =>
    isDividerDisplayed &&
    css`
      border-bottom: 1px solid #edeae3;
    `}

  display: flex;
  flex-direction: column;

  :not(:first-of-type) {
    padding: 0 0 24px 0;
  }
`;

export const HeadingBase = styled.h3`
  margin: 16px 0;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${resolveThemeValue(tokens.color.text.textSecondary, '#8e7e7f')};
`;

export const MenuContentHeading = styled(HeadingBase)`
  @media ${deviceMinWidth.phone} {
    margin: 0 0 20px 0;
  }
  .isFWC & {
    color: ${resolveThemeValue(tokens.color.text.textSecondary)};
  }
`;

export const ExperiencesHeading = styled(HeadingBase)`
  margin: 16px 0;
`;

export const LeadHeading = styled(HeadingBase)`
  margin: 20px 0;
`;

export const ComplementaryHeading = styled(HeadingBase)`
  margin: 24px 0 20px;
`;

export const GridContainer = styled(motion.div)<{ onlyOneColumn: boolean }>`
  display: grid;
  grid-template-columns: ${({ onlyOneColumn }) => (onlyOneColumn ? 'max-content' : 'max-content 312px')};
  user-select: none;
`;
