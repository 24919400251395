/* eslint-disable max-len */
import styled, { css } from 'styled-components';
import { PrimaryButton } from '~/src/styles/buttons';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { staticBodyTextStyles } from 'ui/components/primitives/typography/CustomTypography';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const FilterLabel = styled.div<{ isModal?: boolean; isDynamicSegmentVersion?: boolean }>`
  padding: ${(props) => (props.isModal ? '8px' : '8px 16px')};
  border-radius: ${resolveThemeValue(tokens.radius.radiusL, '4px')};
  border: solid 1px ${resolveThemeValue(tokens.color.border.border, 'var(--color-beige)')};
  background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceBg, 'var(--color-white)')};
  transition: 300ms all;
  cursor: pointer;
  font-family: var(--font-poppins);
  font-size: ${(props) => (props.isModal ? '12px' : '14px')};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  display: flex;
  align-items: center;
  color: #1f2244;
  white-space: nowrap;
  position: relative;

  &.selected {
    color: black;
  }

  &.active {
    background-color: var(--color-beige);
  }

  > span {
    width: 6px;
    height: 6px;
    position: absolute;
    right: 4px;
    top: 4px;
    border-radius: 3px;
    background-color: var(--color-green);
  }

  ${({ isDynamicSegmentVersion }) =>
    isDynamicSegmentVersion &&
    css`
      font-size: 16px;
    `}
  &.disabled {
    background-color: var(--color-beige);
  }

  &:hover {
    border: 1px solid var(--support-grey-300, #dad1d1);
  }

  .isFWC & {
    &::after {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      margin-left: 12px;
      background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21"><path fill="%231F2244" fill-rule="evenodd" d="M.183 6.205a.625.625 0 0 1 .884 0L10 15.138l8.933-8.933a.625.625 0 1 1 .884.884l-8.933 8.932a1.25 1.25 0 0 1-1.768 0L.183 7.09a.625.625 0 0 1 0-.884Z" clip-rule="evenodd"/></svg>')
        no-repeat;
    }
  }
`;

const FilterContentWrapper = styled.div`
  position: relative;
  z-index: 9999;
`;

const FilterWrapper = styled.div<{ leftOffset?: number }>`
  padding: 16px 0 0;
  min-width: 360px;
  position: absolute;
  overflow: auto;
  top: 5px;
  background-color: white;
  z-index: 9;
  cursor: pointer;
  box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
  border-radius: 8px;
  display: none;
  left: ${(props) => (props.leftOffset ? `${props.leftOffset}px` : 0)};
  max-height: 50vh;

  &.carTypeVersion {
    @media only screen and (min-width: 500px) {
      min-width: 440px;
    }
  }

  @media only screen and (max-width: 1100px) {
    box-shadow: 0 20px 32px 0 rgba(31, 34, 68, 0.2);
    width: 100%;

    left: 0;
    top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 15px;
  }

  ${Wrapper}.active & {
    display: block;
  }

  &.calendarVersion {
    min-height: 500px;
    width: 700px;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }

  &.locationVersion {
    height: 360px;
    width: 360px;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }

  &.fitWidth {
    width: fit-content;
    height: fit-content;
    min-height: unset;
    cursor: initial;

    @media only screen and (max-width: 1100px) {
      width: 100%;
    }
  }
`;

const FilterName = styled.h4`
  font-family: var(--font-poppins);
  font-size: 16px;
  padding: 0 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  /* margin-bottom: 20px; */
  letter-spacing: normal;
  color: var(--color-dark-grey);
`;

const FilterContent = styled.div`
  padding: 16px 16px;
  position: relative;
  z-index: 999;

  &.extra-margin {
    > div > div {
      margin: 30px 0;
    }

    > div > div:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const FilterActions = styled.div`
  border-top: 1px solid ${resolveThemeValue(tokens.color.border.border, 'var(--color-beige)')};
  padding: 16px 16px 16px;
  position: sticky;
  z-index: 9999;
  bottom: 0;
  background: white;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  button {
    max-width: 165px;
  }
`;

const ShowResultsButton = styled(PrimaryButton)``;

const ClearFilter = styled.span`
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: ${resolveThemeValue(tokens.color.text.textError, 'var(--color-error-red)')};

  .isFWC & {
    ${staticBodyTextStyles('lBold')}
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  z-index: 999;

  > div {
    &:first-of-type {
      margin-top: 0 !important;
    }

    margin-top: 16px;
  }
`;

const InputWrapper = styled.div`
  > div {
    &::first-of-type {
      margin-top: 0;
    }

    margin-top: 16px;
  }
`;

const SearchPlaceholder = styled.div`
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #1f2244;
  opacity: 0.5;
`;

const SearchInputValue = styled.div`
  margin-top: 8px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #1f2244;

  &.hasValue {
    color: var(--color-dark-blue);
  }
`;

const ValueCleaner = styled.div`
  position: absolute;
  right: 16px;
  top: 20px;
  font-family: var(--font-poppins);
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: var(--color-error-red);
  text-decoration: underline;
`;

const DisabledWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: center;
`;
const DisabledIconWrapper = styled.div``;

const DisabledHeading = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #1f2244;
  width: 100%;
  margin: 20px 0px;
`;

const DisabledCTA = styled.div`
  width: 100%;
`;

export {
  Wrapper,
  FilterLabel,
  FilterWrapper,
  FilterName,
  FilterContent,
  FilterActions,
  ShowResultsButton,
  ClearFilter,
  SelectWrapper,
  InputWrapper,
  FilterContentWrapper,
  SearchPlaceholder,
  SearchInputValue,
  ValueCleaner,
  DisabledWrapper,
  DisabledIconWrapper,
  DisabledHeading,
  DisabledCTA,
};
