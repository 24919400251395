import { FC, useEffect } from 'react';
import { Trans } from '@lingui/react';
import { useSelector } from 'react-redux';
import { LeadHeading, MobileMenuContentWrapper, ComplementaryHeading } from '../menu.styles';
import { MenuDescriptionLink } from '../MenuDescriptionLink';

import { languageSelector } from '~/src/redux/selectors/settings';
import { getCountryLocaleSupportLinks } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/supportMenu/getCountryLocaleSupportLinks';
import { useCodebase } from 'features/hooks/useCodebase';
import { trackMainMenu } from '~/src/utils/dataLayer';
import { t } from '@lingui/macro';
import { FlexCol } from 'ui/primitives/flex';
import tokens from 'ui/design/tokens/tokens';

export const SupportMenuMobile: FC = () => {
  const lang = useSelector(languageSelector);
  const isFWC = useCodebase('fwc');
  const { companyLinks, resourcesLinks } = getCountryLocaleSupportLinks(lang);

  useEffect(() => trackMainMenu({ text: t({ id: 'tabs.support' }) }), []);

  return (
    <>
      <MobileMenuContentWrapper>
        <LeadHeading>
          {isFWC ? (
            <Trans id="menu.fwc.resources" message="Resources" />
          ) : (
            <Trans id="menu.resources" message="Resources" />
          )}
        </LeadHeading>
        <FlexCol $gap={tokens.spacing.spacingL}>
          {resourcesLinks.map((data, index) => (
            <MenuDescriptionLink
              boldText={data.boldText}
              href={data.href}
              leftIcon={data.leftIcon}
              key={data.id}
              isDisplayedAsMobileVersion={true}
            />
          ))}
        </FlexCol>
      </MobileMenuContentWrapper>

      <MobileMenuContentWrapper>
        <ComplementaryHeading>
          {isFWC ? <Trans id="menu.fwc.company" message="Company" /> : <Trans id="menu.company" message="Company" />}
        </ComplementaryHeading>
        <FlexCol $gap={tokens.spacing.spacingL}>
          {companyLinks.map((data, index) => {
            if (data.hash === '#job') {
              return (
                <MenuDescriptionLink
                  boldText={data.boldText}
                  href={data.href}
                  hash={data.hash}
                  key={data.id}
                  dataTestId="menu-mission-job"
                  isDisplayedAsMobileVersion={true}
                />
              );
            }
            return (
              <MenuDescriptionLink
                boldText={data.boldText}
                href={data.href}
                hash={data.hash}
                key={data.id}
                isDisplayedAsMobileVersion={true}
              />
            );
          })}
        </FlexCol>
      </MobileMenuContentWrapper>
    </>
  );
};
