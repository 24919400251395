import React, { ReactElement, useEffect, useState } from 'react';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import styled, { css } from 'styled-components';
import { resolveThemeValue } from '../design/utils';
import tokens from '../design/tokens/tokens';

interface Props {
  text: string | React.ReactNode | ReactElement;
  heading: string | ReactElement;
  containerID?: string;
  children?: any;
  childrenLineHeight?: number;
  hoverTrigger?: boolean;
  placementProp?: string;
  closeIcon?: boolean;
  id?: string;
  isVisibleProp?: boolean;
  disabled?: boolean;
}

function ToolTip({
  text,
  heading,
  containerID,
  children = null,
  childrenLineHeight = undefined,
  hoverTrigger = true,
  placementProp = 'top',
  closeIcon = true,
  isVisibleProp = false,
  disabled = false,
}: Props) {
  const [isVisible, setIsVisible] = useState<boolean>(isVisibleProp);

  useEffect(() => {
    setIsVisible(isVisibleProp);
  }, [isVisibleProp]);

  if (disabled) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Wrapper style={{ lineHeight: `${childrenLineHeight}px` }}>
      <Tooltip
        visible={isVisible}
        onVisibleChange={() => {
          setIsVisible(!isVisible);
        }}
        placement={placementProp}
        trigger={hoverTrigger ? ['hover'] : ['click']}
        {...(containerID && {
          getTooltipContainer: () => document.getElementById(containerID) as HTMLElement,
        })}
        overlay={
          <ToolTipContent>
            {closeIcon ? (
              <CloseWrapper>
                <Heading closeIcon>{heading}</Heading>
                <CloseButton onClick={() => setIsVisible(false)} className="icon-close" />
              </CloseWrapper>
            ) : (
              <Heading>{heading}</Heading>
            )}

            <Content>{text}</Content>
          </ToolTipContent>
        }
      >
        {children || <Icon className="icon-info" />}
      </Tooltip>
    </Wrapper>
  );
}

const Wrapper = styled.span`
  position: relative;
  vertical-align: middle;
`;
const CloseWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;
const CloseButton = styled.i`
  font-size: 12px;
  color: var(--color-white);
`;

const ToolTipContent = styled.div`
  background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceSection, '#1f2244')} !important;
  font-family: var(--font-poppins);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: ${resolveThemeValue(tokens.color.text.textContrastSecondary, 'var(--color-white)')};
  display: flex;
  flex-direction: column;
  font-size: 12px;
`;

const Heading = styled.div<{ closeIcon?: boolean }>`
  font-weight: 600;
  line-height: 1.33;
  color: var(--color-beige);
  font-family: var(--font-poppins);
  ${({ closeIcon }) =>
    !closeIcon &&
    css`
      margin-bottom: 8px;
    `}
`;
const Content = styled.div`
  font-family: var(--font-poppins);
`;

const Icon = styled.i`
  margin-left: 12px;
  cursor: pointer;
`;
export default ToolTip;
