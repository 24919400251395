/* eslint-disable max-len */
import { getLayoutFillNextImage } from 'features/utils/images';
import ImageAvatar from '~/src/components/layout/headers/icons-and-images/ImageAvatar';
import ImageAvatarLight from '~/src/components/layout/headers/icons-and-images/ImageAvatarLight';
import { openDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { ModalType } from '~/src/components/modal/modalTypes';
import ImageLoginDark from '~/src/components/layout/headers/icons-and-images/ImageLoginDark';
import ImageLogin from '~/src/components/layout/headers/icons-and-images/ImageLogin';
import { navUnderlineHighlightCss, UserNavItem } from '~/src/components/layout/headers/styles';
import { Trans } from '@lingui/react';
import { LoginButton } from '~/src/components/layout/headers/navigation-menu/V2/LoginButton';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '~/src/redux/selectors/user';
import { screenSelector } from 'features/redux/selectors/screen';
import UserMenu from '~/src/components/layout/headers/UserMenu';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { useCodebase } from 'features/hooks/useCodebase';

const loginArrowIcon = (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.14643 0.520456C0.341683 0.325185 0.658266 0.32517 0.853537 0.520423L8 7.66622L15.1465 0.520423C15.3417 0.32517 15.6583 0.325185 15.8536 0.520456C16.0488 0.715727 16.0488 1.03231 15.8535 1.22756L8.70714 8.37329C8.70705 8.37338 8.70696 8.37347 8.70687 8.37356C8.61411 8.46638 8.50398 8.54003 8.38276 8.59029C8.26142 8.6406 8.13135 8.6665 8 8.6665C7.86865 8.6665 7.73858 8.6406 7.61724 8.59029C7.49602 8.54003 7.38589 8.46638 7.29313 8.37356C7.29304 8.37347 7.29295 8.37338 7.29286 8.37329L0.146463 1.22756C-0.0488081 1.03231 -0.0488229 0.715727 0.14643 0.520456Z"
      fill="#1F2244"
    />
  </svg>
);

interface Props {
  messagesCount?: number;
  messagingEnabled?: boolean;
  isWhiteVersion?: boolean;
  isBookingPage?: boolean;
  isRenterPage?: boolean;
  isOperatorPage?: boolean;
}

export default function UserMenuDropdown({
  messagingEnabled = true,
  messagesCount = 0,
  isBookingPage = false,
  isWhiteVersion = false,
  isRenterPage = false,
  isOperatorPage = false,
}: Props) {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const userMenuRef = useRef<HTMLDivElement | undefined>();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const screen = useSelector(screenSelector);

  return (
    <HPLoginWrapper>
      <div ref={userMenuRef} className="user" data-testid="nav-login-section">
        {user.isAuthenticated ? (
          <UserIcon
            data-testid="nav-user-icon"
            showMessagesCount={!!(messagesCount && messagingEnabled)}
            onClick={(e) => {
              setShowUserMenu((prev) => !prev);
            }}
            className={`
                            ${user.customerData.profilePicture ? 'hasPic' : ''}
                            ${isWhiteVersion || isBookingPage ? 'light' : ''}
                            userClickableDiv
                            `}
          >
            {user.customerData.profilePicture ? (
              <UserProfilePictureWrapper>
                {getLayoutFillNextImage(user.customerData.profilePicture, 'profilePic', 'profilePic', '32px')}
              </UserProfilePictureWrapper>
            ) : (
              <ImgWrapper>{isWhiteVersion || isBookingPage ? <ImageAvatar /> : <ImageAvatarLight />}</ImgWrapper>
            )}

            <LoginArrow className={showUserMenu ? 'up' : ''}>{loginArrowIcon}</LoginArrow>
          </UserIcon>
        ) : (
          // todo : AB test to switch new icon for the old one
          <>
            <Flex
              id="ab_menu_test_login_btn_old"
              onClick={() => {
                openDesktopOrPhoneModal(ModalType.LOGIN, screen, dispatch);
              }}
            >
              <ImgWrapper>{isWhiteVersion ? <ImageLoginDark /> : <ImageLogin />}</ImgWrapper>

              <UserNavItem className={`${isWhiteVersion ? '' : 'isLightColor'} `}>
                <span>
                  <Trans id="labels.login" message="Přihlášení" />
                </span>
              </UserNavItem>
            </Flex>
            <LoginButton screen={screen} isLightColorVersion={!isWhiteVersion} />
          </>
        )}
        {showUserMenu && (
          <UserMenu
            menuRef={userMenuRef}
            setShowUserMenu={setShowUserMenu}
            isRenterPage={isRenterPage}
            isOperatorPage={isOperatorPage}
            messagesCount={messagesCount}
            messagingEnabled={messagingEnabled}
          />
        )}
      </div>
    </HPLoginWrapper>
  );
}

const Flex = styled.div`
  display: flex;

  .isFWC & {
    ${navUnderlineHighlightCss};
  }

  &.menuAbTestHidden {
    display: none;
  }
`;

const LoginWrapper = styled.div`
  a {
    cursor: pointer;
    display: block;
  }
`;

const HPLoginWrapper = styled(LoginWrapper)`
  .user {
    display: flex;

    img {
      cursor: pointer;
    }
  }

  a {
    margin: auto 0 auto 10px;
  }
`;

const ImgWrapper = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
export const UserIcon = styled.div<{ showMessagesCount: boolean }>`
  display: flex;
  border-radius: 16px;
  width: 64px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  background: #1f2244;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ff5e55;
    border: 2px solid #1f2244;
    position: absolute;
    right: -2px;
    top: -2px;
    display: ${(p) => (p.showMessagesCount ? 'block' : 'none')};
  }

  &.light {
    background: #edeae3;

    .isFWC & {
      background: ${resolveThemeValue(tokens.color.backgrounds.disabled)};
    }

    &::after {
      border: 2px solid #edeae3;
    }
  }

  &.hasPic {
    justify-content: inherit;
  }
`;

const LoginArrow = styled.div`
  margin-left: 8px;
  transform: all linear 0.3;
  pointer-events: none;

  svg path {
    fill: white;
  }

  &.up {
    transform: rotate(180deg);
  }

  ${UserIcon}.light & {
    svg path {
      fill: #1f2244;
    }
  }
`;

const UserProfilePictureWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  width: 32px;
  height: 32px;

  img {
    border-radius: 16px;
  }
`;
