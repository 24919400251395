import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { MenuDescriptionLink } from '../MenuDescriptionLink';
import { GridContainer, MenuContentHeading, MenuContentWrapper } from '../menu.styles';
import { LoginIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/resources-icons/LoginIcon';
import { languageSelector } from '~/src/redux/selectors/settings';
import { FadeSlideVariant } from '../grid.animation';
import { getCountryLocaleOwnersLinks } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/ownersMenu/getCountryLocaleOwnerLinks';
import { openDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { ModalType } from '~/src/components/modal/modalTypes';
import { RootState } from '~/src/redux/reducers/rootReducer';
import { screenSelector } from '~/src/redux/selectors/screen';
import { userSelector } from '~/src/redux/selectors/user';
import { routes } from '~/src/utils/routing';
import { useCodebase } from 'features/hooks/useCodebase';
import { trackMainMenu, trackMainMenuLink } from '~/src/utils/dataLayer';
import { FlexCol } from 'ui/primitives/flex';
import tokens from 'ui/design/tokens/tokens';

export const OwnersMenu: FC = () => {
  const lang = useSelector(languageSelector);
  const { companyLinks, resourcesLinks } = getCountryLocaleOwnersLinks(lang);
  const dispatch = useDispatch<RootState>();
  const screen = useSelector(screenSelector);
  const { isAuthenticated, customerData } = useSelector(userSelector);
  const isFWC = useCodebase('fwc');

  useEffect(() => trackMainMenu({ text: t({ id: 'menu.owners' }) }), []);

  return (
    <GridContainer
      variants={FadeSlideVariant}
      initial="prepare"
      animate="animate"
      exit="exit"
      transition={{ type: 'tween' }}
      onlyOneColumn={lang !== 'cs'}
    >
      <MenuContentWrapper>
        <MenuContentHeading>
          {isFWC ? (
            <Trans id="menu.fwc.resources" message="Resources" />
          ) : (
            <Trans id="menu.resources" message="Resources" />
          )}
        </MenuContentHeading>

        <FlexCol $gap={tokens.spacing.spacingXl}>
          {resourcesLinks.map((data) => (
            <MenuDescriptionLink
              href={data.href}
              leftIcon={data.leftIcon}
              boldText={data.boldText}
              additionalText={data.additionalText}
              key={data.id}
            />
          ))}
          {isAuthenticated && customerData.isOperator && (
            <>
              <Divider />
              <MenuDescriptionLink
                leftIcon={<LoginIcon fillColor="#1F2244" height={20} width={20} />}
                boldText={i18n._(t({ id: 'dashboard.heading.listings' }))}
                href={routes.operatorDashboard}
              />
            </>
          )}
        </FlexCol>

        {!isAuthenticated && (
          <>
            <Divider />

            <LoginButton
              onClick={() => {
                openDesktopOrPhoneModal(ModalType.LOGIN, screen, dispatch);
              }}
            >
              <LoginIcon fillColor="#1F2244" width={20} height={20} />
              <Text>
                <Trans id="menu.ownersLogin" message="Owner Login" />
              </Text>
            </LoginButton>
          </>
        )}
      </MenuContentWrapper>

      {companyLinks.length > 0 && (
        <MenuContentWrapper isBackgroundFilled={true} isDividerDisplayedAtMobileView={true}>
          <MenuContentHeading>
            <Trans id="menu.careForOwners" message="Care for owners" />
          </MenuContentHeading>
          <FlexCol $gap={tokens.spacing.spacingXl}>
            {companyLinks.map((data) => (
              <MenuDescriptionLink
                href={data.href}
                externalURL={data.externalURL}
                newWindow={data.newWindow}
                boldText={data.boldText}
                additionalText={data.additionalText}
                key={data.id}
              />
            ))}
          </FlexCol>
        </MenuContentWrapper>
      )}
    </GridContainer>
  );
};

const LoginButton = styled.button`
  display: flex;
  margin-top: 20px;
`;

const Divider = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: #edeae3;
  height: 1px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 12px;
  color: #1F2244;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #1F2244;
    transform: scaleX(0);
    transition: transform .2s ease-out;
  }

  :hover {
    ::before {
      transform: scaleX(1);
      transition: transform .2s ease-in;
    }
`;
