import { PictureModel } from 'api/src';
import { ReactNode } from 'react';
import Image, { ImageLoader } from 'next/legacy/image';
import getConfig from 'next/config';
import * as process from 'process';
import { deviceSizes, imageSizes } from './imageSizesDefinition';

const config = getConfig();

if (typeof btoa === 'undefined') {
  global.btoa = (str) => Buffer.from(str, 'binary').toString('base64');
}

/**
 * A custom function used to resolve URLs for next/legacy/image.
 *
 * @param cropRatio Image ratio to crop to. If `cropRatio` is defined, the image will be cropped based on image width and provided ratio. If `cropRatio` is undefined, the image will not be cropped.
 * @returns Formated URL string from AWS Serverless Image Handler
 */
export const myLoader =
  (cropRatio?: number): ImageLoader =>
  ({ src, width }): string => {
    const imageRequest = getImageRequest(src, width, cropRatio ? Math.round(width * cropRatio) : undefined);
    return `${config?.publicRuntimeConfig?.IMAGES_CLOUDFRONT_HOST}${btoa(imageRequest)}`;
  };

/**
 * Returns stringified request config for AWS Serverless Image Handler.
 */
export const getImageRequest = (src: string, desiredWith: number, desiredHeight?: number) =>
  JSON.stringify({
    bucket: config?.publicRuntimeConfig?.IMAGES_BUCKET || process.env.NEXT_PUBLIC_FWC_IMAGES_BUCKET,
    key: src,
    edits: {
      resize: {
        width: desiredWith,
        height: desiredHeight,
      },
    },
  });

export function ImageLink({ src, children }: { src: string; children: ReactNode }) {
  const host = config?.publicRuntimeConfig?.IMAGES_CLOUDFRONT_HOST;
  const path = btoa(
    JSON.stringify({
      bucket: config?.publicRuntimeConfig?.IMAGES_BUCKET || process.env.NEXT_PUBLIC_FWC_IMAGES_BUCKET,
      key: src,
    })
  );
  const href = `${host}${path}`;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
}

export const generatePreload = (mainPicture: any, sizes: any) => {
  const srcSet = [...deviceSizes, ...imageSizes]
    .sort((a, b) => a - b)
    .map(
      (size) =>
        `${myLoader(0)({
          src: `${mainPicture.name}.${mainPicture.extension}`,
          width: size,
        })} ${size}w`
    );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <link rel="preload" as="image" imageSrcSet={srcSet.join(', ')} imageSizes={sizes} />;
};

export const placeholderImage = '/static/images/vectors/placeholder.svg';

export const getLayoutFillNextImage = (
  img: PictureModel,
  // eslint-disable-next-line default-param-last
  alt = 'image description',

  classString?: string,
  sizes?: string,
  priority = false,
  fetchPriority?: 'low' | 'high' | 'auto'
) => (
  <Image
    className={classString}
    alt={alt}
    loader={myLoader(0)}
    src={`${img?.name}.${img?.extension}`}
    layout="fill"
    sizes={sizes}
    priority={priority}
    objectFit="cover"
    fetchPriority={fetchPriority}
    onError={(e: any) => {
      e.target.src = placeholderImage;
      e.target.srcset = placeholderImage;
    }}
  />
);

/**
 * Returns `next/legacy/image` component.
 *
 * @param img PictureDto object to display.
 * @param width The width of the image, in pixels. Must be an integer without a unit.
 * @param height The height of the image, in pixels. Provide only if you need to crop the image to fit in required bounbdaries. Must be an integer without a unit.
 * @param sizes A string mapping media queries to device sizes. Defaults to `100vw`.
 */
export const getNextImageElement = (
  img: PictureModel,
  // eslint-disable-next-line default-param-last
  width = 900,
  height?: number,
  sizes = '100vw',
  classString = '',
  alt = ''
) => {
  let cloneHeight = height;

  if (!height) {
    cloneHeight = 500;
  }

  return (
    <Image
      className={classString}
      alt={alt}
      width={width}
      height={cloneHeight}
      loader={myLoader((cloneHeight ?? 500) / width)}
      src={`${img.name}.${img.extension}`}
      onError={(e: any) => {
        e.target.src = placeholderImage;
        e.target.srcset = placeholderImage;
      }}
      layout="responsive"
      sizes={sizes}
    />
  );
};
