/* eslint-disable react/jsx-indent */
import { Trans } from '@lingui/react';
import { Language, routes } from '~/src/utils/routing';
import { QuestionIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/faq-icons/QuestionIcon';
import { FirstTimersIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/faq-icons/FirstTimersIcon';
import { BlogIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/faq-icons/BlogIcon';
import PlayIcon from 'ui/icons/ic_play.svg';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';
import DashboardIcon from 'ui/icons/ic_car-dashboard.svg';
import { isFWC } from '~/src/utils/appType';

const FAQLinkData = {
  href: routes.faq,
  id: 1,
  leftIcon: <QuestionIcon />,
  boldText: <Trans id="menu.FAQ" message="FAQ" />,
  additionalText: <Trans id="header.faq.heading" />,
};

const firstTimeCampervanLinkData = {
  href: routes.firstTimeCampervaningLanding,
  id: 2,
  leftIcon: <FirstTimersIcon />,
  boldText: <Trans id="menu.firstTime" message="First time with a campervan" />,
  additionalText: (
    <Trans id="menu.firstTime.additionalText" message="Answers to all questions asked by first timers." />
  ),
};

const videosLinkData = {
  href: routes.faq,
  id: 2,
  leftIcon: <PlayIcon fill="#001629" />,
  boldText: <Trans id="menu.videos" message="Videos" />,
  additionalText: null,
};

const blogLinkData = {
  href: routes.blog,
  id: 3,
  leftIcon: <BlogIcon />,
  boldText: <Trans id="menu.blog" message="Blog" />,
  additionalText: null,
};

const contactLinkData = {
  href: routes.faq,
  id: 4,
  hash: '#contact',
  boldText: <Trans id="faq.contacts.heading" />,
};

const ourMissionLinkData = {
  href: isFWC ? freewayRoutesTyped.aboutUs : routes.mission,
  id: 5,
  hash: null,
  boldText: isFWC ? <Trans id="aboutUs.heading" message="About us" /> : <Trans id="ourMission.heading" />,
};

const careerLinkData = {
  href: isFWC ? freewayRoutesTyped.aboutUs : routes.mission,
  id: 6,
  hash: '#job',
  boldText: isFWC ? (
    <Trans id="ourMission.fwc.jobs.preHead" message="Open job positions" />
  ) : (
    <Trans id="ourMission.jobs.preHead" />
  ),
};

const HelpCenterData = {
  href: freewayRoutesTyped.helpCenter,
  id: 7,
  leftIcon: <DashboardIcon />,
  boldText: <Trans id="menu.hc" message="Help Center" />,
  additionalText: <Trans id="header.hc.additionalText" message="Are you travelling and need support?" />,
};

export const getCountryLocaleSupportLinks = (lang: Language) => {
  const resourcesLinks = [
    FAQLinkData,
    isFWC && HelpCenterData,
    !isFWC && firstTimeCampervanLinkData,
    // isFWC && videosLinkData,
    blogLinkData,
  ].filter(Boolean);
  const companyLinks = [contactLinkData, ourMissionLinkData, careerLinkData];

  switch (lang) {
    case Language.sk:
    case Language.en:
      return {
        companyLinks,
        resourcesLinks: resourcesLinks.filter((link) => link.href !== routes.firstTimeCampervaningLanding),
      };
    case Language.es:
      return { companyLinks, resourcesLinks: resourcesLinks.filter((link) => link.href !== routes.blog) };
    default:
      return { companyLinks, resourcesLinks };
  }
};
