import { FC } from 'react';
import { NavigationMenuIconProps } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/types';

export const LoginIcon: FC<NavigationMenuIconProps> = ({ fillColor = 'currentColor', height = 16, width = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable-next-line max-len */
      d="M8 1C7.20435 1 6.44129 1.31607 5.87868 1.87868C5.31607 2.44129 5 3.20435 5 4V6H11V4C11 3.20435 10.6839 2.44129 10.1213 1.87868C9.55871 1.31607 8.79565 1 8 1ZM4 4V6H3.5C2.67157 6 2 6.67157 2 7.5V14.5C2 15.3284 2.67157 16 3.5 16H12.5C13.3284 16 14 15.3284 14 14.5V7.5C14 6.67157 13.3284 6 12.5 6H12V4C12 2.93913 11.5786 1.92172 10.8284 1.17157C10.0783 0.421427 9.06087 0 8 0C6.93913 0 5.92172 0.421427 5.17157 1.17157C4.42143 1.92172 4 2.93913 4 4ZM3 7.5C3 7.22386 3.22386 7 3.5 7H4.5H11.5H12.5C12.7761 7 13 7.22386 13 7.5V14.5C13 14.7761 12.7761 15 12.5 15H3.5C3.22386 15 3 14.7761 3 14.5V7.5ZM8.5 10C8.5 9.72386 8.27614 9.5 8 9.5C7.72386 9.5 7.5 9.72386 7.5 10V12C7.5 12.2761 7.72386 12.5 8 12.5C8.27614 12.5 8.5 12.2761 8.5 12V10Z"
      fill={fillColor}
    />
  </svg>
);
