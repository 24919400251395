/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import { Trans } from '@lingui/react';
import { getFormattedDate, getFormattedDateWithoutYear } from '~/src/utils/date';
import useWindowSize from '~/src/hooks/useWindowSize';
import { searchInfoSelector } from '~/src/redux/selectors/search';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { SearchInputValue, SearchPlaceholder, ValueCleaner } from '~/src/components/search/styles';
import { showModal } from '~/src/redux/actions/settings';
import { changeDateSelection, goToSearchPageWithResetFilters } from '~/src/utils/query';
import { useOnClickOutside } from '~/src/hooks/useOnClickOutside';
import { setSearchDates } from '~/src/redux/actions/search';
import { screenSelector } from '~/src/redux/selectors/screen';
import { ModalType } from '../../modal/modalTypes';
import { SearchInfoReduxData } from '~/src/redux/reducers/search';
import { TypedIBrowser } from '~/src/redux/reducers/rootReducer';

const DateRange: any = dynamic(import('ui/components/searchBar/DateRange'));

interface Props {
  screen: TypedIBrowser;
  searchInfo: SearchInfoReduxData;
  dispatch: any;
  monthsBreakPoint?: number;
  showSpecialOffer?: boolean;
}

const DateRangeCalendar = ({ screen, searchInfo, dispatch, monthsBreakPoint = 760, showSpecialOffer }: Props) => {
  const size = useWindowSize();
  const [showCalendar, setShowCalendar] = useState(false);
  const [monthsToShow, setMonthsToShow] = useState(size.width < monthsBreakPoint ? 1 : 2);
  const calendarRef = useRef(null);

  useOnClickOutside(calendarRef, () => setShowCalendar(false));

  useEffect(() => {
    setMonthsToShow(size.width < monthsBreakPoint ? 1 : 2);
  }, [size.width]);

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const clean = () => {
    dispatch(setSearchDates(null));

    const currentQuery = Router.query;
    delete currentQuery.dateFrom;
    delete currentQuery.dateTo;

    goToSearchPageWithResetFilters(currentQuery);
  };

  const [tmpSelectedDays, setTmpSelectedDays] = useState(
    searchInfo.selectedDays
      ? searchInfo.selectedDays
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        }
  );

  useEffect(() => {
    setTmpSelectedDays(
      searchInfo.selectedDays
        ? searchInfo.selectedDays
        : {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
          }
    );
  }, [searchInfo.selectedDays]);

  useEffect(() => {
    setTmpSelectedDays(
      searchInfo.selectedDays
        ? searchInfo.selectedDays
        : {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
          }
    );
  }, [searchInfo.selectedDays]);

  const [selectedBothDates, setSelectedBothDates] = useState(false);

  useEffect(() => {
    if (selectedBothDates) {
      changeDateSelection({ selection: tmpSelectedDays }, dispatch);
      setShowCalendar(false);
    }
  }, [selectedBothDates]);

  return (
    <Wrapper ref={calendarRef}>
      <CalendarInput
        data-testid="select-a-date"
        onClick={() => {
          if (screen.lessThan.medium) {
            dispatch(showModal(ModalType.SEARCH_WIDGET_MODAL_FWC_PORT, { type: 'date' }));
          } else {
            toggleCalendar();
          }
        }}
      >
        <Placeholder>
          <Trans id="datePicker.term.label" message="Termín" />
        </Placeholder>
        {searchInfo.selectedDays &&
          searchInfo.selectedDays.startDate &&
          searchInfo.selectedDays.endDate &&
          showCalendar && (
            <ValueCleaner
              onClick={() => {
                clean();
              }}
            >
              <Trans id="datePicker.term.clear" message="Vymazat" />
            </ValueCleaner>
          )}
        <InputValue className={searchInfo.selectedDays ? 'hasValue' : ''}>
          {searchInfo.selectedDays && searchInfo.selectedDays.startDate && searchInfo.selectedDays.endDate ? (
            `${getFormattedDateWithoutYear(searchInfo.selectedDays.startDate, 'cs')} - ${getFormattedDate(
              searchInfo.selectedDays.endDate,
              'cs'
            )}`
          ) : (
            <Trans id="datePicker.term.placeholder" message="Vyberte datum" />
          )}
        </InputValue>
      </CalendarInput>

      {showCalendar && (
        <CalendarWrapper className={monthsToShow === 1 ? 'oneColumn' : ''}>
          <DateRange
            showSpecialOffer={showSpecialOffer}
            monthsBreakPoint={1400}
            minDate={new window.Date()}
            rangeColors={['#119383']}
            className="date-range-picker"
            months={monthsToShow}
            showMonthAndYearPickers={false}
            moveRangeOnFirstSelection={false}
            onChange={(item) => {
              setTmpSelectedDays(item.selection);
            }}
            onRangeFocusChange={(item) => {
              setSelectedBothDates(item[0] === 0 && item[1] === 0);
            }}
            ranges={[tmpSelectedDays]}
            direction="horizontal"
          />
        </CalendarWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 240px;
  height: 100%;
  border-left: 1px solid var(--color-beige);

  @media ${deviceMaxWidth.phone} {
    grid-area: date;
    padding: 16px;
    border-right: 1px solid var(--color-beige);
    max-width: unset;
    border-bottom: 1px solid var(--color-beige);
    background: white;
  }
`;

const CalendarInput = styled.div`
  width: 100%;
  background-color: white;
  color: hsl(0, 0%, 50%);
  cursor: pointer;
  display: flex;
  height: 80px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0px;
  padding: 0px 16px;
  transition: 300ms all;
  &.selected {
    color: black;
  }

  ${Wrapper}:hover & {
    background-color: var(--color-beige);
  }

  @media ${deviceMaxWidth.phone} {
    padding: 0px;
  }
`;

const Placeholder = styled(SearchPlaceholder)``;
const InputValue = styled(SearchInputValue)``;

const CalendarWrapper = styled.div`
  position: absolute;
  top: 92px;
  background-color: white;
  z-index: 9;
  cursor: pointer;
  box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
  border-radius: 8px;
  width: 100%;
  width: 700px;

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrMonth {
    width: 50%;

    &:first-child {
      border-right: solid 1px var(--color-beige);
    }
  }

  .rdrCalendarWrapper {
    width: 100%;
  }

  .rdrMonthName {
    text-align: center;
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--color-dark-blue);
    text-transform: capitalize;
    margin-top: -40px;
    margin-bottom: 30px;
  }

  &.oneColumn {
    width: 350px;

    .rdrCalendarWrapper,
    .rdrMonth {
      width: 100%;
    }
  }
`;

const mapStateToProps = (state) => ({
  searchInfo: searchInfoSelector(state),
  screen: screenSelector(state),
});

const connector = connect(mapStateToProps, (dispatch: any) => ({ dispatch }));
export default connector(DateRangeCalendar);
