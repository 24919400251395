import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

interface Props {
  onClickCallback?: any;
  isBlack: any;
}

export function DotContainerComp({ onClickCallback, isBlack = false }: Props) {
  return (
    <DotContainer
      onClick={() => {
        if (onClickCallback) {
          onClickCallback();
        }
      }}>
      <div className={!isBlack ? 'bar1' : 'bar1 black'} />
      <div className={!isBlack ? 'bar2' : 'bar2 black'} />
      <div className={!isBlack ? 'bar3' : 'bar3 black'} />
    </DotContainer>
  );
}

const DotContainer = styled.div`
  margin: auto;
`;

const mapStateToProps = state => ({});

export default connect(mapStateToProps, dispatch => ({ dispatch }))(DotContainerComp);
