import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

export const getExperienceCategoriesCS = () =>
  [
    {
      id: 1,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.familyExperiences' })),
      image: '/static/compressedImages/experiences-menu/family.png',
      slug: 'obytna-auta-pro-rodinu',
      dynamicSegmentId: '74',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/family-blur.jpg',
    },
    {
      id: 2,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.adventureForTwo' })),
      image: '/static/compressedImages/experiences-menu/adventure.png',
      slug: 'karavany-pro-dva',
      dynamicSegmentId: '75',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/adventure-blur.jpg',
    },
    {
      id: 3,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.firstWithCaravan' })),
      image: '/static/compressedImages/experiences-menu/first-timer.png',
      slug: 'obytna-auta-pro-zacatecniky',
      dynamicSegmentId: '76',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/first-timer-blur.jpg',
    },
    {
      id: 4,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.tripWithDog' })),
      image: '/static/compressedImages/experiences-menu/pet-friendly.png',
      slug: 'karavanem-se-psem',
      dynamicSegmentId: '78',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/pet-friendly-blur.jpg',
    },
    {
      id: 5,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.luxusOnWheels' })),
      image: '/static/compressedImages/experiences-menu/luxury.png',
      slug: 'luxusni-obytna-auta',
      dynamicSegmentId: '79',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/luxury-blur.jpg',
    },
    {
      id: 6,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.EcologicalTravel' })),
      image: '/static/compressedImages/experiences-menu/eco-travel.png',
      slug: 'ekologicke-cestovani-karavanem',
      dynamicSegmentId: '73',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/eco-travel-blur.jpg',
    },
    {
      id: 7,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.carTrailers' })),
      image: '/static/compressedImages/experiences-menu/trailers.png',
      slug: 'obytne-privesy',
      dynamicSegmentId: '80',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/trailers-blur.jpg',
    },
  ] as const;

export const getExperienceCategories = () =>
  [
    {
      id: 1,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.familyExperiences' })),
      image: '/static/compressedImages/experiences-menu/family.png',
      searchQuery: 'licenseCategory=NotSet&manufacturer&vehicleType=%5B"NotSet"%2C"Alcove"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/family-blur.jpg',
    },
    {
      id: 2,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.adventureForTwo' })),
      image: '/static/compressedImages/experiences-menu/adventure.png',
      searchQuery: 'vehicleType=%5B%22NotSet%22%2C%22BuiltIn%22%2C%22Campervan%22%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/adventure-blur.jpg',
    },
    {
      id: 3,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.firstWithCaravan' })),
      image: '/static/compressedImages/experiences-menu/first-timer.png',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"BuiltIn"%2C"SemiIntegrated"%2C"Alcove"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/first-timer-blur.jpg',
    },
    {
      id: 4,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.tripWithDog' })),
      image: '/static/compressedImages/experiences-menu/pet-friendly.png',
      searchQuery: 'pets=%5B"Allowed"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/pet-friendly-blur.jpg',
    },
    {
      id: 5,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.luxusOnWheels' })),
      image: '/static/compressedImages/experiences-menu/luxury.png',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"Integrated"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/luxury-blur.jpg',
    },
    {
      id: 6,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.EcologicalTravel' })),
      image: '/static/compressedImages/experiences-menu/eco-travel.png',
      searchQuery:
        'vehicleType=["NotSet","BuiltIn","SemiIntegrated","Trailer"]&solarPanels=true&yearBuildFrom=2018&yearBuildTo=2021',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/eco-travel-blur.jpg',
    },
    {
      id: 7,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.carTrailers' })),
      image: '/static/compressedImages/experiences-menu/trailers.png',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"Trailer"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/trailers-blur.jpg',
    },
  ] as const;
