/* eslint-disable react/jsx-indent */
import { Trans } from '@lingui/react';
import { Language, routes } from '~/src/utils/routing';
import { ListRVIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/resources-icons/ListRVIcon';
import { AllInclusiveIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/resources-icons/AllInclusiveIcon';
import { InvestmentIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/resources-icons/InvestmentIcon';
import { WhyRentIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/resources-icons/WhyRentIcon';
import { Money } from '../../../../icons-and-images/navigation-menu-icons/resources-icons/Money';

const offerLinkData = {
  id: 1,
  href: routes.listingWizardLanding,
  leftIcon: <ListRVIcon />,
  boldText: <Trans id="profile.settings.becomeOwner.cta" />,
  additionalText: <Trans id="lw.landing.pageHeader.listAndEarn" />,
};

const p2pLinkData = {
  id: 2,
  href: routes.p2pAllInclusive,
  leftIcon: <AllInclusiveIcon />,
  boldText: <Trans id="menu.P2PAllInclusive" message="P2P All Inclusive" />,
  additionalText: <Trans id="menu.P2PAllInclusive.additionalText" message="Exclusive program for campervan owners." />,
};

const campiriPlusData = {
  id: 3,
  href: routes.campiriPlus,
  leftIcon: <Money />,
  boldText: <Trans id="menu.campiriplus" message="Upgrade on Campiri+" />,
  additionalText: <Trans id="menu.campiriplus.additionalText" message="A great opportunity to increase sales" />,
};

// const campersClubLinkData = {
//   id: 3,
//   href: routes.landingCampersClub,
//   leftIcon: <InvestmentIcon />,
//   boldText: <Trans id="tab.ccc" />,
//   additionalText: <Trans
//     id="menu.campervanInvestment.additionalText"
//     message="Buy campervan as a investment. Coming soon."
//   />,
// };

const servisLinkData = {
  id: 4,
  externalURL: 'https://www.campiriservis.cz/',
  newWindow: true,
  href: null,
  boldText: <Trans id="menu.service" message="Campiri Service" />,
  additionalText: (
    <Trans id="menu.service.additionalText" message="The best care for your motorhomes, caravans and campervans." />
  ),
};

const parkingLinkData = {
  id: 5,
  href: null,
  externalURL: 'https://www.campiriservis.cz/parking.html',
  newWindow: true,
  boldText: <Trans id="menu.parking" message="Campiri Parking" />,
  additionalText: <Trans id="menu.parking.additionalText" message="Safe parking for your motorhome in Prague." />,
};

export const getCountryLocaleOwnersLinks = (lang: Language) => {
  const resourcesLinks = [offerLinkData, p2pLinkData, campiriPlusData];
  const companyLinks = [servisLinkData, parkingLinkData];

  const onlyCSRoutes = [routes.p2pAllInclusive, routes.landingCampersClub];

  switch (lang) {
    case Language.cs:
      return { companyLinks, resourcesLinks };
    default:
      return { resourcesLinks: resourcesLinks.filter(data => !onlyCSRoutes.includes(data.href)), companyLinks: [] };
  }
};
