import getConfig from 'next/config';
import { Configuration } from '~/libs/api/configuration';
import { MessagingMarketplaceApi } from '~/libs/api/apis/messaging-marketplace-api';

const { publicRuntimeConfig } = getConfig();

const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    const accessToken = window?.localStorage?.getItem('accessToken');
    return accessToken;
  }
  return null;
};

const getApiConfig = () => new Configuration({
  basePath: publicRuntimeConfig.API_HOST.replace(/\/+$/, ''),
  accessToken: getAccessToken,
});

const apiConfiguration = getApiConfig();

const INBOX_API = new MessagingMarketplaceApi(apiConfiguration, apiConfiguration.basePath);

export const getUnreadMessages = () => INBOX_API.apiV1MarketplaceMessagingUnreadMessagesGet();
