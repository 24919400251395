import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { Trans } from '@lingui/react';
import { setSearchPlace } from '~/src/redux/actions/search';
import { searchInfoSelector } from '~/src/redux/selectors/search';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { SearchInputValue, SearchPlaceholder } from '~/src/components/search/styles';
import { showModal } from '~/src/redux/actions/settings';
import { screenSelector } from '~/src/redux/selectors/screen';
import { ModalType } from '../../modal/modalTypes';
import MapBoxAutocomplete from 'ui/components/searchBar/MapBoxAutocomplete';
import { popularPlaces, popularSearches } from '~/src/components/search/placesAndSearches';
import { customClearSearchSideEffect } from '~/src/components/search/utils';

interface Props {
  dispatch: Dispatch<any>;
  placeError: boolean;
  setPlaceError: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlaceAutocomplete = ({ screen, dispatch, searchInfo, placeError, setPlaceError }: Props & PropsFromRedux) => {
  const inputRef = useRef(null);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  useEffect(() => {
    if (placeError) {
      inputRef?.current?.focus();
    }
  }, [placeError]);

  return (
    <Wrapper
      className={placeError ? 'hasError' : ''}
      onClick={() => {
        if (screen.lessThan.medium) {
          dispatch(showModal(ModalType.SEARCH_WIDGET_MODAL_FWC_PORT, { type: 'place' }));
        } else {
          setInputHasFocus(true);
          inputRef?.current?.focus();
        }
      }}
    >
      <AutocompleteInput>
        <Placeholder>
          <Trans id="autoComplete.startPlace.label" message="Odkud vyrazíte" />
        </Placeholder>
        <InputValue>
          <MapBoxAutocomplete
            customClearSearchSideEffect={customClearSearchSideEffect}
            popularPlaces={popularPlaces}
            popularSearches={popularSearches}
            inputHasFocus={inputHasFocus}
            setInputHasFocus={setInputHasFocus}
            selectedPlaceName={searchInfo?.place?.place_name ? searchInfo.place.place_name : null}
            inputRef={inputRef}
            onSelect={(place) => {
              setPlaceError(false);
              dispatch(setSearchPlace(place));
            }}
          />
        </InputValue>
      </AutocompleteInput>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  /* max-width: 250px; */
  height: 80px;
  padding: 0px 16px;
  background-color: white;
  transition: 300ms all;
  border-left: 1px solid var(--color-beige);

  &.hasError {
    background: #f5d3d3;
  }

  &:hover {
    background-color: var(--color-beige);
  }

  @media ${deviceMaxWidth.phone} {
    grid-area: place;
    max-width: unset;
    margin: 0 auto;
    border-bottom: 1px solid var(--color-beige);
    border-radius: 0px;
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
`;

const AutocompleteInput = styled.div`
  width: 100%;
  color: hsl(0, 0%, 50%);
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0px;
  input {
    font-family: var(--font-poppins);
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #1f2244;
    height: 35px;
    &::placeholder {
      font-family: var(--font-poppins);
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #1f2244;
    }
  }
`;

const Placeholder = styled(SearchPlaceholder)`
  margin-top: 13px;

  @media ${deviceMaxWidth.phone} {
    margin-top: 0px;
    margin-bottom: 6px;
  }
`;

const InputValue = styled(SearchInputValue)`
  position: relative;
  width: 100%;
  margin-top: 0px;
`;

const mapStateToProps = (state) => ({
  searchInfo: searchInfoSelector(state),
  screen: screenSelector(state),
});

const connector = connect(mapStateToProps, (dispatch) => ({ dispatch }));
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PlaceAutocomplete);
