import { FC, useEffect } from 'react';
import { Trans } from '@lingui/react';
import { useSelector } from 'react-redux';
import { GridContainer, MenuContentHeading, MenuContentWrapper } from '../menu.styles';
import { MenuDescriptionLink } from '../MenuDescriptionLink';

import { FadeSlideVariant } from '../grid.animation';
import { getCountryLocaleSupportLinks } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/supportMenu/getCountryLocaleSupportLinks';
import { languageSelector } from '~/src/redux/selectors/settings';
import { useCodebase } from 'features/hooks/useCodebase';
import { trackMainMenu } from '~/src/utils/dataLayer';
import { t } from '@lingui/macro';
import { FlexCol } from 'ui/primitives/flex';
import tokens from 'ui/design/tokens/tokens';

export const SupportMenu: FC = () => {
  const lang = useSelector(languageSelector);
  const isFWC = useCodebase('fwc');
  const { companyLinks, resourcesLinks } = getCountryLocaleSupportLinks(lang);
  useEffect(() => trackMainMenu({ text: t({ id: 'tabs.support' }) }), []);

  return (
    <GridContainer
      variants={FadeSlideVariant}
      initial="prepare"
      animate="animate"
      exit="exit"
      transition={{ type: 'tween' }}
      onlyOneColumn={false}
    >
      <MenuContentWrapper>
        <MenuContentHeading>
          {isFWC ? (
            <Trans id="menu.fwc.resources" message="Resources" />
          ) : (
            <Trans id="menu.resources" message="Resources" />
          )}
        </MenuContentHeading>

        <FlexCol $gap={tokens.spacing.spacingXl}>
          {resourcesLinks.map((data, index) => (
            <MenuDescriptionLink
              boldText={data.boldText}
              href={data.href}
              additionalText={data.additionalText}
              leftIcon={data.leftIcon}
              key={data.id}
            />
          ))}
        </FlexCol>
      </MenuContentWrapper>

      <MenuContentWrapper isBackgroundFilled={true}>
        <MenuContentHeading>
          {isFWC ? <Trans id="menu.fwc.company" message="Company" /> : <Trans id="menu.company" message="Company" />}
        </MenuContentHeading>
        <FlexCol $gap={tokens.spacing.spacingXl}>
          {companyLinks.map((data, index) => {
            if (data.hash === '#job') {
              return (
                <MenuDescriptionLink
                  boldText={data.boldText}
                  href={data.href}
                  hash={data.hash}
                  key={data.id}
                  dataTestId="menu-mission-job"
                />
              );
            }
            return <MenuDescriptionLink boldText={data.boldText} href={data.href} hash={data.hash} key={data.id} />;
          })}
        </FlexCol>
      </MenuContentWrapper>
    </GridContainer>
  );
};
