import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Trans } from '@lingui/react';
import { useIsomorphicLayoutEffect } from 'react-use';
import {
  DesktopListItem,
  ItemContainer,
  MenuButton,
} from '~/src/components/layout/headers/navigation-menu/V2/DesktopListItem';
import { Country, routes } from '~/src/utils/routing';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { useTypedSelector } from '~/src/redux/store';
import { useRouter } from 'next/router';
import { VouchersIcon } from '../../icons-and-images/navigation-menu-icons/special-offers-icons/VouchersIcon';
import { FlexAlignCenter } from 'ui/primitives/flex';
import { useCodebase } from 'features/hooks/useCodebase';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { useLocalizedRouter } from '~/src/components/LocalizedLink';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';
import LocalizedLink from 'features/routing/LocalizedLink';
import { navUnderlineHighlightCss } from '~/src/components/layout/headers/styles';
import { trackMainMenuLink } from '~/src/utils/dataLayer';
import { t } from '@lingui/macro';

type OpenMenuType = 'vehicleRental' | 'owners' | 'support' | null;

interface DesktopMenuItemsListProps {
  areLinksInDarkColor?: boolean;
}

export const DesktopMenuItemsList: FC<DesktopMenuItemsListProps> = ({ areLinksInDarkColor }) => {
  const [openMenuItem, setOpenMenuItem] = useState<OpenMenuType>(null);
  const settings = useTypedSelector(settingsSelector);
  const isFWC = useCodebase('fwc');

  const handleOpenMenu = (menuItem: OpenMenuType) => {
    if (menuItem !== openMenuItem) {
      setOpenMenuItem(menuItem);
    }

    if (menuItem === openMenuItem) {
      setOpenMenuItem(null);
    }
  };

  const router = useRouter();
  const localisedRouter = useLocalizedRouter();

  useEffect(() => {
    setOpenMenuItem(null);
  }, [router.asPath]);

  const closeMenu = useCallback(() => {
    setOpenMenuItem(null);
  }, []);

  const buttonRef = useRef<HTMLDivElement>(null);
  useIsomorphicLayoutEffect(() => {
    const eventHandler = (event) => {
      if (!buttonRef.current?.contains(event.target)) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', eventHandler);
    return () => document.removeEventListener('mousedown', eventHandler);
  }, [buttonRef.current, closeMenu]);

  return (
    <ListContainer ref={buttonRef} id="ab_menu_test_menu_items_new">
      <DesktopListItem
        areLinksInDarkColor={areLinksInDarkColor}
        itemOrder={1}
        localisedText={
          isFWC ? (
            <Trans message="Campers" id="menu.campers" />
          ) : (
            <Trans message="Vehicle rental" id="menu.vehicleRental" />
          )
        }
        openMenuItem={openMenuItem}
        handleOpenMenu={() => handleOpenMenu('vehicleRental')}
        shouldAnimateIn={openMenuItem !== null}
        menuType="vehicleRental"
        isOpen={openMenuItem === 'vehicleRental'}
      />
      {settings?.country === Country.cz && !isFWC && (
        <DesktopListItem
          areLinksInDarkColor={areLinksInDarkColor}
          itemOrder={2}
          localisedText={<Trans id="menu.owners" message="Owners" />}
          openMenuItem={openMenuItem}
          handleOpenMenu={() => handleOpenMenu('owners')}
          shouldAnimateIn={openMenuItem !== null}
          menuType="owners"
          isOpen={openMenuItem === 'owners'}
        />
      )}
      {isFWC && (
        <LocalizedLink
          href={freewayRoutesTyped.buyCamper}
          onClick={(e) => {
            if (!('href' in e.currentTarget)) return;
            trackMainMenuLink({
              link_url: e.currentTarget.href as string,
              text: t({ id: 'menu.buyCamper', message: 'Buy your camper' }),
            });
          }}
        >
          <LinkWrapper data-dark={areLinksInDarkColor}>
            <Trans id="menu.buyCamper" message="Buy your camper" />
          </LinkWrapper>
        </LocalizedLink>
      )}

      <DesktopListItem
        areLinksInDarkColor={areLinksInDarkColor}
        itemOrder={3}
        localisedText={<Trans id="tabs.support" />}
        openMenuItem={openMenuItem}
        isOpen={openMenuItem === 'support'}
        handleOpenMenu={() => handleOpenMenu('support')}
        shouldAnimateIn={openMenuItem !== null}
        menuType="support"
      />

      {!isFWC && (
        <ItemContainer>
          <a
            href="https://shop.campiri.com/"
            onClick={() =>
              trackMainMenuLink({ link_url: 'https://shop.campiri.com/', text: t({ id: 'menu.vouchers' }) })
            }
          >
            <MenuButton areLinksInDarkColor={areLinksInDarkColor} isActive={false}>
              <FlexAlignCenter $gap="8px">
                <VouchersIcon /> <Trans id="menu.vouchers" message="Vouchery" />
              </FlexAlignCenter>
            </MenuButton>
          </a>
        </ItemContainer>
      )}

      {/* {((settings.country === Country.pl && settings.language === Language.pl) || (settings.country === Country.cz && settings.language === Language.cs)) && (
        <MenuLink areLinksInDarkColor={areLinksInDarkColor}>
          <VouchersIcon />
          <LocalizedLink href={routes.voucher} passHref={true}>
            <a>
              <Trans message="Vouchers" id="tabs.voucher" />
            </a>
          </LocalizedLink>

        </MenuLink>
      )} */}
    </ListContainer>
  );
};

const LinkWrapper = styled.div`
  margin-left: 28px;
  &[data-dark='true'] {
    color: ${resolveThemeValue(tokens.color.text.textPrimary)};
  }
  color: ${resolveThemeValue(tokens.color.text.textContrastSecondary)};
  position: relative;
  .isFWC & {
    ${navUnderlineHighlightCss};
  }

  &:hover {
    &[data-dark='false'] {
      color: white;
    }
  }
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;

  &.menuAbTestHidden {
    display: none;
  }
`;
