/* eslint-disable max-len */
const ImageLanguageIcon = () => (
  <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.497 7.003a4.503 4.503 0 0 1 4.499 4.499A4.503 4.503 0 0 1 11.497 16 4.503 4.503 0 0 1 7 11.502a4.503 4.503 0 0 1 4.498-4.499zM9.667.18a8.014 8.014 0 0 1 6.259 6.766.498.498 0 0 1-.495.566.502.502 0 0 1-.497-.434 7 7 0 0 0-1.196-3.073h-2.46a.5.5 0 0 0-.485.379l-.31 1.231a.5.5 0 1 1-.97-.244l.31-1.23a1.497 1.497 0 0 1 1.455-1.135h1.614a7.037 7.037 0 0 0-3.434-1.848 6.982 6.982 0 0 0-8.166 4.848H4.22c.69 0 1.288.467 1.455 1.135l.5 2a1.49 1.49 0 0 1-.395 1.424 1.49 1.49 0 0 1-1.06.44h-.31l-.53 2.654c.791.58 1.698.985 2.658 1.188a.497.497 0 0 1 .385.592.501.501 0 0 1-.593.384 7.966 7.966 0 0 1-3.897-2.083l-.124-.123-.085-.087A7.983 7.983 0 0 1 0 7.999l.001-.135.007-.208c.004-.086.01-.172.016-.259l.001-.018a7.305 7.305 0 0 1 .037-.365l.018-.13c.019-.132.041-.264.067-.397l.029-.155a7.946 7.946 0 0 1 3.47-5.042A7.955 7.955 0 0 1 9.667.179zm1.83 7.824A3.503 3.503 0 0 0 8 11.502C7.999 13.43 9.568 15 11.497 15c1.93 0 3.5-1.57 3.5-3.498 0-1.93-1.57-3.5-3.5-3.5zm0 .5a.5.5 0 0 1 .5.5h.5a.5.5 0 1 1 0 1h-1.355a.395.395 0 0 0-.147.76l1.375.55c.346.14.617.405.763.748.146.342.15.721.011 1.067-.191.48-.633.813-1.147.866v.007a.5.5 0 0 1-1 0h-.5a.5.5 0 0 1 0-1h1.354a.395.395 0 0 0 .147-.76l-1.375-.55a1.39 1.39 0 0 1-.874-1.293c0-.372.144-.722.407-.986.226-.227.523-.369.841-.402v-.007a.5.5 0 0 1 .5-.5zm-7.278-1.5H1.073a7.092 7.092 0 0 0-.065.673l-.002.062a8.257 8.257 0 0 0-.005.34l.002.121c.003.093.008.185.014.276l.003.056.007.092c.005.063.013.125.02.187l.006.045c.019.148.039.283.063.41l.012.067a7.035 7.035 0 0 0 1.374 2.995l.082.102c.053.064.098.12.145.174.093.105.18.2.267.289l.014.013.5-2.5a.501.501 0 0 1 .49-.402h.718a.5.5 0 0 0 .486-.621l-.5-2a.5.5 0 0 0-.485-.378z"
      fill="#1F2244"
      fillRule="evenodd"
    />
  </svg>
);

export default ImageLanguageIcon;
