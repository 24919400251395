import { FC } from 'react';

interface QuestionIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

export const QuestionIcon: FC<QuestionIconProps> = ({ fillColor = 'currentColor', height = 16, width = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_849_536)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM8 11L8.08747 11.005C8.46047 11.0484 8.75 11.3654 8.75 11.75C8.75 12.1642 8.41421 12.5 8 12.5C7.58579 12.5 7.25 12.1642 7.25 11.75C7.25 11.3654 7.53953 11.0484 7.91253 11.005L8 11ZM10.4361 5.43898C10.7216 6.67781 10.0319 7.93363 8.83333 8.3574C8.65845 8.41923 8.53432 8.57168 8.50609 8.75101L8.5 8.82933V9.5C8.5 9.77614 8.27614 10 8 10C7.74896 10 7.54113 9.81499 7.50542 9.57389L7.5 9.5V8.8296C7.49969 8.23603 7.84876 7.7025 8.38279 7.46164L8.5 7.4146C9.21916 7.16033 9.63298 6.40684 9.46169 5.66354C9.2904 4.92023 8.58854 4.42385 7.83063 4.50997C7.11483 4.59132 6.56434 5.16778 6.50526 5.87416L6.5 6.0001C6.49995 6.27624 6.27605 6.50005 5.9999 6.5C5.72376 6.49995 5.49995 6.27605 5.5 5.9999C5.50024 4.7286 6.45454 3.65991 7.71772 3.51637C8.9809 3.37282 10.1507 4.20014 10.4361 5.43898Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_849_536">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
