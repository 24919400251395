import { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { useRouter } from 'next/router';
import { useScrollPosition } from '~/src/hooks/useScrollPosition';

export const useStickyHeaderPosition = (isScrollMonitored: boolean) => {
  if (!isScrollMonitored) return false;

  const router = useRouter();
  const size = useWindowSize();
  const [isSticky, setIsSticky] = useState(false);

  const [scrollPosition, setScrollPostion] = useState({});
  useScrollPosition(
    ({ prevPos, currPos }) => {
      setScrollPostion({ prevPos, currPos });
    },
    [],
    null,
    false,
    300
  );

  useEffect(() => {
    const bodyClassList = document.body.classList;
    if (bodyClassList.contains('mobile-menu-open')) {
      bodyClassList.remove('mobile-menu-open');
    }

    // setIsSticky(router.pathname === '/');
    // allowScroll();

    if (scrollPosition) {
      // @ts-ignore
      if (router.pathname === '/' && scrollPosition.currPos?.y < -466 && size.width < 760) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
  }, [router.pathname, scrollPosition, size]);

  return isSticky;
};
