import { MutableRefObject, useEffect } from 'react';

export default function useOnClickOutside(ref: MutableRefObject<any>, handler: (event: any) => void) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current?.contains(event.target)) {
        return;
      }

      // TODO: select filter hotfix
      if (
        ref.current.id === 'CarfilterMobileWrapper' &&
        (event.target.classList.contains('css-6htbnp-ValueContainer') || event.target.classList.contains('css-rgx79y'))
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('mousedown', listener, { passive: true });
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
}
