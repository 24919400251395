import { RefObject, useEffect, useMemo, useState } from 'react';
import { IS_CLIENT } from '~/src/utils/app';

type UseResize = (ref: RefObject<Element>) => {
  width?: number;
  height?: number;
};

if (IS_CLIENT && !window?.ResizeObserver) {
  import('resize-observer').then((module) => module.install());
}

export const useResize: UseResize = (ref) => {
  const [sizes, setSizes] = useState<{
    width?: number;
    height?: number;
  }>({
    width: null,
    height: null,
  });

  const observer = useMemo(() => {
    if (IS_CLIENT && window?.ResizeObserver) {
      return new ResizeObserver((entries) => {
        // We wrap it in requestAnimationFrame to avoid this error - ResizeObserver loop limit exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          setSizes({
            width: ref.current?.clientWidth,
            height: ref.current?.clientHeight,
          });
        });
      });
    }
  }, [ref, IS_CLIENT]);

  useEffect(() => {
    if (ref?.current) {
      observer?.observe(ref.current);
    }
    return () => {
      observer?.disconnect();
    };
  }, [observer, ref]);

  return { width: sizes.width, height: sizes.height };
};
