import styled from 'styled-components';
import { mediaSmMax } from '../../utils/breakpoints';
import { resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';

const ModalTopBarWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 22px 16px;
`;

export const StickyWrapper = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
`;

const CloseIcon = styled.i`
  font-size: 20px;
  cursor: pointer;
  position: absolute;
`;

const BackIcon = styled.i`
  font-size: 20px;
  cursor: pointer;
`;

const HorizontalLine = styled.div`
  background-color: ${resolveThemeValue(tokens.color.border.border, 'var(--color-beige)')};
  width: 100%;
  height: 1px;
  flex-shrink: 0;
`;

const ModalButtonHorizontalLine = styled.div`
  background-color: ${resolveThemeValue(tokens.color.border.border, 'var(--color-beige)')};
  width: 100%;
  height: 1px;
  margin: 16px 0px;
`;

const Heading = styled.div`
  margin: 0 auto;
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-dark-blue);
  padding-left: 20px;

  &.hidden {
    display: none;
  }
`;

const ConfirmationDialogQuestion = styled.div`
  margin-bottom: 32px;
  font-family: var(--font-poppins);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #1f2244;
`;

const ConfirmationDialogButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button:first-child {
    margin-right: 32px;
  }

  ${mediaSmMax} {
    flex-direction: column;
    width: 100%;
    margin-top: auto;

    button:first-child {
      margin-right: 0px;
      margin-bottom: 16px;
    }
  }
`;

export {
  ModalTopBarWrapper,
  CloseIcon,
  Heading,
  HorizontalLine,
  ModalButtonHorizontalLine,
  BackIcon,
  ConfirmationDialogQuestion,
  ConfirmationDialogButtonsWrapper,
};
