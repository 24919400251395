import styled from 'styled-components';
import { Box } from './box';
import { mediaMdMin, queriesKeys } from '../utils/breakpoints';

const HiddenOnMobile = styled(Box)<{ query?: keyof typeof queriesKeys }>`
  display: none;
  ${({ query = 'mediaMdMin' }) => queriesKeys[query] || mediaMdMin} {
    display: ${(props) => props.$display || 'inline-block'};
  }
`;

const HiddenOnDesktop = styled(Box)<{ query?: keyof typeof queriesKeys }>`
  display: ${(props) => props.$display || 'inline-block'};
  ${({ query = 'mediaMdMin' }) => queriesKeys[query] || mediaMdMin} {
    display: none;
  }
`;

export { HiddenOnMobile, HiddenOnDesktop };
