import { FC } from 'react';
import { NavigationMenuIconProps } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/types';

export const AllInclusiveIcon: FC<NavigationMenuIconProps> = ({
  fillColor = 'currentColor',
  height = 16,
  width = 16,
}) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_849_645)">
      <path
        /* eslint-disable-next-line max-len */
        d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM8.006 3.50038C8.39072 3.50038 8.74359 3.71411 8.93221 4.0762L9.8256 6.0016L11.4871 6.00238C11.8698 5.98737 12.2234 6.19411 12.4002 6.52711L12.4439 6.62099C12.6003 7.00845 12.4966 7.45241 12.2029 7.71348L10.7136 9.18L11.5425 11.083C11.6967 11.4545 11.6182 11.8783 11.35 12.1693L11.2721 12.2452C10.9423 12.5347 10.4647 12.5818 10.0896 12.3651L8.0096 11.1952L5.9327 12.3623C5.58434 12.5635 5.15397 12.5407 4.8306 12.3125L4.7452 12.2452C4.41535 11.9558 4.30663 11.4883 4.47828 11.075L5.3024 9.1816L3.75418 7.65432C3.52647 7.40253 3.44365 7.05026 3.53583 6.72305L3.56916 6.62479C3.72413 6.23585 4.10714 5.98658 4.50667 6.002L6.1872 6.0016L7.09021 4.05506C7.25059 3.7482 7.55245 3.54439 7.89168 3.5067L8.006 3.50038ZM7.98699 4.49691L6.96032 6.71224C6.87843 6.88893 6.70141 7.002 6.50667 7.002L4.5152 7.0016L6.24961 8.71052C6.39697 8.85573 6.43969 9.07671 6.35705 9.26638L5.39846 11.4664C5.39452 11.4759 5.39707 11.4868 5.4048 11.4936C5.41253 11.5004 5.42372 11.5015 5.43779 11.4934L7.76512 10.1861C7.91728 10.1006 8.10298 10.1006 8.25509 10.1862L10.5847 11.4963C10.5936 11.5015 10.6048 11.5004 10.6125 11.4936C10.6203 11.4868 10.6228 11.4759 10.6222 11.4743L9.66024 9.26495C9.57766 9.07529 9.62039 8.85437 9.76773 8.70919L11.5016 7.0016L9.50667 7.002C9.33982 7.002 9.18598 6.919 9.09383 6.7841L9.05312 6.71246L8.03554 4.51827C8.02979 4.50727 8.01841 4.50038 8.006 4.50038L7.98703 4.50241L7.98699 4.49691Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_849_645">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
