import { FC, ReactElement, useState } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import styled, { css } from 'styled-components';
import { ArrowDownIcon } from '~/src/components/layout/headers/icons-and-images/ArrowDownIcon';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

const CollapsibleVariants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  close: {
    opacity: 0,
    height: 0,
  },
};

const IconAnimationVariants: Variants = {
  animate: (isOpen: boolean) => ({
    rotate: isOpen ? 180 : 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      ease: 'easeInOut',
    },
  }),
};

interface MobileMenuItemProps {
  localisedText: string | ReactElement;
  isOpenByDefault?: boolean;
  noPaddingSides?: boolean;
}

export const MobileMenuItemLayout: FC<MobileMenuItemProps> = ({
  localisedText,
  children,
  isOpenByDefault = false,
  noPaddingSides = false,
}) => {
  const [isOpen, setIsOpen] = useState(() => isOpenByDefault);

  return (
    <MenuContainer noPaddingSides={noPaddingSides}>
      <MenuButton type="button" onClick={() => setIsOpen((state) => !state)} additionalPadding={noPaddingSides}>
        {localisedText}
        <IconContainer variants={IconAnimationVariants} animate="animate" custom={isOpen}>
          <ArrowDownIcon />
        </IconContainer>
      </MenuButton>
      <AnimatePresence initial={false} presenceAffectsLayout={true}>
        {isOpen && (
          <CollapsibleElement
            variants={CollapsibleVariants}
            initial="close"
            exit="close"
            animate="open"
            key="collapsible"
          >
            {children}
          </CollapsibleElement>
        )}
      </AnimatePresence>
    </MenuContainer>
  );
};

const MenuButton = styled.button<{ additionalPadding: boolean }>`
  display: flex;
  position: relative;
  color: ${resolveThemeValue(tokens.color.text.textPrimary, '#1f2244')};
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 2px;
  text-align: left;
  width: 100%;
  text-transform: uppercase;

  .isFWC & {
    background: transparent;
    border: none;
    text-transform: initial;
    font-size: 16px;
    letter-spacing: normal;
  }

  ${({ additionalPadding }) =>
    additionalPadding &&
    css`
      padding: 0 16px;
    `}
`;

const CollapsibleElement = styled(motion.div)`
  overflow: hidden;
`;

const MenuContainer = styled(motion.div)<{ noPaddingSides: boolean }>`
  padding: 24px 16px 0;

  &:last-child {
    padding: 24px 16px;
  }

  &:last-of-type {
    padding: 24px 16px 100px;
  }

  ${({ noPaddingSides }) =>
    noPaddingSides &&
    css`
      padding: 24px 0 0;
    `}
`;

const IconContainer = styled(motion.div)`
  margin-left: auto;
`;
