// TODO ready for the future items
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Country, Language, routes, RouteType } from '~/src/utils/routing';
import { campervans } from '~/src/components/landing/summerCompetition/CS/Favorites';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';

export interface IMenuItems {
  label: string;
  route: RouteType;
  hash?: string;
  supportedLanguage?: Language;
}

export const getPageType = (pathname: string, isUserAuthenticated: boolean): PageType => {
  switch (true) {
    case pathname === routes.carDetail.template:
      return 'isCarPage';
    case [
      routes.blog.template,
      routes.blogDetailPreview.template,
      routes.blogDetail.template,
      routes.blogCategory.template,
    ].includes(pathname):
      return 'isBlogPage';
    case pathname === routes.search.template:
      return 'isSearchPage';
    case pathname === routes.password_recovery.template:
      return 'isPasswordRecoveryPage';
    case ['/404', '/error', '/500'].includes(pathname):
      return 'isErrorPage';
    case [
      routes.operatorDashboard.template,
      routes.create_offerOperator.template,
      routes.operatorBookingsDashboard.template,
      routes.operatorCalendar.template,
      freewayRoutesTyped.operatorStationsDashboard.template,
      freewayRoutesTyped.operatorStationView.template,
      freewayRoutesTyped.operatorStationCreate.template,
      freewayRoutesTyped.operatorStationEdit.template,
      freewayRoutesTyped.operatorVehiclesDashboard.template,
      freewayRoutesTyped.operatorVehicleCreate.template,
      freewayRoutesTyped.operatorVehicleEdit.template,
      freewayRoutesTyped.operatorVehicleView.template,
      freewayRoutesTyped.operatorAvailabilityCalendar.template,
      routes.checkin.template,
      routes.checkout.template,
      freewayRoutesTyped.operatorReportsDropoffs.template,
      freewayRoutesTyped.operatorReportsPickups.template,
    ].includes(pathname):
      return 'isOperatorPage';
    case pathname === routes.renterDashboard.template:
      return 'isRenterPage';
    case pathname === routes.accountCompletion.template:
      return 'isAccountCompletion';
    case pathname.includes('profile'):
      return 'isSettingsPage';
    case [
      routes.create_offer.template,
      routes.reviewBookingDetail.template,
      routes.reviewBookingDetailOperator.template,
      '/booking',
      routes.bookingDetail.template,
    ].includes(pathname):
      if (!isUserAuthenticated) return 'isBookingPageUnauthenticated';
      return 'isBookingPage';
    case pathname === routes.bookingKyc.template:
      return 'isKYCPage';
    case pathname === routes.managerBooking.template:
      return 'isManagerBookingPage';
    case pathname === routes.operatorBooking.template:
      return 'isOperatorBookingPage';
    case pathname.includes('first-time-campervaning-landing') || pathname.includes('roadtrips'):
      return 'isFirsTimeCapervanLandingPage';
    case pathname === routes.cookies.template:
      return 'isCookiePage';
    case pathname === routes.inbox.template:
      return 'isMessagePage';
    case pathname === routes.listingWizardEntry.template:
      return 'isLWEntry';
    case pathname === routes.listingWizardLanding.template:
      return 'isLWLanding';
    case pathname === routes.campiriSpain.template || pathname === routes.campiriPortugal.template:
      return 'isCampiriSpainLP';
    default:
      return null;
  }
};

export type PageType =
  | 'isCarPage'
  | 'isBlogPage'
  | 'isSearchPage'
  | 'isPasswordRecoveryPage'
  | 'isErrorPage'
  | 'isOperatorPage'
  | 'isRenterPage'
  | 'isSettingsPage'
  | 'isBookingPage'
  | 'isCICOPage'
  | 'isKYCPage'
  | 'isManagerBookingPage'
  | 'isOperatorBookingPage'
  | 'isBookingPageUnauthenticated'
  | 'isFirsTimeCapervanLandingPage'
  | 'isCookiePage'
  | 'isAccountCompletion'
  | 'isMessagePage'
  | 'isLWEntry'
  | 'isLWLanding'
  | 'isCampiriSpainLP'
  | 'isSticky'
  | 'showMenuExpanded'
  | null;

const getTopMenuItemsCSCZ: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.caravans' })),
    route: routes.search,
  },
  {
    label: i18n._(t({ id: 'tabs.spain' })),
    route: routes.campiriSpain,
  },
  {
    label: i18n._(t({ id: 'tabs.voucher' })),
    route: 'https://shop.campiri.com/',
  },
  {
    label: i18n._(t({ id: 'tabs.addYourCaravan' })),
    route: routes.listingWizardEntry,
  },
  {
    label: 'Blog',
    route: routes.blog,
  },
  {
    label: i18n._(t({ id: 'tabs.support' })),
    route: routes.faq,
  },
];

const getTopMenuItemsENCZ: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.caravans' })),
    route: routes.search,
  },
  {
    label: i18n._(t({ id: 'tabs.ourMission' })),
    route: routes.mission,
  },
  {
    label: i18n._(t({ id: 'tabs.addYourCaravan' })),
    route: routes.listingWizardEntry,
  },
  {
    label: 'Blog',
    route: routes.blog,
  },
  {
    label: i18n._(t({ id: 'tabs.support' })),
    route: routes.faq,
  },
];

const getTopMenuItemsES: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.caravans' })),
    route: routes.search,
  },
  {
    label: i18n._(t({ id: 'tabs.ourMission' })),
    route: routes.mission,
  },
  {
    label: i18n._(t({ id: 'tabs.addYourCaravan' })),
    route: routes.listingWizardEntry,
  },

  {
    label: i18n._(t({ id: 'tabs.support' })),
    route: routes.faq,
  },
];

const getTopMenuItemsENPL: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.caravans' })),
    route: routes.search,
  },
  {
    label: i18n._(t({ id: 'tabs.ourMission' })),
    route: routes.mission,
  },
  {
    label: i18n._(t({ id: 'tabs.addYourCaravan' })),
    route: routes.listingWizardEntry,
  },

  {
    label: i18n._(t({ id: 'tabs.support' })),
    route: routes.faq,
  },
];

const getTopMenuItemsPLPL: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.caravans' })),
    route: routes.search,
  },
  {
    label: 'Hiszpania',
    route: routes.campiriSpain,
  },
  {
    label: i18n._(t({ id: 'tabs.addYourCaravan' })),
    route: routes.listingWizardEntry,
  },
  {
    label: 'Blog',
    route: routes.blog,
  },
  {
    label: i18n._(t({ id: 'tabs.support' })),
    route: routes.faq,
  },
];

const getOperatorMenuItems: (isFWC: boolean, isStationManager?: boolean) => IMenuItems[] = (
  isFWC,
  isStationManager = false
) =>
  [
    isFWC && {
      label: t({
        id: 'dashboard.heading.availabilityCalendar',
        message: 'Availability Calendar',
      }),
      route: freewayRoutesTyped.operatorAvailabilityCalendar,
    },
    {
      label: i18n._(t({ id: 'dashboard.heading.bookings' })),
      route: routes.operatorBookingsDashboard,
    },
    isFWC && {
      label: t({
        id: 'dashboard.heading.vehicles',
        message: 'Vehicles',
      }),
      route: freewayRoutesTyped.operatorVehiclesDashboard,
    },
    isFWC &&
      !isStationManager && {
        label: t({
          id: 'dashboard.heading.stations',
          message: 'Stations',
        }),
        route: freewayRoutesTyped.operatorStationsDashboard,
      },
    {
      label: i18n._(t({ id: 'dashboard.heading.listings' })),
      route: routes.operatorDashboard,
    },
    isFWC && {
      label: i18n._(t({ id: 'fwc.operator.reports.pickups.heading', message: 'Pickups' })),
      route: freewayRoutesTyped.operatorReportsPickups,
    },
    isFWC && {
      label: i18n._(t({ id: 'fwc.operator.reports.dropoffs.heading', message: 'Dropoffs' })),
      route: freewayRoutesTyped.operatorReportsDropoffs,
    },
    !isFWC && {
      label: i18n._(t({ id: 'dashboard.heading.calendar' })),
      route: routes.operatorCalendar,
    },
    {
      label: i18n._(t({ id: 'tabs.support' })),
      route: routes.faq,
    },
  ].filter(Boolean);

const getRenterMenuItems: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'dashboard.heading.myTrips' })),
    route: routes.renterDashboard,
  },
  {
    label: i18n._(t({ id: 'tabs.support' })),
    route: routes.faq,
  },
];

const getTopMenuItemsSK: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.caravans' })),
    route: routes.search,
  },
  {
    label: i18n._(t({ id: 'tabs.ourMission' })),
    route: routes.mission,
  },
  {
    label: i18n._(t({ id: 'tabs.addYourCaravan' })),
    route: routes.listingWizardEntry,
  },
  {
    label: 'Blog',
    route: routes.blog,
  },
  {
    label: i18n._(t({ id: 'tabs.support' })),
    route: routes.faq,
  },
];

const getFooterCampervansMenuItems: () => Partial<
  Record<Country, Record<string, Array<Omit<IMenuItems, 'route'> & { route: RouteType | string }>>>
> = () => ({
  [Country.cz]: {
    campervans: [
      {
        label: i18n._(t({ id: 'tabs.caravans' })),
        route: routes.search,
      },
      {
        label: i18n._(t({ id: 'tabs.spain' })),
        route: routes.campiriSpain,
      },
      {
        label: 'Portugalsko',
        route: routes.campiriPortugal,
        supportedLanguage: Language.cs,
      },
      {
        label: i18n._(t({ id: 'tabs.voucher' })),
        route: 'https://shop.campiri.com/',
      },
    ],
    owners: [
      {
        label: i18n._(t({ id: 'tabs.addYourCaravan' })),
        route: routes.listingWizardEntry,
      },
      {
        label: i18n._(t({ id: 'tabs.whyRentOnCampiri' })),
        route: routes.listingWizardLanding,
      },
      {
        label: i18n._(t({ id: 'menu.P2PAllInclusive' })),
        route: routes.p2pAllInclusive,
        supportedLanguage: Language.cs,
      },
      /* {
      label: i18n._(t({ id: 'tab.ccc' })),
      route: routes.landingCampersClub,
      supportedLanguage: Language.cs,
    }, */ {
        label: i18n._(t({ id: 'menu.service' })),
        route: 'https://www.campiriservis.cz/',
      },
      {
        label: i18n._(t({ id: 'menu.parking' })),
        route: 'https://www.campiriservis.cz/parking.html',
      },
    ],
    support: [
      {
        label: i18n._(t({ id: 'tabs.frequentlyAskedQuestions' })),
        route: routes.faq,
      },
      {
        label: 'Blog',
        route: routes.blog,
      },
      {
        label: i18n._(t({ id: 'tabs.contacts' })),
        route: routes.faq,
        hash: '#contact',
      },
      {
        label: i18n._(t({ id: 'tabs.ourMission' })),
        route: routes.mission,
      },
      {
        label: i18n._(t({ id: 'tabs.jobs' })),
        route: routes.mission,
        hash: '#job',
      },
    ],
  },
  [Country.sk]: {
    campervans: [
      {
        label: i18n._(t({ id: 'tabs.caravans' })),
        route: routes.search,
      },
    ],
    owners: [
      {
        label: i18n._(t({ id: 'tabs.addYourCaravan' })),
        route: routes.listingWizardEntry,
      },
      {
        label: i18n._(t({ id: 'tabs.whyRentOnCampiri' })),
        route: routes.listingWizardLanding,
      },
    ],
    support: [
      {
        label: i18n._(t({ id: 'tabs.frequentlyAskedQuestions' })),
        route: routes.faq,
      },
      {
        label: 'Blog',
        route: routes.blog,
      },
      {
        label: i18n._(t({ id: 'tabs.contacts' })),
        route: routes.faq,
        hash: '#contact',
      },
      {
        label: i18n._(t({ id: 'tabs.ourMission' })),
        route: routes.mission,
      },
      {
        label: i18n._(t({ id: 'tabs.jobs' })),
        route: routes.mission,
        hash: '#job',
      },
    ],
  },
  [Country.pl]: {
    campervans: [
      {
        label: i18n._(t({ id: 'tabs.caravans' })),
        route: routes.search,
      },
      {
        label: i18n._(t({ id: 'tabs.spain' })),
        route: routes.campiriSpain,
      },
    ],
    owners: [
      {
        label: i18n._(t({ id: 'tabs.addYourCaravan' })),
        route: routes.listingWizardEntry,
      },
      {
        label: i18n._(t({ id: 'tabs.whyRentOnCampiri' })),
        route: routes.listingWizardLanding,
      },
    ],
    support: [
      {
        label: i18n._(t({ id: 'tabs.frequentlyAskedQuestions' })),
        route: routes.faq,
      },
      {
        label: 'Blog',
        route: routes.blog,
      },
      {
        label: i18n._(t({ id: 'tabs.contacts' })),
        route: routes.faq,
        hash: '#contact',
      },
      {
        label: i18n._(t({ id: 'tabs.ourMission' })),
        route: routes.mission,
      },
      {
        label: i18n._(t({ id: 'tabs.jobs' })),
        route: routes.mission,
        hash: '#job',
      },
    ],
  },
  [Country.es]: {
    campervans: [
      {
        label: i18n._(t({ id: 'tabs.caravans' })),
        route: routes.search,
      },
    ],
    owners: [
      {
        label: i18n._(t({ id: 'tabs.addYourCaravan' })),
        route: routes.listingWizardEntry,
      },
      {
        label: i18n._(t({ id: 'tabs.whyRentOnCampiri' })),
        route: routes.listingWizardLanding,
      },
    ],
    support: [
      {
        label: i18n._(t({ id: 'tabs.frequentlyAskedQuestions' })),
        route: routes.faq,
      },
      {
        label: i18n._(t({ id: 'tabs.contacts' })),
        route: routes.faq,
        hash: '#contact',
      },
      {
        label: i18n._(t({ id: 'tabs.ourMission' })),
        route: routes.mission,
      },
      {
        label: i18n._(t({ id: 'tabs.jobs' })),
        route: routes.mission,
        hash: '#job',
      },
    ],
  },
});

export const getFooterLinksTitleByKey = (key: string): string => {
  switch (key) {
    case 'campervans':
      return i18n._(t({ id: 'tabs.title.campervans' }));
    case 'owners':
      return i18n._(t({ id: 'tabs.title.owners' }));
    case 'support':
      return i18n._(t({ id: 'tabs.title.support' }));
    default:
      return '';
  }
};

const getFooterBottomMenuItems: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.complaintsRules' })),
    route: routes.reclamationPage,
  },
  {
    label: i18n._(t({ id: 'tabs.termsAndConditions' })),
    route: routes.tos,
  },
  {
    label: i18n._(t({ id: 'tabs.personalDataProtection' })),
    route: routes.privacy,
  },
];

const getFooterBottomMenuItemsNonSKCZ: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.termsAndConditions' })),
    route: routes.tos,
  },
  {
    label: i18n._(t({ id: 'tabs.personalDataProtection' })),
    route: routes.privacy,
  },
];

const getFooterBottomMenuItemsPL: () => IMenuItems[] = () => [
  {
    label: i18n._(t({ id: 'tabs.termsAndConditions' })),
    route: routes.tos,
  },
  {
    label: i18n._(t({ id: 'header.termsOfPromotion.heading' })),
    route: routes.termsOfPromotion,
  },
  {
    label: i18n._(t({ id: 'tabs.personalDataProtection' })),
    route: routes.privacy,
  },
];

enum Menu {
  USER = 'USER',
  MENU = 'MENU',
}

export {
  getTopMenuItemsCSCZ,
  getTopMenuItemsENCZ,
  getTopMenuItemsENPL,
  getTopMenuItemsPLPL,
  getTopMenuItemsSK,
  getFooterBottomMenuItems,
  getFooterBottomMenuItemsPL,
  getFooterBottomMenuItemsNonSKCZ,
  Menu,
  getOperatorMenuItems,
  getRenterMenuItems,
  getTopMenuItemsES,
  getFooterCampervansMenuItems,
};
