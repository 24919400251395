/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import SearchBar from '~/src/components/search/search-bar/SearchBar';
import { searchInfoSelector } from '~/src/redux/selectors/search';
import { userSelector } from '~/src/redux/selectors/user';
import { UserReduxType } from '~/src/redux/reducers/user';
import { Menu } from '~/src/components/layout/headers/utils';
import DotContainerComponent from '~/src/components/layout/headers/DotContainerComponent';
import HomepageLogoLink from '~/src/components/layout/headers/HomepageLogoLink';
import MobileMenuExpanded from './navigation-menu/MobileMenuExpanded';
import MobileMenuComponent from './navigation-menu/MobileMenuComponent';
import UserMenuComponent from './UserMenu';
import { UserNavItem } from './styles';
import { ModalType } from '../../modal/modalTypes';
import { openDesktopOrPhoneModal } from '../../modal/utils';
import { screenSelector } from '~/src/redux/selectors/screen';
import { SettingsProps } from '~/src/redux/reducers/settings';
import { getLayoutFillNextImage } from '~/src/utils/images';
import { TypedIBrowser } from '~/src/redux/reducers/rootReducer';

interface Props {
  dispatch: any;
  showMenuExpanded: any;
  toggleMenu: any;
  user: UserReduxType;
  screen: TypedIBrowser;
  settings: SettingsProps;
  messagesCount: number;
  messagingEnabled: boolean;
}

const loginArrowIcon = (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.14643 0.520456C0.341683 0.325185 0.658266 0.32517 0.853537 0.520423L8 7.66622L15.1465 0.520423C15.3417 0.32517 15.6583 0.325185 15.8536 0.520456C16.0488 0.715727 16.0488 1.03231 15.8535 1.22756L8.70714 8.37329C8.70705 8.37338 8.70696 8.37347 8.70687 8.37356C8.61411 8.46638 8.50398 8.54003 8.38276 8.59029C8.26142 8.6406 8.13135 8.6665 8 8.6665C7.86865 8.6665 7.73858 8.6406 7.61724 8.59029C7.49602 8.54003 7.38589 8.46638 7.29313 8.37356C7.29304 8.37347 7.29295 8.37338 7.29286 8.37329L0.146463 1.22756C-0.0488081 1.03231 -0.0488229 0.715727 0.14643 0.520456Z"
      fill="#1F2244"
    />
  </svg>
);

export function Header({
  toggleMenu,
  dispatch,
  screen,
  showMenuExpanded,
  user,
  settings,
  messagesCount,
  messagingEnabled,
}: Props) {
  const [showUserMenu, setShowUserMenu] = useState(false);

  const menuRef = useRef<HTMLDivElement>();

  return (
    <>
      <PageOverlay active={!!showMenuExpanded} onClick={() => toggleMenu(null)} />
      <HeaderRootsWrap>
        <HeaderRoot>
          <HeaderContainer className={showMenuExpanded ? 'expanded' : ''}>
            <NavRoot className="search-page">
              <PaddingWrapper>
                <HomepageLogoLink isDark={true} />
              </PaddingWrapper>
              <SearchMenuWrap>
                <SearchBar />
                <MobileMenuTrigger
                  id="mobile-menu-trigger"
                  className={showMenuExpanded === Menu.MENU ? 'expanded search-m-trigger' : 'search-m-trigger'}
                >
                  <DotContainerComponent
                    onClickCallback={() => {
                      toggleMenu(Menu.MENU);
                    }}
                    isBlack={true}
                  />
                </MobileMenuTrigger>
                <ProfileContainer>
                  <DesktopWrapper ref={menuRef}>
                    {user.isAuthenticated ? (
                      <UserIcon
                        showMessagesCount={!!(messagesCount && messagingEnabled)}
                        className={`
                            ${user?.customerData?.profilePicture ? 'hasPic' : ''}
                            userClickableDiv
                            `}
                        onClick={() => {
                          setShowUserMenu((prev) => !prev);
                          toggleMenu(null);
                        }}
                      >
                        {user.customerData.profilePicture ? (
                          <UserProfilePictureWrapper>
                            {getLayoutFillNextImage(
                              user.customerData.profilePicture,
                              'profilePic',
                              'profilePic',
                              '32px'
                            )}
                          </UserProfilePictureWrapper>
                        ) : (
                          <ImgWrapper>
                            <img width={20} height={20} src="/static/images/login/avatar.svg" alt="" />
                          </ImgWrapper>
                        )}

                        {/* <UserNavItem className={''}>{shortName(user.customerData.name)}</UserNavItem> */}
                        <LoginArrow className={showUserMenu ? 'up' : ''}>{loginArrowIcon}</LoginArrow>
                      </UserIcon>
                    ) : (
                      <Flex
                        onClick={() => {
                          toggleMenu(null);
                          setShowUserMenu(false);
                          openDesktopOrPhoneModal(ModalType.LOGIN, screen, dispatch);
                        }}
                      >
                        <img src="/static/images/login/login_dark.svg" alt="" />

                        <UserNavItem className="" data-testid="loggedInNav">
                          <span data-testid="login-button">
                            <Trans id="labels.login" message="Přihlášení" />
                          </span>
                        </UserNavItem>
                      </Flex>
                    )}
                    {showUserMenu && (
                      <UserMenuComponent
                        menuRef={menuRef}
                        setShowUserMenu={setShowUserMenu}
                        messagesCount={messagesCount}
                        messagingEnabled={messagingEnabled}
                      />
                    )}
                  </DesktopWrapper>
                </ProfileContainer>
              </SearchMenuWrap>

              <MobileMenuExpanded
                isInSearchPageMenu={true}
                showMenuExpanded={showMenuExpanded}
                settings={settings}
                messagesCount={messagesCount}
                messagingEnabled={messagingEnabled}
              />
            </NavRoot>
          </HeaderContainer>
        </HeaderRoot>

        <MobileMenuComponent
          showMenuExpanded={showMenuExpanded}
          toggleMenu={toggleMenu}
          isSticky={false}
          isSearchPage={true}
          isWhiteVersion={true}
          messagesCount={messagesCount}
          messagingEnabled={messagingEnabled}
        />
      </HeaderRootsWrap>
    </>
  );
}

const HeaderRootsWrap = styled.div`
  position: relative;
  z-index: 10100;
  width: 100%;

  &.sticky {
    position: fixed;
    box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
    background-color: var(--color-white);
    width: 100%;
    height: 64px;
    z-index: 1001;
    top: -1px;

    .bar1,
    .bar2,
    .bar3 {
      background-color: #000 !important;
    }

    .logo {
      display: none !important;
    }
  }
`;

const HeaderRoot = styled.header`
  left: 0;
  width: 100%;
  height: auto;
  position: unset;
  top: 0px;
  z-index: 1000;
`;

const DesktopWrapper = styled.div`
  position: relative;

  @media ${deviceMaxWidth.phone} {
    display: none;
  }
`;

const HeaderContainer = styled.div`
  box-shadow: 0 1px 0 0 var(--color-beige);
  background-color: var(--color-white);
  position: relative;

  &.expanded {
    @media ${deviceMaxWidth.phone} {
      height: 65px;
      nav {
        align-items: flex-start;
        flex-direction: column;

        > div {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100%;
          top: 65px;
          left: 0;
          background: white;
          border-top: 1px solid var(--color-beige);
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          padding: 8px 0px 16px;
          opacity: 1;
          /* transition: opacity 1s ease-in-out; */

          a {
            margin: 0;
            font-family: var(--font-poppins);
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: var(--color-dark-blue);

            ::before {
              display: none !important;
            }
          }
        }
      }
    }

    .search-page {
      .menu {
        display: flex;
      }
    }

    .showed {
      width: calc(100% - 62px);
    }
  }
`;

const NavRoot = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1272px;

  .menu {
    @media ${deviceMinWidth.phone} {
      flex-direction: column;
      display: none;
      position: absolute;
      right: 80px;

      top: 92px;
      z-index: 12;
      padding: 24px 16px;
      border-radius: 8px;
      box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
      background-color: var(--color-white);
      a {
        margin: 0;
        font-family: var(--font-poppins);
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #1f2244;

        &:not(:last-child) {
          margin-bottom: 36px;
        }

        /* &:last-of-type {
          margin-bottom: 0;
        } */

        :before {
          display: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      > div {
        > a {
          margin-bottom: 0;
        }
      }
    }
    @media screen and (max-width: 1300px) {
      right: 16px;
    }
  }

  &.search-page {
    @media ${deviceMinWidth.phone} {
      padding: 0 16px;
    }
    position: relative;
    justify-content: flex-start;
    justify-content: space-between;

    .bar1,
    .bar2,
    .bar3 {
      background-color: #1f2244;
    }

    .search-m-trigger {
      display: none;

      height: 80px;
      width: 84px;
      border-left: 1px solid var(--color-beige);
      border-right: 1px solid var(--color-beige);
      @media screen and (min-width: 860px) {
        display: flex;
        /* position: absolute; */
        /* right: 28px; */
        /* top: 28px; */
      }
      @media screen and (max-width: 1100px) {
        right: 16px;
      }
    }
  }

  @media ${deviceMaxWidth.phone} {
    padding: 16px;
  }
`;

const SearchMenuWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;

  > div:first-of-type {
    padding-right: 38px;
  }

  @media screen and (max-width: 1165px) {
    max-width: 905px;
  }
  @media screen and (max-width: 1100px) {
    max-width: 700px;
    border: medium none;
  }
`;

const NavRootExpanded = styled.nav<{ hero?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ hero }) => (hero ? '1072px' : '1272px')};
  position: relative;
  padding: ${({ hero }) => (hero ? '30px 16px' : '16px')};

  @media ${deviceMaxWidth.phone} {
    padding: 16px;
  }
`;

const ProfileContainer = styled.div`
  margin: auto;
  height: 20px;
  cursor: pointer;
  margin: auto 20px;
`;

const MobileMenuTrigger = styled.div`
  right: 0;
  top: 0;
  cursor: pointer;
  margin-left: auto;

  @media ${deviceMaxWidth.phone} {
    right: unset;
    top: unset;
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 24px;
    height: 1px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;

    &.black {
      background-color: #000;
    }
  }

  &.expanded {
    .bar1,
    .bar3 {
      background-color: #000;
    }

    .bar1 {
      width: 25px;
      transform: rotate(-45deg) translate(-7px, 1px);
    }

    .bar2 {
      opacity: 0;
    }

    .bar3 {
      width: 25px;
      transform: rotate(45deg) translate(-8px, -3px);
    }
  }
`;

const PageOverlay = styled.div<{ active?: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #000000;
  z-index: 1000;
  visibility: hidden;
  overflow: hidden;
  overscroll-behavior: contain;
  @media ${deviceMinWidth.phone} {
    display: none !important;
  }
  ${({ active }) =>
    active &&
    `
      visibility: visible;
  `}
`;

const LoginWrapper = styled.div`
  a {
    color: var(--color-beige);
    cursor: pointer;
    display: block;

    &:hover {
      color: var(--color-beige);
    }
  }
`;

const Flex = styled.div`
  display: flex;
`;

const UserIcon = styled.div<{ showMessagesCount: boolean }>`
  display: flex;
  border-radius: 16px;
  width: 64px;
  height: 32px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #edeae3;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ff5e55;
    border: 2px solid #edeae3;
    position: absolute;
    right: -2px;
    top: -2px;
    display: ${(p) => (p.showMessagesCount ? 'block' : 'none')};
  }

  &.hasPic {
    justify-content: flex-start;
  }
`;

const LoginArrow = styled.div`
  margin-left: 8px;
  transform: all linear 0.3;

  svg path {
    fill: #1f2244;
  }

  &.up {
    transform: rotate(180deg);
  }
`;

const HPLoginWrapper = styled(LoginWrapper)`
  border-left: 1px solid var(--color-dark-grey);

  .user {
    display: flex;

    img {
      cursor: pointer;
      margin-left: 33px;
    }
  }

  a {
    margin: auto 0 auto 10px;
  }
`;
const PaddingWrapper = styled.section`
  padding: 16px 0px;

  @media ${deviceMaxWidth.phone} {
    padding: 0px;
  }
`;

const UserProfilePictureWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  width: 32px;
  height: 32px;

  img {
    border-radius: 16px;
  }
`;

const ImgWrapper = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const mapStateToProps = (state) => ({
  searchInfo: searchInfoSelector(state),
  user: userSelector(state),
  screen: screenSelector(state),
  settings: state.settings,
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(Header);
