import { getFormattedPrice, getFormattedPriceWithForcedCurrency } from 'features/utils/price';

const getFormattedPriceWithoutCurrency = (amount) => {
  if (!amount) return '0';
  const price = new Intl.NumberFormat('cs-CZ', {}).format(amount.toFixed(0));
  return price;
};

const getFormattedPriceWithDecimal = (amount) => {
  if (!amount) return '0';

  const price = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'CZK' }).format(amount);
  return price;
};

export {
  getFormattedPrice,
  getFormattedPriceWithDecimal,
  getFormattedPriceWithoutCurrency,
  getFormattedPriceWithForcedCurrency,
};
