/* eslint-disable @typescript-eslint/indent */
/* eslint-disable max-len */
import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import { searchInfoSelector } from '~/src/redux/selectors/search';
import { showModal } from '~/src/redux/actions/settings';
import { userSelector } from '~/src/redux/selectors/user';
import { Menu } from './utils';
import { HomepageLogoLink } from './HomepageLogoLink';
import UserMenuComponent from './UserMenu';

import MobileMenuComponent from './navigation-menu/MobileMenuComponent';
import MobileMenuExpanded from './navigation-menu/MobileMenuExpanded';
import { ModalType } from '../../modal/modalTypes';

import { screenSelector } from '~/src/redux/selectors/screen';
import ImageAvatar from './icons-and-images/ImageAvatar';
import ImageAvatarLight from './icons-and-images/ImageAvatarLight';

import { SettingsProps } from '~/src/redux/reducers/settings';
import { UserReduxType } from '~/src/redux/reducers/user';
import { getLayoutFillNextImage } from '~/src/utils/images';
import { InboxReduxType } from '~/src/redux/reducers/inbox';
import { INBOX_TABS } from '../../messages/utils';

import { LoginButton } from '~/src/components/layout/headers/navigation-menu/V2/LoginButton';
import { openDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { UserNavItem } from './styles';
import ImageLoginDark from '~/src/components/layout/headers/icons-and-images/ImageLoginDark';
import ImageLogin from '~/src/components/layout/headers/icons-and-images/ImageLogin';
import { MemoizedMenuControl } from '~/src/components/layout/headers/basicHeader/MenuItemsControl';
import UserMenuDropdown from '~/src/components/layout/headers/basicHeader/UserMenuDropdown';

interface Props {
  toggleMenu: (menu: Menu | null) => void;
  isBookingPage?: boolean;
  isSticky: boolean;
  showMenuExpanded: Menu;
  showFullMenu: boolean;
  isWhiteVersion?: boolean;
  hasSmallerWidth?: boolean;
  isManagerBookingPage?: boolean;
  isOperatorBookingPage?: boolean;
  settings: SettingsProps;
  isOperatorPage?: boolean;
  isRenterPage?: boolean;
  isSettingsPage?: boolean;
  searchInfo: any;
  dispatch: any;
  user: UserReduxType;
  screen: any;
  fullWidth?: boolean;
  messagesCount?: number;
  messagingEnabled?: boolean;
  inbox: InboxReduxType;
  isMessagePage?: boolean;
}

const Header = ({
  searchInfo,
  dispatch,
  user,
  screen,
  toggleMenu,
  isBookingPage,
  isSticky,
  showMenuExpanded,
  showFullMenu,
  isWhiteVersion,
  hasSmallerWidth = false,
  isManagerBookingPage = false,
  isOperatorBookingPage = false,
  isOperatorPage = false,
  isRenterPage = false,
  isSettingsPage = false,
  settings,
  fullWidth = false,
  messagesCount = 0,
  messagingEnabled = true,
  inbox,
  isMessagePage = false,
}: Props) => {
  const displayRenterMenu =
    showFullMenu && (isRenterPage || isSettingsPage || isBookingPage) && !isOperatorPage && !isOperatorBookingPage;
  const isInRenterInbox = inbox.activeInboxTab === INBOX_TABS.RENTER && isMessagePage;

  const displayOperatorMenu = showFullMenu && !isRenterPage && (isOperatorPage || isOperatorBookingPage);
  const isInOperatorInbox = inbox.activeInboxTab === INBOX_TABS.OWNER && isMessagePage;

  const specialMenuToDisplay = (): 'renter' | 'operator' | '' => {
    if (displayRenterMenu || isInRenterInbox) return 'renter';
    if (isInOperatorInbox || displayOperatorMenu) return 'operator';

    return '';
  };

  return (
    <>
      <PageOverlay active={!!showMenuExpanded} onClick={() => toggleMenu(null)} />
      <HeaderRootsWrap offset={settings.specialOfferBarRef?.current?.offsetHeight} className={isSticky ? 'sticky' : ''}>
        <HomePageFixedSearch
          onClick={() => {
            dispatch(showModal(ModalType.SEARCH_WIDGET_MODAL_FWC_PORT, { type: 'place' }));
          }}
          className={isSticky ? 'showed' : ''}
        >
          <span className="label">
            <Trans id="autoComplete.startPlace.label" message="Odkud vyrazíte" />
          </span>
          <span className="place">
            {searchInfo?.place?.place_name ? (
              searchInfo.place.place_name
            ) : (
              <Trans id="autoComplete.startPlace.placeHolder" message="Zadejte místo" />
            )}
          </span>
        </HomePageFixedSearch>

        {!showMenuExpanded ? (
          <HeaderRootExpanded>
            <HeaderContainerExpanded hero={!isWhiteVersion}>
              <NavRootExpanded hero={!isWhiteVersion} hasSmallerWidth={hasSmallerWidth} fullWidth={fullWidth}>
                <HomepageLogoLink isDark={isWhiteVersion} />
                {isManagerBookingPage && (
                  <>
                    <ManagerDivider />
                    <CaravanManager>
                      <Trans id="header.caravanManager" message="Manager karavanu" />
                    </CaravanManager>
                  </>
                )}

                {isOperatorBookingPage && (
                  <>
                    <ManagerDivider />
                    <CaravanManager>
                      <Trans id="header.caravanOperator" message="Campervan operator" />
                    </CaravanManager>
                  </>
                )}
                <MenuItems>
                  {/* todo : feature toggle / ab testing */}
                  <MemoizedMenuControl specialMenuType={specialMenuToDisplay()} isWhiteVersion={isWhiteVersion} />

                  <Divider className={isWhiteVersion ? '' : 'hero'} />
                  <LastItem>
                    <UserMenuDropdown
                      isWhiteVersion={isWhiteVersion}
                      isBookingPage={isBookingPage}
                      messagesCount={messagesCount}
                      messagingEnabled={messagingEnabled}
                      isOperatorPage={isOperatorPage}
                      isRenterPage={isRenterPage}
                    />
                  </LastItem>
                </MenuItems>
              </NavRootExpanded>
            </HeaderContainerExpanded>
          </HeaderRootExpanded>
        ) : (
          <HeaderRoot>
            <HeaderContainer className={showMenuExpanded ? 'expanded' : ''}>
              <NavRoot className="search-page">
                <HomepageLogoLink isDark={true} />
                <MobileMenuExpanded
                  messagesCount={messagesCount}
                  messagingEnabled={messagingEnabled}
                  isOperatorPage={isOperatorPage}
                  isRenterPage={isRenterPage}
                  showMenuExpanded={showMenuExpanded}
                  settings={settings}
                  isMessagePage={isMessagePage}
                />
              </NavRoot>
            </HeaderContainer>
          </HeaderRoot>
        )}

        <MobileMenuComponent
          showMenuExpanded={showMenuExpanded}
          isWhiteVersion={isWhiteVersion}
          toggleMenu={toggleMenu}
          isSticky={isSticky}
          isSearchPage={false}
          messagesCount={messagesCount}
          messagingEnabled={messagingEnabled}
        />
      </HeaderRootsWrap>
    </>
  );
};

export const UserIcon = styled.div<{ showMessagesCount: boolean }>`
  display: flex;
  border-radius: 16px;
  width: 64px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  background: #1f2244;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ff5e55;
    border: 2px solid #1f2244;
    position: absolute;
    right: -2px;
    top: -2px;
    display: ${(p) => (p.showMessagesCount ? 'block' : 'none')};
  }

  &.light {
    background: #edeae3;

    &::after {
      border: 2px solid #edeae3;
    }
  }

  &.hasPic {
    justify-content: inherit;
  }
`;

const HeaderRootsWrap = styled.div<{ offset?: number }>`
  position: relative;
  z-index: 1001;
  width: 100%;

  &.sticky {
    position: fixed;
    box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
    background-color: var(--color-white);
    width: 100%;
    height: 64px;
    z-index: 1001;

    top: -1px;
    /* ${({ offset }) =>
      offset &&
      css`
        top: ${offset}px;
      `} */

    .bar1,
    .bar2,
    .bar3 {
      background-color: #000 !important;
    }

    .logo {
      display: none !important;
    }
  }
`;
const HomePageFixedSearch = styled.div`
  width: calc(100% - 124px);
  height: 65px;
  /* border-right: 1px solid var(--color-beige); */
  display: none;

  &.showed {
    display: block;
    position: absolute;
    z-index: 2;
  }

  .label {
    margin: 14px 0 8px 16px;
    font-family: var(--font-poppins);
    font-size: 12px;
    display: block;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--color-dark-blue);
  }

  .place {
    font-size: 16px;
    margin-left: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--color-dark-blue);
    white-space: nowrap;
    font-family: var(--font-poppins);
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 8px;
    display: block;
    opacity: 0.5;
  }
`;
const HeaderRoot = styled.header`
  left: 0;
  width: 100%;
  height: auto;
  position: unset;
  top: 0px;
  z-index: 1000;
`;
const HeaderRootExpanded = styled.header`
  left: 0;
  width: 100%;
  height: auto;
  top: 0px;
  z-index: 1000;
  background: transparent;
`;
const HeaderContainer = styled.div`
  box-shadow: 0 1px 0 0 var(--color-beige);
  background-color: var(--color-white);
  position: relative;

  &.expanded {
    @media ${deviceMaxWidth.phone} {
      height: 65px;
      nav {
        align-items: flex-start;
        flex-direction: column;

        > div {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100%;
          top: 65px;
          left: 0;
          background: #fff;
          border-top: 1px solid var(--color-beige);
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          padding: 8px 0px 16px;
          opacity: 1; /* transition: opacity 1s ease-in-out; */

          a {
            margin: 0;
            font-family: var(--font-poppins);
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: var(--color-dark-blue);

            ::before {
              display: none !important;
            }
          }
        }
      }
    }

    .search-page {
      .menu {
        display: flex;
      }
    }

    .showed {
      width: calc(100% - 62px);
    }
  }
`;
const HeaderContainerExpanded = styled.div<{ hero?: boolean }>`
  box-shadow: ${({ hero }) => (hero ? 'unset' : '0 1px 0 0 var(--color-beige)')};
  background: transparent;

  nav {
  }
`;
const NavRoot = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1272px;

  .menu {
    @media ${deviceMinWidth.phone} {
      flex-direction: column;
      display: none;
      position: absolute;
      right: 10px;
      width: 182px;
      top: 92px;
      z-index: 12;
      padding: 24px 16px;
      border-radius: 8px;
      box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
      background-color: var(--color-white);
      a {
        margin: 0;
        font-family: var(--font-poppins);
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #1f2244;

        &:not(:last-child) {
          margin-bottom: 36px;
        }

        /* &:last-of-type { margin-bottom: 0; } */

        :before {
          display: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      > div {
        > a {
          margin-bottom: 0;
        }
      }
    }
    @media screen and (max-width: 1300px) {
      right: 16px;
    }
  }

  &.search-page {
    @media ${deviceMinWidth.phone} {
      padding: 0 16px;
    }
    position: relative;
    justify-content: flex-start;

    .bar1,
    .bar2,
    .bar3 {
      background-color: #1f2244;
    }

    .search-m-trigger {
      display: none;
      height: 80px;
      width: 84px;
      border-left: 1px solid var(--color-beige);
      border-right: 1px solid var(--color-beige);

      @media screen and (min-width: 860px) {
        display: flex;
        position: absolute; /* right: 28px; */
        /* top: 28px; */
      }
      @media screen and (max-width: 1100px) {
        right: 16px;
      }
    }

    .search-menu-wrap {
      position: relative;
      width: 100%;
      max-width: 100%;

      > div:first-of-type {
        padding-right: 38px;
      }

      @media screen and (max-width: 1165px) {
        max-width: 905px;
      }
      @media screen and (max-width: 1100px) {
        max-width: 700px;
        border: medium none;
      }
    }
  }

  @media ${deviceMaxWidth.phone} {
    padding: 16px;
  }
`;
const NavRootExpanded = styled.nav<{ fullWidth?: boolean; hasSmallerWidth?: boolean; hero?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  max-width: ${({ hero, hasSmallerWidth }) => (hero || hasSmallerWidth ? '1072px' : '1272px')};
  position: relative;
  padding: ${({ hero, hasSmallerWidth }) => (hero && !hasSmallerWidth ? '30px 16px' : '16px')};
  ${({ fullWidth }) => fullWidth && 'max-width: 100%; padding: 20px 32px;'}
}

@media ${deviceMaxWidth.phone} {
  padding: 16px;
}

@media screen and (max-width: 500px) {
  flex-direction: column;
  align-items: start;
}

`;
const MenuItems = styled.div`
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  position: relative;
  @media ${deviceMaxWidth.phone} {
    display: none;
    opacity: 0;
  }
`;

const PageOverlay = styled.div<{ active: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #000000;
  z-index: 1000;
  visibility: hidden;
  overflow: hidden;
  overscroll-behavior: contain;
  @media ${deviceMinWidth.phone} {
    display: none !important;
  }
  ${({ active }) => active && ' visibility: visible; '}
`;

const LastItem = styled.div``;

const Divider = styled.div`
  height: 74px;
  width: 1px;
  background: #edeae3;
  margin-top: -26px;
  margin-bottom: -26px;
  margin: -26px 32px;

  &.hero {
    width: 2px;
    height: 32px;
    border-radius: 8px;
    background-color: var(--color-dark-grey);
    margin: -6px 32px;
  }
`;

const CaravanManager = styled.div`
  font-family: var(--font-baloo);
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--color-green);
  margin-right: auto;
  position: relative;
  top: 3px;
`;

const ManagerDivider = styled.div`
  height: 40px;
  margin: 0px 24px;
  background-color: var(--color-grey);
  width: 1px;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const ImgWrapper = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const OldMenuItemsWrapper = styled.div`
  display: flex;

  &.menuAbTestHidden {
    display: none;
  }
`;

const mapStateToProps = (state) => ({
  searchInfo: searchInfoSelector(state),
  user: userSelector(state),
  screen: screenSelector(state),
  settings: state.settings,
  inbox: state.inbox,
});

const connector = connect(mapStateToProps, (dispatch: any) => ({ dispatch }));
export default connector(Header);
