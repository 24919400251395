/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { searchInfoSelector } from '~/src/redux/selectors/search';
import { userSelector } from '~/src/redux/selectors/user';
import { Menu } from '../utils';
import DotContainerComponent from '../DotContainerComponent';
import { screenSelector } from '~/src/redux/selectors/screen';
import ImageAvatarLight from '../icons-and-images/ImageAvatarLight';
import ImageAvatar from '../icons-and-images/ImageAvatar';
import { UserReduxType } from '~/src/redux/reducers/user';
import { openDesktopOrPhoneModal } from '../../../modal/utils';
import { ModalType } from '../../../modal/modalTypes';
import { getLayoutFillNextImage } from '~/src/utils/images';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { mediaMdMax, mediaSmMax } from 'ui/utils/breakpoints';

interface Props {
  showMenuExpanded: Menu;
  isWhiteVersion?: boolean;
  isSticky?: boolean;
  isSearchPage?: boolean;
  toggleMenu: (menu: Menu) => void;
  user: UserReduxType;
  screen: any;
  dispatch: any;
  messagesCount: number;
  messagingEnabled: boolean;
  className?: string;
}

const loginArrowIcon = (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.14643 0.520456C0.341683 0.325185 0.658266 0.32517 0.853537 0.520423L8 7.66622L15.1465 0.520423C15.3417 0.32517 15.6583 0.325185 15.8536 0.520456C16.0488 0.715727 16.0488 1.03231 15.8535 1.22756L8.70714 8.37329C8.70705 8.37338 8.70696 8.37347 8.70687 8.37356C8.61411 8.46638 8.50398 8.54003 8.38276 8.59029C8.26142 8.6406 8.13135 8.6665 8 8.6665C7.86865 8.6665 7.73858 8.6406 7.61724 8.59029C7.49602 8.54003 7.38589 8.46638 7.29313 8.37356C7.29304 8.37347 7.29295 8.37338 7.29286 8.37329L0.146463 1.22756C-0.0488081 1.03231 -0.0488229 0.715727 0.14643 0.520456Z"
      fill="#1F2244"
    />
  </svg>
);

const MobileMenuComponent = ({
  showMenuExpanded,
  isWhiteVersion,
  toggleMenu,
  isSticky,
  isSearchPage,
  screen,
  dispatch,
  user,
  messagesCount,
  messagingEnabled,
  className = '',
}: Props) => (
  <MobileMenu className={className}>
    <MobileMenuTrigger
      id="mobileMenuTrigger"
      className={showMenuExpanded === Menu.MENU ? 'expanded' : ''}
      onClick={() => toggleMenu(Menu.MENU)}
    >
      <DotContainerComponent isBlack={!!(isWhiteVersion || isSearchPage || showMenuExpanded)} />
    </MobileMenuTrigger>
    <ProfileContainer
      onClick={() => {
        if (user.isAuthenticated) {
          toggleMenu(Menu.USER);
        } else {
          openDesktopOrPhoneModal(ModalType.LOGIN, screen, dispatch);
        }
      }}
    >
      <UserIcon
        showMessagesCount={!!(messagesCount && messagingEnabled)}
        className={`
          ${user.customerData?.profilePicture ? 'hasPic' : ''}
          ${isWhiteVersion || showMenuExpanded === Menu.USER ? 'light' : ''}
          userClickableDiv
        `}
      >
        {user.customerData?.profilePicture && user?.isAuthenticated ? (
          <UserProfilePictureWrapper>
            {getLayoutFillNextImage(user.customerData.profilePicture, 'profilePic', 'profilePic', '32px')}
          </UserProfilePictureWrapper>
        ) : (
          <ImgWrapper>{isWhiteVersion ? <ImageAvatar /> : <ImageAvatarLight />}</ImgWrapper>
        )}

        <LoginArrow className={showMenuExpanded === Menu.USER ? 'up' : ''}>{loginArrowIcon}</LoginArrow>
      </UserIcon>
    </ProfileContainer>
  </MobileMenu>
);

const UserIcon = styled.div<{ showMessagesCount: boolean }>`
  display: flex;
  border-radius: 16px;
  width: 64px;
  height: 32px;
  align-items: center;
  cursor: pointer;
  background: #1f2244;
  justify-content: center;
  position: relative;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: #ff5e55;
    border: 2px solid #1f2244;
    position: absolute;
    right: -2px;
    top: -2px;
    display: ${(p) => (p.showMessagesCount ? 'block' : 'none')};
  }

  &.light {
    background: #edeae3;
    &::after {
      border: 2px solid #edeae3;
    }
  }

  &.hasPic {
    justify-content: inherit;
  }
`;

const ImgWrapper = styled.div`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;
const LoginArrow = styled.div`
  margin-left: 8px;
  transform: all linear 0.3;

  svg path {
    fill: white;
  }

  &.up {
    transform: rotate(180deg);
  }

  .isCAMPIRI & {
    ${UserIcon}.light & {
      svg path {
        fill: #1f2244;
      }
    }
  }
`;

const UserProfilePictureWrapper = styled.div`
  position: relative;
  border-radius: 16px;
  width: 32px;
  height: 32px;
  img {
    border-radius: 16px;
  }
`;

const ProfileContainer = styled.div`
  margin: auto;
  margin-left: 8px;
  cursor: pointer;
`;

const MobileMenu = styled.div`
  position: absolute;
  width: 112px;
  height: 64px;
  z-index: 8;
  right: 0;
  top: 0;
  display: none;

  .isFWC & {
    ${mediaMdMax} {
      display: flex;
    }
  }

  ${mediaSmMax} {
    display: flex;
  }
`;
const MobileMenuTrigger = styled.div`
  right: 0;
  margin: auto;
  top: 0;
  cursor: pointer;
  ${mediaSmMax} {
    right: unset;
    top: unset;
  }
  .bar1,
  .bar2,
  .bar3 {
    width: 24px;
    height: 1px;
    background-color: #fff;
    margin: 6px 0;
    transition: 0.4s;
    &.black {
      background-color: #000;
    }
  }
  &.expanded {
    .bar1,
    .bar3 {
      background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceBg, '#000')};
      &.black {
        background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceBg, '#000')};
      }
    }
    .bar1 {
      width: 25px;
      transform: rotate(-45deg) translate(-7px, 1px);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      width: 25px;
      transform: rotate(45deg) translate(-8px, -3px);
    }
  }
`;
const mapStateToProps = (state) => ({
  searchInfo: searchInfoSelector(state),
  user: userSelector(state),
  screen: screenSelector(state),
});
export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(MobileMenuComponent);
