/* eslint-disable @typescript-eslint/ban-ts-comment */
import styled, { css } from 'styled-components';
import { mediaMdMin } from 'ui/utils/breakpoints';
import { Typography } from 'ui/primitives/typography';
import { resolveThemeValue } from 'ui/design/utils';

import tokens from 'ui/design/tokens/tokens';

export type BodySize = keyof (typeof tokens)['font']['body']['desktop' | 'mobile'];
type HeadlinesSize = keyof (typeof tokens)['font']['headlines']['desktop' | 'mobile'];

export const staticBodyTextStyles = (size: BodySize) => css`
  ${resolveThemeValue(tokens.font.body.mobile[size])}

  ${mediaMdMin} {
    ${resolveThemeValue(tokens.font.body.desktop[size])}
  }
`;

export const staticHeadlineTextStyles = (size: HeadlinesSize) => css`
  ${resolveThemeValue(tokens.font.headlines.mobile[size])}

  ${mediaMdMin} {
    ${resolveThemeValue(tokens.font.headlines.desktop[size])}
  }
`;

export const bodyCss = css<{
  $size: BodySize;
}>`
  ${({ $size }) =>
    css(
      // @ts-ignore
      resolveThemeValue(tokens.font.body.mobile[$size])
    )}
  ${mediaMdMin} {
    ${({ $size }) =>
      css(
        // @ts-ignore
        resolveThemeValue(tokens.font.body.desktop[$size])
      )}
  }
`;

export const Body = styled(Typography).attrs(({ $size }: { $size: BodySize }) => ({ size: $size }))<{
  $size: BodySize;
}>`
  ${bodyCss};
`;

export const headlineCss = css<{
  $size: HeadlinesSize;
}>`
  ${({ $size }) =>
    css(
      // @ts-ignore
      resolveThemeValue(tokens.font.headlines.mobile[$size])
    )}
  ${mediaMdMin} {
    ${({ $size }) =>
      css(
        // @ts-ignore
        resolveThemeValue(tokens.font.headlines.desktop[$size])
      )}
  }
`;

export const Headline = styled(Typography).attrs(({ $size }: { $size: HeadlinesSize }) => ({
  size: $size,
  as: $size,
}))<{
  $size: HeadlinesSize;
}>`
  ${headlineCss}
`;
