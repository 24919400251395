import { Trans } from '@lingui/react';
import styled from 'styled-components';
import { Country, routes } from '~/src/utils/routing';
import LocalizedLink from '~/src/components/LocalizedLink';
import { PlaceType, SearchLocationType } from 'ui/components/searchBar/utils/utils';

export type { PlaceType };
export { SearchLocationType };

const LocalizedLinkWhiteUnderscore = styled.span`
  a {
    color: var(--color-white);
    text-decoration: underline !important;
  }
`;

export const popularPlacesPrague = {
  name: 'prague, prague, czech republic',
  title: <Trans id="popularPlaces.prague" message="Prague" />,
  type: SearchLocationType.CampiriBase,
  popupText: (
    <Trans
      id="popularPlaces.prague.popup"
      components={{
        0: <LocalizedLink href={routes.search} query={{ latitude: 50.087465, longitude: 14.421253 }} />,
        1: <LocalizedLinkWhiteUnderscore />,
      }}
      message="Prague branch provides own cars, Campiri services, and Campiri parking. <1><0>See the offer.</0></1>"
    />
  ),
  description: <Trans id="popularPlaces.prague.description" message="Prague, Czech Republic" />,
};

export const popularPlacesMalaga = {
  name: 'málaga, málaga, spain',
  title: <Trans id="popularPlaces.malaga" message="Malaga" />,
  type: SearchLocationType.CampiriBase,
  popupText: (
    <Trans
      id="popularPlaces.malaga.popup"
      components={{
        0: <LocalizedLink href={routes.search} query={{ latitude: 36.720476, longitude: -4.412525 }} />,
        1: <LocalizedLinkWhiteUnderscore />,
      }}
      message="Newly opened branch in Malaga, Spain. Even here, you can rent our caravans. <1><0>See the offer.</0></1>"
    />
  ),
  description: <Trans id="popularPlaces.malaga.description" message="Malaga, Spain" />,
};

export const popularPlaces: PlaceType = {
  [Country.cz]: [
    popularPlacesPrague,
    popularPlacesMalaga,
    {
      name: 'warsaw, masovian voivodeship, poland',
      title: <Trans id="popularPlaces.warsaw" message="Warsaw" />,
      type: SearchLocationType.City,
      description: <Trans id="popularPlaces.warsaw.description" message="Warsaw, Poland" />,
    },
  ],
  [Country.sk]: [
    popularPlacesPrague,
    popularPlacesMalaga,
    {
      name: 'brno, south moravian, czech republic',
      title: <Trans id="popularPlaces.brno" message="Brno" />,
      type: SearchLocationType.City,
      description: <Trans id="popularPlaces.brno.description" message="South Moravian, Czech Republic" />,
    },
  ],
  [Country.pl]: [
    popularPlacesPrague,
    popularPlacesMalaga,
    {
      name: 'krąków, sieradz county, łódź voivodeship, poland',
      title: <Trans id="popularPlaces.krakow" message="Krąków" />,
      type: SearchLocationType.City,
      description: <Trans id="popularPlaces.krakow.description" message="Krąków, Poland" />,
    },
  ],
  [Country.es]: [
    popularPlacesPrague,
    popularPlacesMalaga,
    {
      name: 'barcelona, barcelona, spain',
      title: <Trans id="popularPlaces.barcelona" message="Barcelona" />,
      type: SearchLocationType.City,
      description: <Trans id="popularPlaces.barcelona.description" message="Barcelona, Spain" />,
    },
  ],
  [Country.de]: [],
  [Country.it]: [],
};

export const popularSearches: PlaceType = {
  [Country.cz]: [
    {
      name: 'brno, south moravian, czech republic',
      title: <Trans id="popularSearches.brno" message="Brno" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.brno.description" message="South Moravian, Czech Republic" />,
    },
    {
      name: 'kladno, central bohemian, czech republic',
      title: <Trans id="popularSearches.kladno" message="Kladno" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.kladno.description" message="Central Bohemian, Czech Republic" />,
    },
    {
      name: 'south moravian, czech republic',
      title: <Trans id="popularSearches.southMoravian" message="South Moravian" />,
      type: SearchLocationType.Place,
      description: <Trans id="popularSearches.southMoravian.description" message="South Moravian, Czech Republic" />,
    },
    {
      name: 'vysočina, czech republic',
      title: <Trans id="popularSearches.vysochina" message="Vysočina" />,
      type: SearchLocationType.Place,
      description: <Trans id="popularSearches.vysochina.description" message="Vysočina, Czech Republic" />,
    },
  ],
  [Country.sk]: [
    {
      name: 'bratislava, bratislava, slovakia',
      title: <Trans id="popularSearches.bratislava" message="Bratislava" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.bratislava.description" message="Bratislava, Slovakia" />,
    },
    {
      name: 'liptovský mikuláš, žilina, slovakia',
      title: <Trans id="popularSearches.liptovskyMikulash" message="Liptovský Mikuláš" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.liptovskyMikulash.description" message="Žilina, Slovakia" />,
    },
    {
      name: 'bratislava, bratislava, slovakia',
      title: <Trans id="popularSearches.bratislavaRegion" message="Bratislava region" />,
      type: SearchLocationType.Place,
      description: <Trans id="popularSearches.bratislavaRegion.description" message="Bratislava, Slovakia" />,
    },
    {
      name: 'trenčín, trenčín, slovakia',
      title: <Trans id="popularSearches.trenchin" message="Trenčín" />,
      type: SearchLocationType.Place,
      description: <Trans id="popularSearches.trenchin.description" message="Trenčín, Slovakia" />,
    },
  ],
  [Country.pl]: [
    {
      name: 'warsaw, masovian voivodeship, poland',
      title: <Trans id="popularSearches.warsaw" message="Warsaw" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.warsaw.description" message="Warsaw, Poland" />,
    },
    {
      name: 'wrocław, lower silesian voivodeship, poland',
      title: <Trans id="popularSearches.wroclaw" message="Wrocław" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.wroclaw.description" message="Wrocław, Poland" />,
    },
    {
      name: 'masovian voivodeship, poland',
      title: <Trans id="popularSearches.masovianVoivodeship" message="Województwo Mazowieckie" />,
      type: SearchLocationType.Place,
      description: (
        <Trans id="popularSearches.masovianVoivodeship.description" message="Masovian Voivodeship, Poland" />
      ),
    },
    {
      name: 'lower silesian voivodeship, poland',
      title: <Trans id="popularSearches.wowejewodztdoDolnoslanskie" message="Województwo Dolnośląskie" />,
      type: SearchLocationType.Place,
      description: (
        <Trans
          id="popularSearches.wowejewodztdoDolnoslanskie.description"
          message="Lower Silesian Voivodeship, Poland"
        />
      ),
    },
  ],
  [Country.es]: [
    {
      name: 'málaga, málaga, spain',
      title: <Trans id="popularSearches.malaga" message="Malaga" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.malaga.description" message="Malaga, Spain" />,
    },
    {
      name: 'madrid, madrid, spain',
      title: <Trans id="popularSearches.madrid" message="Madrid" />,
      type: SearchLocationType.City,
      description: <Trans id="popularSearches.madrid.description" message="Madrid, Spain" />,
    },
    {
      name: 'andalucía, 04271 sorbas, almería, spain',
      title: <Trans id="popularSearches.andalucia" message="Andalucía" />,
      type: SearchLocationType.Place,
      description: <Trans id="popularSearches.andalucia.description" message="Andalucía, Spain" />,
    },
    {
      name: 'carrer catalunya, 07011 palma, balearic islands, spain',
      title: <Trans id="popularSearches.lowerSilesian" message="Cataluña" />,
      type: SearchLocationType.Place,
      description: <Trans id="popularSearches.lowerSilesian.description" message="Cataluña, Spain" />,
    },
  ],
  [Country.de]: [],
  [Country.it]: [],
};
