import { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { motion, Variants } from 'framer-motion';
import { ArrowDownIcon } from '../../icons-and-images/ArrowDownIcon';
import { DesktopItemMenuLayout } from './DesktopItemMenuLayout';
import { OwnersMenu } from './navigationItems/ownersMenu/OwnersMenu';
import { SupportMenu } from './navigationItems/supportMenu/SupportMenu';
import { VehicleRentalMenuDesktop } from './navigationItems/vehicleRentalMenu/VehicleRentalMenuDesktop';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { navUnderlineHighlightCss } from '~/src/components/layout/headers/styles';

const IconAnimationVariants: Variants = {
  animate: (isOpen: boolean) => ({
    rotate: isOpen ? 180 : 0,
    transition: {
      type: 'tween',
      duration: 0.3,
      ease: 'easeInOut',
    },
  }),
};

interface DesktopListItemProps {
  localisedText: string | ReactElement;
  handleOpenMenu: () => void;
  shouldAnimateIn: boolean;
  menuType: 'vehicleRental' | 'owners' | 'support';
  itemOrder: 1 | 2 | 3;
  openMenuItem: 'vehicleRental' | 'owners' | 'support' | null;
  isOpen: boolean;
  areLinksInDarkColor: boolean;
}

export const DesktopListItem: FC<DesktopListItemProps> = ({
  isOpen,
  localisedText,
  handleOpenMenu,
  shouldAnimateIn,
  menuType,
  itemOrder,
  openMenuItem,
  areLinksInDarkColor,
}) => (
  <ItemContainer data-testid={`btn-${menuType}`}>
    <MenuButton
      data-linkdark={areLinksInDarkColor}
      areLinksInDarkColor={areLinksInDarkColor}
      onClick={handleOpenMenu}
      isActive={isOpen}
    >
      {localisedText}
      <IconContainer variants={IconAnimationVariants} animate="animate" custom={isOpen}>
        <ArrowDownIcon />
      </IconContainer>
    </MenuButton>
    <DesktopItemMenuLayout
      isOpen={isOpen}
      openMenuItem={openMenuItem}
      shouldAnimateIn={shouldAnimateIn}
      itemOrder={itemOrder}
    >
      <DesktopMenuType menuType={menuType} />
    </DesktopItemMenuLayout>
  </ItemContainer>
);

const DesktopMenuType: FC<{ menuType: 'vehicleRental' | 'owners' | 'support' }> = ({ menuType }) => {
  switch (menuType) {
    case 'owners':
      return <OwnersMenu />;
    case 'support':
      return <SupportMenu />;
    case 'vehicleRental':
      return <VehicleRentalMenuDesktop />;
    default:
      return null;
  }
};

export const ItemContainer = styled.div`
  &:not(:first-of-type) {
    margin-left: 28px;
  }

  position: relative;
`;

export const MenuButton = styled.button<{ areLinksInDarkColor: boolean; isActive?: boolean }>`
  display: flex;
  position: relative;
  color: #edeae3;
  font-size: 16px;

  .isFWC & {
    border: none;
    background: transparent;
    cursor: pointer;
  }

  :hover {
    color: white;
  }

  .isFWC & {
    ${navUnderlineHighlightCss};
  }

  &[data-linkdark='true'] {
    color: #9c8c8c;

    :hover {
      color: #1f2244;
    }

    .isFWC & {
      color: ${resolveThemeValue(tokens.color.text.textPrimary)};
    }
  }

  ${({ isActive, areLinksInDarkColor }) =>
    isActive &&
    areLinksInDarkColor &&
    css`
      color: #1f2244;
    `}

  ${({ isActive, areLinksInDarkColor }) =>
    isActive &&
    !areLinksInDarkColor &&
    css`
      color: white;
    `}
`;

const IconContainer = styled(motion.div)`
  margin-left: 8px;
`;
