/* eslint-disable @typescript-eslint/indent */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useIsomorphicLayoutEffect, useMedia } from 'react-use';

import { useScrollBlock } from '~/src/hooks/useScrollBlock';
import { selectIsUserAuthenticated } from '~/src/redux/selectors/user';
import SearchHeader from '~/src/components/layout/headers/SearchHeader';
import { getPageType, Menu, PageType } from './headers/utils';
import BasicHeader from './headers/BasicHeader';

import { getUnreadMessages } from '~/src/api/inbox';
import { selectInboxMessageCount, selectInboxMessagingEnabled } from '~/src/redux/selectors/inbox';
import { setInboxMessagesCount } from '~/src/redux/actions/inbox';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { useTypedSelector } from '~/src/redux/store';
import { useStickyHeaderPosition } from '~/src/components/layout/useStickyHeaderPosition';
import FreewayHeader from '~/src/freeway/components/layout/Header';
import { useCodebase } from 'features/hooks/useCodebase';

interface Props {
  scrollPosition: any;
  fullWidth?: boolean;
  forceWhiteVersion?: boolean;
}

const pagesWithWhiteVersion = (isFWC: boolean) =>
  [
    !isFWC && 'isCarPage',
    'isErrorPage',
    'isCookiePage',
    !isFWC && 'isBlogPage',
    'isPasswordRecoveryPage',
    'isKYCPage',
    'isCICOPage',
    'isOperatorPage',
    'isRenterPage',
    'isSettingsPage',
    'isFirsTimeCapervanLandingPage',
    !isFWC && 'isBookingPage',
    'isManagerBookingPage',
    'isLWLanding',
    'isOperatorBookingPage',
    'isMessagePage',
    'isLWEntry',
    'isCampiriSpainLP',
    'isAccountCompletion',
    !isFWC && 'isBookingPageUnauthenticated',
  ].filter(Boolean) as Array<PageType>;

const pagesWithoutFullMenu: Array<PageType> = ['isKYCPage', 'isCICOPage', 'isPasswordRecoveryPage'];
const messagingDisabledPages: Array<PageType> = ['isLWEntry', 'isCampiriSpainLP'];
const pagesWithSmallerWidth: Array<PageType> = [
  'isKYCPage',
  'isCICOPage',
  'isOperatorPage',
  'isRenterPage',
  'isSettingsPage',
  'isFirsTimeCapervanLandingPage',
  'isBookingPage',
  'isManagerBookingPage',
  'isLWLanding',
  'isOperatorBookingPage',
  'isMessagePage',
];
const bookingPageCategory: Array<PageType> = [
  'isManagerBookingPage',
  'isBookingPage',
  'isOperatorBookingPage',
  'isMessagePage',
];

export function Header({ scrollPosition, fullWidth = false, forceWhiteVersion = false }: Props) {
  const router = useRouter();

  // note: use here memo and callbacks due to scroll position changes really often and every scroll on most of the pages hurts react performance, and state changes often as well
  const dispatch = useDispatch();
  const inboxMessagesCount = useTypedSelector(selectInboxMessageCount);
  const isInboxMessagingEnabled = useTypedSelector(selectInboxMessagingEnabled);
  const isUserAuthenticated = useTypedSelector(selectIsUserAuthenticated);
  const pageType = useMemo(
    () => getPageType(router.pathname, isUserAuthenticated),
    [router.pathname, isUserAuthenticated]
  );
  const isFWC = useCodebase('fwc');

  const isWhiteVersion = useMemo(
    () => forceWhiteVersion || pagesWithWhiteVersion(isFWC).includes(pageType),
    [pageType, forceWhiteVersion, isFWC]
  );
  const isFullMenu = useMemo(() => !pagesWithoutFullMenu.includes(pageType), [pageType]);

  const isMessagingEnabled = useMemo(
    () => isInboxMessagingEnabled && !messagingDisabledPages.includes(pageType),
    [isInboxMessagingEnabled, pageType]
  );
  const messagesCount = useMemo(
    () => (!messagingDisabledPages.includes(pageType) ? inboxMessagesCount : undefined),
    [inboxMessagesCount, pageType]
  );
  const isSmallerWidth = useMemo(() => pagesWithSmallerWidth.includes(pageType), [pageType]);
  const isInBookingCategory = useMemo(() => bookingPageCategory.includes(pageType), [pageType]);

  const [showMenuExpanded, setMenuExpanded] = useState<Menu | null>(null);

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    const routeChange = () => {
      allowScroll(true, false);
      setMenuExpanded(null);
    };

    router.events.on('routeChangeComplete', routeChange);

    return () => {
      router.events.off('routeChangeComplete', routeChange);
    };
  }, []);

  useEffect(() => {
    if (!showMenuExpanded) {
      allowScroll(true, false);
    }
  }, [showMenuExpanded]);

  // this fixes bug when mobile and desktop navigation should swap
  const mobileMediaQuery = useMedia(deviceMaxWidth.phone, false);
  useIsomorphicLayoutEffect(() => {
    if (!mobileMediaQuery) {
      setMenuExpanded(null);
    }
  }, [mobileMediaQuery]);

  useEffect(() => {
    if (!isUserAuthenticated) return;

    if (isFWC) return;

    getUserUnreadMessages();

    const interval = setInterval(() => getUserUnreadMessages(), 20990);

    return () => clearInterval(interval);
  }, [isUserAuthenticated]);

  const getUserUnreadMessages = useCallback(async () => {
    try {
      const response = await getUnreadMessages();
      if (inboxMessagesCount !== response.data.count) {
        dispatch(setInboxMessagesCount(response.data.count));
      }
    } catch (error) {
      console.log(error);
    }
  }, [inboxMessagesCount]);

  const toggleMenu = useCallback((value: Menu | null) => {
    if (value === null) {
      setMenuExpanded(null);
      allowScroll();
    }

    setMenuExpanded((prev) => {
      if (value === prev) {
        allowScroll();
        return null;
      }
      blockScroll();

      return value;
    });
  }, []);

  // only on HP
  const isSticky = useStickyHeaderPosition(scrollPosition);

  if (isFWC)
    return (
      <FreewayHeader
        isOperatorBookingPage={pageType === 'isOperatorBookingPage'}
        isSettingsPage={pageType === 'isSettingsPage'}
        isOperatorPage={pageType === 'isOperatorPage'}
        isBookingPage={isInBookingCategory}
        showFullMenu={isFullMenu}
        isRenterPage={pageType === 'isRenterPage'}
        isWhiteVersion={isWhiteVersion}
      />
    );

  return pageType === 'isSearchPage' ? (
    <SearchHeader
      toggleMenu={toggleMenu}
      showMenuExpanded={showMenuExpanded}
      messagesCount={inboxMessagesCount}
      messagingEnabled={isInboxMessagingEnabled}
    />
  ) : (
    <BasicHeader
      toggleMenu={toggleMenu}
      showMenuExpanded={showMenuExpanded}
      isSticky={isSticky}
      showFullMenu={isFullMenu}
      isWhiteVersion={isWhiteVersion}
      isOperatorBookingPage={pageType === 'isOperatorBookingPage'}
      isManagerBookingPage={pageType === 'isManagerBookingPage'}
      isSettingsPage={pageType === 'isSettingsPage'}
      isOperatorPage={pageType === 'isOperatorPage'}
      isMessagePage={pageType === 'isMessagePage'}
      isRenterPage={pageType === 'isRenterPage'}
      isBookingPage={isInBookingCategory}
      fullWidth={fullWidth}
      messagingEnabled={isMessagingEnabled}
      messagesCount={messagesCount}
      hasSmallerWidth={isSmallerWidth}
    />
  );
}

export default Header;
