import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import { NavItem } from '../../styles';
import { IMenuItems } from '~/src/components/layout/headers/utils';
import LocalizedLink from '~/src/components/LocalizedLink';
import { SettingsProps } from '~/src/redux/reducers/settings';
import { openDesktopOrPhoneModal } from '../../../../modal/utils';
import { ModalType } from '../../../../modal/modalTypes';
import { screenSelector } from '~/src/redux/selectors/screen';
import ImageLanguageIconLight from '../../../ImageLanguageIconLight';
import ImageLanguageIcon from '../../../ImageLanguageIcon';
import { TypedIBrowser } from '~/src/redux/reducers/rootReducer';

interface Props {
  menuItems: IMenuItems[];
  isLightColor?: boolean;
  settings: SettingsProps;
  screen: TypedIBrowser;
  dispatch;
  hideLang?: boolean;
}

const MenuItemsComponent = ({
  menuItems,
  isLightColor = false,
  settings,
  dispatch,
  screen,
  hideLang = true,
}: Props) => {
  const router = useRouter();

  return (
    <>
      {menuItems.map((item, id) => (
        <LocalizedLink
          legacyBehavior={true}
          prefetch={false}
          key={item.route.template}
          href={item.route}
          passHref={true}
        >
          <NavItem
            isActive={router.pathname === item.route.template}
            className={`${isLightColor ? 'isLightColor' : ''}`}
          >
            <Trans id={item.label} />
          </NavItem>
        </LocalizedLink>
      ))}

      {!hideLang && (
        <NavItem
          isActive={false}
          className={`${isLightColor ? 'isLightColor' : ''}`}
          onClick={() => {
            openDesktopOrPhoneModal(ModalType.CHANGE_LANGUAGE, screen, dispatch);
          }}
        >
          <ImageWrapper>{isLightColor ? <ImageLanguageIconLight /> : <ImageLanguageIcon />}</ImageWrapper>

          {settings?.language.toUpperCase()}
        </NavItem>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  settings: state.settings,
  screen: screenSelector(state),
});

const connector = connect(mapStateToProps, (dispatch) => ({ dispatch }));

export default connector(MenuItemsComponent);

const ImageWrapper = styled.div`
  margin-right: 8px;
`;
