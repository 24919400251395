import { FC, useEffect } from 'react';
import { Trans } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MenuDescriptionLink } from '../MenuDescriptionLink';
import { SummerSpainIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/special-offers-icons/SummerSpainIcon';
import { VouchersIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/special-offers-icons/VouchersIcon';
import { ComplementaryHeading, ExperiencesHeading, MobileMenuContentWrapper } from '../menu.styles';

import { ExperienceScroll } from './ExperienceScroll';
import { Language, routes } from '~/src/utils/routing';
import { languageSelector } from '~/src/redux/selectors/settings';
import { FlagImage } from './rentalMenu.styles';
import { SummerPortugalIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/special-offers-icons/SummerPortugalIcon';
import { useCodebase } from 'features/hooks/useCodebase';
import BeachIcon from 'ui/icons/ic_beach.svg';
import TimeIcon from 'ui/icons/ic_time.svg';
import ExtensionCordIcon from 'ui/icons/ic_extension_cord.svg';
import ForestIcon from 'ui/icons/ic_forest.svg';
import AdsIcon from 'ui/icons/ic_ads.svg';
import { trackMainMenu } from '~/src/utils/dataLayer';
import { t } from '@lingui/macro';
import { FlexCol } from 'ui/primitives/flex';
import tokens from 'ui/design/tokens/tokens';

export const VehicleRentalMenuMobile: FC = () => {
  const language = useSelector(languageSelector);
  const isFWC = useCodebase('fwc');

  useEffect(() => {
    if (isFWC) trackMainMenu({ text: t({ id: 'menu.campers' }) });
    else trackMainMenu({ text: t({ id: 'menu.vehicleRental' }) });
  }, []);

  return (
    <>
      <MobileMenuContentWrapper>
        <Spacer>
          <ExperiencesHeading>
            <Trans id="menu.experiences" message="Experiences" />
          </ExperiencesHeading>
        </Spacer>

        <ExperienceScroll />
      </MobileMenuContentWrapper>

      {isFWC && (
        <MobileMenuContentWrapper>
          <Spacer>
            <ComplementaryHeading>
              <Trans id="menu.specialOffers" message="Special Offers" />
            </ComplementaryHeading>

            <FlexCol $gap={tokens.spacing.spacingL}>
              <MenuDescriptionLink
                isDisplayedAsMobileVersion={true}
                links={{
                  [Language.en]: {
                    href: routes.dynamicSegmentNew,
                    linkParams: {
                      slug: 'special-offers',
                      id: 675,
                    },
                  },
                  [Language.de]: {
                    href: routes.dynamicSegmentNew,
                    linkParams: {
                      slug: 'sonderangebote',
                      id: 674,
                    },
                  },
                  [Language.it]: {
                    href: routes.dynamicSegmentNew,
                    linkParams: {
                      slug: 'offerte-speciali',
                      id: 676,
                    },
                  },
                }}
                boldText={<Trans id="menu.fwc.specialoffer.title.option1" message="10% discount on all campers" />}
                leftIcon={<AdsIcon width={10} height={10} />}
                additionalText={
                  <Trans
                    id="menu.fwc.specialoffer.text.option1"
                    message="With the code SUNNY10, you save 10% on all trips in 2024"
                  />
                }
              />
              <MenuDescriptionLink
                links={{
                  [Language.en]: {
                    href: routes.blogDetail,
                    linkParams: {
                      slug: 'long-term-rental',
                      id: 536,
                    },
                  },
                  [Language.de]: {
                    href: routes.blogDetail,
                    linkParams: {
                      slug: 'langzeitmiete',
                      id: 537,
                    },
                  },
                  [Language.it]: {
                    href: routes.blogDetail,
                    linkParams: {
                      slug: 'noleggio-lungo-termine',
                      id: 538,
                    },
                  },
                }}
                boldText={<Trans id="menu.fwc.specialDeals" message="Special deals" />}
                isDisplayedAsMobileVersion={true}
                leftIcon={<BeachIcon height={20} />}
              />
              <MenuDescriptionLink
                isDisplayedAsMobileVersion={true}
                leftIcon={<TimeIcon height={20} />}
                links={{
                  [Language.de]: {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 558,
                      slug: 'fruehbucher',
                    },
                  },
                  [Language.en]: {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 557,
                      slug: 'early-booking',
                    },
                  },
                  [Language.it]: {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 559,
                      slug: 'prenotazioni-anticipate',
                    },
                  },
                }}
                boldText={<Trans id="menu.fwc.photoChallenge" message="Photo challenge!" />}
              />
              <MenuDescriptionLink
                isDisplayedAsMobileVersion={true}
                boldText={<Trans id="menu.fwc.extrasAndServices" message="Extras and services" />}
                links={{
                  'de-de': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 546,
                      slug: 'unsere-zusatzservices',
                    },
                  },
                  'en-de': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 545,
                      slug: 'our-additional-services',
                    },
                  },
                  'en-it': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 919,
                      slug: 'additional-services',
                    },
                  },
                  'it-it': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 547,
                      slug: 'attrezzatura-e-servizi',
                    },
                  },
                }}
                leftIcon={<ExtensionCordIcon height={20} />}
              />
              <MenuDescriptionLink
                isDisplayedAsMobileVersion={true}
                links={{
                  'de-de': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 549,
                      slug: 'klimabewusst-reise',
                    },
                  },
                  'en-de': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 548,
                      slug: 'climate-conscious-travel',
                    },
                  },
                  'en-it': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 920,
                      slug: 'climate-conscious',
                    },
                  },
                  'it-it': {
                    href: routes.blogDetail,
                    linkParams: {
                      id: 550,
                      slug: 'attenti-all-ambiente',
                    },
                  },
                }}
                boldText={<Trans id="menu.fwc.climate" message="Climate conscious travel" />}
                leftIcon={<ForestIcon height={20} />}
              />
            </FlexCol>
          </Spacer>
        </MobileMenuContentWrapper>
      )}

      {language === Language.cs && (
        <MobileMenuContentWrapper>
          <Spacer>
            <ComplementaryHeading>
              <Trans id="menu.specialOffers" message="Special Offers" />
            </ComplementaryHeading>
            <FlexCol $gap={tokens.spacing.spacingL}>
              <MenuDescriptionLink
                isDisplayedAsMobileVersion={true}
                href={routes.superGiveawayJuneLP}
                boldText="Letní Soutěž"
                leftIcon={<AdsIcon height={10} width={10} />}
                additionalText="Vyhrajte letní dovolenou v obytném autě v hodnotě 10 000 Kč!"
              />
              <MenuDescriptionLink
                href={routes.campiriSpain}
                isDisplayedAsMobileVersion={true}
                boldText={
                  <>
                    <Trans id="menu.spain" message="Spain" />
                    <FlagImage src="/static/images/experiences-menu/Spain.svg" alt="spain flag" />
                  </>
                }
                leftIcon={<SummerSpainIcon />}
              />
              <MenuDescriptionLink
                href={routes.campiriPortugal}
                isDisplayedAsMobileVersion={true}
                boldText={
                  <>
                    Portugalsko
                    <FlagImage src="/static/images/experiences-menu/Portugal1.svg" alt="spain flag" />
                  </>
                }
                leftIcon={<SummerPortugalIcon />}
              />
              {/* <MenuDescriptionLink
              href={routes.voucher}
              externalURL="https://www.campiri.com/cs-cz/za-zimnimi-radovankami-karavanem--clanek-330"
              isDisplayedAsMobileVersion={true}
              boldText={<>Zimní dovolená v karavanu</>}
              additionalText="Vyjeďte na hory v obytném voze. Poradíme, jak na to."

              leftIcon={<SnowIcon />}
            /> */}
              <MenuDescriptionLink
                href={routes.vouchers}
                isDisplayedAsMobileVersion={true}
                externalURL="https://shop.campiri.com/"
                boldText="Vouchery"
                leftIcon={<VouchersIcon />}
              />
            </FlexCol>
          </Spacer>
        </MobileMenuContentWrapper>
      )}
      {language === Language.pl && (
        <MobileMenuContentWrapper>
          <Spacer>
            <ComplementaryHeading>
              <Trans id="menu.specialOffers" message="Special Offers" />
            </ComplementaryHeading>
            <FlexCol $gap={tokens.spacing.spacingL}>
              <MenuDescriptionLink
                href={routes.campiriSpain}
                isDisplayedAsMobileVersion={true}
                boldText={
                  <>
                    <Trans id="menu.spain" message="Spain" />
                    <FlagImage src="/static/images/experiences-menu/Spain.svg" alt="spain flag" />
                  </>
                }
                leftIcon={<SummerSpainIcon />}
              />
            </FlexCol>
          </Spacer>
        </MobileMenuContentWrapper>
      )}
      {!isFWC && <Divider />}
    </>
  );
};

const Spacer = styled.div`
  padding-right: 16px;
  padding-left: 16px;
`;

const Divider = styled.div`
  width: calc(100% - 32px);
  height: 1px;
  background-color: #edeae3;
  margin: 0 auto;
`;
