/* eslint-disable no-bitwise */
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/react';
import { deviceMaxWidth, deviceMinWidth } from '~/src/styles/breakpoints';
import LocalizedLink from '~/src/components/LocalizedLink';
import { Country, routes } from '~/src/utils/routing';
import { openDesktopOrPhoneModal } from '../../modal/utils';
import { ModalType } from '../../modal/modalTypes';
import { screenSelector } from '~/src/redux/selectors/screen';
import { getCookie, setCookie } from '~/src/utils/cookies';
import {
  setAnayticCookies,
  setCookieBarVariant,
  setCookieBarVisible,
  setMarketingCookies,
} from '~/src/redux/actions/settings';
import { saveCookieConsentSet, sendCookieBarVisibleRequest } from './utils';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { Box } from 'ui/primitives/box';
import tokens from 'ui/design/tokens/tokens';
import { useCodebase } from 'features/hooks/useCodebase';
import {
  Flex,
  FlexCol,
  FlexColMdMax,
  FlexJustifySpaceBetween,
  FlexJustifySpaceBetweenAlignCenter,
} from 'ui/primitives/flex';
import { Body, Headline } from 'ui/components/primitives/typography/CustomTypography';
import { Typography } from 'ui/primitives/typography';
import PrimaryButton from 'ui/components/buttons/PrimaryButton';
import SecondaryButton from 'ui/components/buttons/SecondaryButton';
import useBreakpoints, { SMALL_SCREEN_QUERY } from 'features/hooks/useBreakpoints';
import { mediaMdMax } from 'ui/utils/breakpoints';
import { resolveThemeValue } from 'ui/design/utils';

export enum CookiesVariant {
  'A' = 'a',
  'B' = 'b',
  'C' = 'c',
}

const vars = [
  CookiesVariant.A, // default - fixed bottom
  CookiesVariant.B, // fixed center
  CookiesVariant.C, // / fixed center without reject btn
];

const getRandomVariant = (country: Country) => {
  if (country === Country.pl) {
    return CookiesVariant.C;
  }
  if (country === Country.cz || country === Country.sk) {
    return CookiesVariant.B;
  }

  return vars[~~(Math.random() * 2)];
};

const Block: FC = () => {
  const settings = useTypedSelector(settingsSelector);
  const dispatch = useDispatch();
  const screen = useTypedSelector(screenSelector);
  const isFWC = useCodebase('fwc');
  const [smallScreen] = useBreakpoints(SMALL_SCREEN_QUERY);

  useEffect(() => {
    const analyticCookies = getCookie('analyticCookies');
    const marketingCookies = getCookie('marketingCookies');
    const randomVariant = getRandomVariant(settings?.country);

    dispatch(setCookieBarVariant(randomVariant));

    dispatch(setMarketingCookies(marketingCookies === 'true'));
    dispatch(setAnayticCookies(analyticCookies === 'true'));

    if (analyticCookies === 'false' || marketingCookies === 'false') {
      dispatch(setCookieBarVisible(false));
    } else if (analyticCookies === 'true' && marketingCookies === 'true') {
      dispatch(setCookieBarVisible(false));
    } else {
      dispatch(setCookieBarVisible(true));
    }
  }, []);

  const onAcceptCookies = () => {
    setCookie('analyticCookies', true, 395);
    setCookie('marketingCookies', true, 395);
    dispatch(setMarketingCookies(true));
    dispatch(setAnayticCookies(true));

    dispatch(setCookieBarVisible(false));

    if (typeof window?.gtag === 'function') {
      window?.gtag('consent', 'update', {
        analytics_storage: 'granted',
        ad_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
      });
    }

    setTimeout(() => {
      window?.dataLayer.push({
        event: 'analyticCookies',
      });
      window?.dataLayer.push({
        event: 'marketingCookies',
      });
    }, 1000);
    saveCookieConsentSet(true, true, 'button', settings.cookieBarVariant, `${settings.language}-${settings.country}`);
  };

  const onCookieSettings = () => {
    dispatch(setCookieBarVisible(false));
    openDesktopOrPhoneModal(ModalType.COOKIES_SETTINGS, screen, dispatch);
    dispatch(setCookieBarVisible(false));
  };

  useEffect(() => {
    if (settings.cookieBarVisible && settings.cookieBarVariant) {
      sendCookieBarVisibleRequest(settings.cookieBarVariant, `${settings.language}-${settings.country}`);
    }
  }, [settings.cookieBarVisible, settings.cookieBarVariant]);

  const getCookiesLink = (country) => {
    if (isFWC) return <LocalizedLink href={routes.privacy} />;

    const links = {
      pl: <LocalizedLink href={routes.privacy} hash="cookies" passHref={true} />,
      sk: <LocalizedLink href={routes.cookies} passHref={true} />,
      cz: <LocalizedLink href={routes.cookies} passHref={true} />,
    };

    return links[country];
  };

  if (!settings.cookieBarVisible) return null;

  if (isFWC)
    return (
      <Box
        $zIndex={1000}
        $width="100%"
        $position="fixed"
        $offset={{
          bottom: '0px',
        }}
        $background={tokens.color.items.separatorDark}
      >
        <FWCWrapper
          $px={tokens.spacing.spacingL}
          $mdMin={{
            $px: '0px',
            $py: tokens.spacing.spacing4Xl,
          }}
          $gap={tokens.spacing.spacing2Xl}
          $py={tokens.spacing.spacing4Xl}
          $maxWidth="1200px"
          $margin="auto"
        >
          <FlexCol $maxWidth="580px" $gap={tokens.spacing.spacingM}>
            <Headline $color={tokens.color.text.textContrastPrimary} $size="h5">
              <Trans id="cookies.fwc.bar.heading" message="Cookie Compass: Navigating Your Tastes and Travels!" />
            </Headline>
            <Body $size="m" $color={tokens.color.text.textContrastSecondary}>
              <Trans
                components={[getCookiesLink(settings.country)]}
                id="cookies.fwc.bar.subheading"
                message="We use cookies to enhance our services. By consenting, you allow us to understand what interests you and where you're heading. This enables us to offer tailor-made experiences. <0>Read more information.</0>"
              />
            </Body>
          </FlexCol>
          <FlexColMdMax
            $mdMax={{
              $width: '100%',
            }}
            $gap={tokens.spacing.spacingL}
          >
            <PrimaryButton onClick={onAcceptCookies} dataTestId="cookies-accept">
              <Trans id="cookies.fwc.buttons.accept" message="Accept" />
            </PrimaryButton>
            <SecondaryButton onClick={onCookieSettings} smallerPadding={smallScreen} dataTestId="cookies-set">
              <Trans id="cookies.fwc.buttons.preferences" message="Setting preferences" />
            </SecondaryButton>
          </FlexColMdMax>
        </FWCWrapper>
      </Box>
    );

  return (
    <>
      <PageOverlay
        active={settings.cookieBarVariant === CookiesVariant.B || settings.cookieBarVariant === CookiesVariant.C}
      />
      <Wrapper
        className={`variant-${
          settings.cookieBarVariant === CookiesVariant.B || settings.cookieBarVariant === CookiesVariant.C ? 'b' : 'a'
        }`}
      >
        <LeftContent>
          <Heading>
            <img alt="logo" src="/static/images/vectors/cookies.svg" />
            <span>
              <Trans id="cookies.bar.heading" message="Cookies v tom jedou s námi, přidáte se?" />
            </span>
          </Heading>
          <Description>
            <Trans
              components={[getCookiesLink(settings.country)]}
              id="cookies.bar.description"
              message="Cookies používáme, abychom vám mohli nabídnout co nejlepší služby na našem webu i mimo něj. Přečtěte si <0> více informací.</0>"
            />
          </Description>
        </LeftContent>
        <RightContent>
          <ButtonsRow>
            <SettingsButton className="hidePhone" onClick={onCookieSettings} data-testid="cookies-set">
              <Trans id="cookies.bar.settingsCTA" message="Nastavit" />
            </SettingsButton>

            <AcceptButton onClick={onAcceptCookies} id="test_cookie_accept" data-testid="cookies-accept">
              <Trans id="cookies.bar.acceptCTA" message="Souhlasím" />
            </AcceptButton>
          </ButtonsRow>
          <PhoneAcceptButtonWrapper>
            <SettingsButton className="hideDesktop" onClick={onCookieSettings} data-testid="cookies-set">
              {settings.cookieBarVariant === CookiesVariant.A ? (
                <Trans id="cookies.bar.settingsCTA" message="Nastavit" />
              ) : (
                <Trans id="cookies.bar.variantB.settingsCTA" message="Nastavit preference" />
              )}
            </SettingsButton>
          </PhoneAcceptButtonWrapper>
        </RightContent>
      </Wrapper>
    </>
  );
};

const FWCWrapper = styled(FlexJustifySpaceBetweenAlignCenter)`
  ${mediaMdMax} {
    flex-flow: column;
    button {
      width: 100% !important;
    }
  }

  a {
    text-decoration: underline;
    color: ${resolveThemeValue(tokens.color.text.textLink)};
  }

  button {
    width: fit-content;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 999999;
  align-items: center;
  display: flex;
  bottom: 12px;
  max-width: 860px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
  background-color: #1f2244;

  &.variant-b {
    z-index: 100001;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    flex-direction: column;
    border-radius: 16px;
    max-width: 600px;
    background: white;
    height: max-content;
    padding-bottom: 32px;
    @media ${deviceMaxWidth.phone} {
      bottom: 0px;
      max-width: unset;
      border-radius: 0px;
    }
  }

  @media ${deviceMaxWidth.phone} {
    bottom: 0px;
    max-width: unset;
    border-radius: 0px;
    flex-direction: column;
  }
`;

const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RightContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 36px;
  width: 100%;

  ${Wrapper}.variant-b & {
    flex-direction: column;
    margin: 16px auto 0;
    max-width: 352px;
  }

  @media ${deviceMaxWidth.phone} {
    flex-direction: column;
    margin-top: 16px;
    margin-left: 0px;
  }
`;

const BasicButton = styled.div`
  cursor: pointer;
  width: auto;
  padding: 10px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: var(--color-white);
  margin-right: 12px;
  transition: all 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 120px;

  ${Wrapper}.variant-b & {
    padding: 16px 29px;
    font-size: 16px;
  }
`;

const AcceptButton = styled(BasicButton)`
  background-color: var(--color-green);
  margin-right: 0px;
  text-align: center;

  &:hover {
    background-color: #1f2244;
  }
`;

const SettingsButton = styled(BasicButton)`
  border: solid 1px rgba(255, 255, 255, 0.2);
  background-color: #1f2244;
  text-align: center;

  &:hover {
    background-color: var(--color-white);
    color: #1f2244;
  }

  @media ${deviceMaxWidth.phone} {
    &.hidePhone {
      display: none;
    }
  }

  @media ${deviceMinWidth.phone} {
    &.hideDesktop {
      display: none;
    }
  }

  &.hidePhone {
    ${Wrapper}.variant-b & {
      display: none;
    }
  }

  ${Wrapper}.variant-b & {
    display: block;
    margin-top: 16px;
    border: solid 1px var(--color-beige);
    background-color: var(--color-white);
    color: var(--color-dark-grey);
  }
`;

const Heading = styled.div`
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-beige);
  margin-bottom: 16px;

  span {
    margin-left: 8px;
    font-weight: 700;
  }

  ${Wrapper}.variant-b & {
    font-family: var(--font-poppins);
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    border-bottom: 1px solid var(--color-beige);
    color: #1f2244;
    padding: 0 24px 21px;
    margin: 0 -24px;

    img {
      display: none;
    }
  }
`;

const Description = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  color: var(--color-beige);

  > a {
    text-decoration: underline;
    color: inherit;
  }

  ${Wrapper}.variant-b & {
    font-family: var(--font-poppins);
    font-size: 16px;
    max-width: 368px;
    margin: 24px auto 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #1f2244;
  }
`;

const PageOverlay = styled.div<{ active?: boolean }>`
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  z-index: 100000;
  visibility: hidden;
  overflow: hidden;
  overscroll-behavior: contain;
  // @media ${deviceMinWidth.phone} {
  //   display: none !important;
  // }
  ${({ active }) =>
    active &&
    `
      visibility: visible;
  `}
`;

const PhoneAcceptButtonWrapper = styled.div`
  width: 100%;

  @media ${deviceMaxWidth.phone} {
    margin-top: 16px;
    ${Wrapper}.variant-b, & {
      margin-top: 0;
    }
  }
`;

const ButtonsRow = styled.div`
  display: flex;
`;

export default Block;
