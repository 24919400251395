import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { PrimaryButton } from '~/src/styles/buttons';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { searchInfoSelector } from '~/src/redux/selectors/search';
import PlaceAutocomplete from './PlaceAutocomplete';
import DateRangeCalendar from './DateRangeCalendar';
import { goToSearchPageWithQuery } from '~/src/utils/query';
import { trackSearchDataLayer, tryCallDataLayerEvent } from '~/src/utils/dataLayer';
import { useTypedSelector } from '~/src/redux/store';

const SearchBar = () => {
  const [placeError, setPlaceError] = useState(false);
  const searchInfo = useTypedSelector(searchInfoSelector);

  const makeSearch = () => {
    // if (!searchInfo.place) {
    //   setPlaceError(true);
    //   return;
    // }

    const hasSearchInfo = searchInfo.place ? 'location' : 'null';
    const hasSelectedDays = searchInfo.selectedDays ? 'date' : 'null';

    goToSearchPageWithQuery(searchInfo);
    // tryCallDataLayerEvent(() => { trackSearchDataLayer(searchInfo.selectedDays, searchInfo.place); });
  };
  return (
    <>
      <Wrapper>
        <PlaceAutocomplete placeError={placeError} setPlaceError={setPlaceError} />
        <DateRangeCalendar monthsBreakPoint={1400} />
        {/* <PassengersInput /> */}
        <SearchCTAWrapper>
          <PrimaryButton onClick={makeSearch} dataTestId="search-campervan-cta">
            <Trans id="button.searchCaravan" message="Vyhledat karavan" />
          </PrimaryButton>
        </SearchCTAWrapper>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: calc(100% - 170px);
  width: 100%;

  height: auto;
  margin-left: 90px;
  background-color: var(--color-white);

  @media ${deviceMaxWidth.phone} {
    display: none;
  }

  @media screen and (max-width: 1165px) {
    margin-left: 20px;
  }

  @media screen and (max-width: 1100px) {
    max-width: 604px;
  }
`;

const SearchCTAWrapper = styled.div`
  button {
    min-width: 206px;
    white-space: nowrap;
    @media screen and (max-width: 1165px) {
      min-width: initial;
    }
  }
`;

export default SearchBar;
