/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

/* eslint-disable @typescript-eslint/indent */
import { Trans } from '@lingui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import styled from 'styled-components';
import { IConversationMessage } from './InboxPage';
import { SettingsProps } from '~/src/redux/reducers/settings';
import { PrimaryButton } from '~/src/styles/buttons';
import { getFormattedPriceWithForcedCurrency } from '~/src/utils/price';
import { goToBookingPage, goToOperatorBookingPage } from '~/src/utils/query';
import { ConversationBookingResult } from '~/libs/api/models/conversation-booking-result';
import { ConversationResult } from '~/libs/api/models/conversation-result';
import { NextBookingAction } from '~/libs/api/models/next-booking-action';
import { getLayoutFillNextImage } from '~/src/utils/images';
import { ConversationMemberResult } from '~/libs/api/models/conversation-member-result';
import { PictureModel } from '~/libs/api/models/picture-model';

export const enum MESSAGE_TYPE {
  TEXT = 'Text',
  ATTACHMENENTS = 'Attachmenents',
  ACTIVITY = 'Activity',
  SYSTEM = 'System',
}

export const enum MESSAGE_STATUS {
  RECEIVED,
  SENT_IN_PROGRESS,
  SENT,
  SENT_FAILED,
  READ,
}

export interface IConversation {
  id: number;
  name: string;
  lastMessage: string;
  model: string;
  from: any;
  to: any;
  messageTime: any;
  status: BOOKING_STATUS;
  hasBeenRead: boolean;
}

export interface IMessage {
  type: MESSAGE_TYPE;
  sender: string;
  content: string;
  dateTime: any;
}

export function useChatScroll<T>(dep: T): React.MutableRefObject<HTMLDivElement> {
  const ref = React.useRef<HTMLDivElement>();
  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
}

export enum BOOKING_STATUS {
  CONFIRMED = 'CONFIRMED',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  DECLINED = 'DECLINED',
}

export enum INBOX_TABS {
  RENTER = 'RENTER',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
}

export const checkIcon = (status: MESSAGE_STATUS = MESSAGE_STATUS.SENT) => {
  const iconColors = {
    [MESSAGE_STATUS.SENT_IN_PROGRESS]: '#DAD1D1',
    [MESSAGE_STATUS.SENT]: '#8E7E7F',
    [MESSAGE_STATUS.READ]: '#1F2244',
  };

  const color = iconColors[status] ?? 'transparent';

  return (
    <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.2 6L1.8 3.6L0 5.4L4.2 9.6L12 1.8L10.2 0L4.2 6Z" fill={color} />
    </svg>
  );
};

export const sorMessagesByDate = (arrToSort: IConversationMessage[]) =>
  arrToSort.sort((a, b) => Date.parse(a.postedOn) - Date.parse(b.postedOn));

export const sortConversationsByDate = (arrToSort: ConversationResult[]) =>
  arrToSort.sort((a, b) => Date.parse(b.lastMessagePostedOn) - Date.parse(a.lastMessagePostedOn));

dayjs.extend(utc);

/* eslint-disable @typescript-eslint/naming-convention */
export enum RENTER_DASHBOARD_TAB {
  UPCOMING,
  PAST,
}

export const getBookingActionContentMessaging = (
  item: ConversationBookingResult,
  settings: SettingsProps,
  activeTab = INBOX_TABS.RENTER
) => {
  if (item.nextAction === NextBookingAction.None) {
    return (
      <>
        <InfoBox>
          <InfoBoxContent>
            <InfoIcon className="icon-icon-action-time" />
            <InfoText>
              <Trans id="banners.reservation.completed" />
            </InfoText>
          </InfoBoxContent>
        </InfoBox>
      </>
    );
  }

  if (item.nextAction === NextBookingAction.Request) {
    return (
      <InfoBox>
        <InfoBoxContent>
          <InfoIcon className="icon icon-icon-specification-kilometers" />
          <InfoText>
            <Trans id="banners.request.inquirtyNearlyThere" message="Your inquiry is nearly there." />
          </InfoText>
        </InfoBoxContent>
        <CTAWrapper>
          <PrimaryButton
            size="sm"
            onClick={() => {
              if (activeTab === INBOX_TABS.RENTER) {
                goToBookingPage(item.referenceCode, settings, false);
              } else {
                goToOperatorBookingPage(item.referenceCode, settings);
              }
            }}
          >
            <Trans id="button.bookingItem.request.complete" message="Complete inquiry" />
          </PrimaryButton>
        </CTAWrapper>
      </InfoBox>
    );
  }

  if (item.nextAction === NextBookingAction.Approve) {
    return (
      <InfoBox>
        <InfoBoxContent>
          <InfoIcon className="icon-icon-action-time" />
          <InfoText>
            <Trans id="banners.request.waitingForOwner" />
          </InfoText>
        </InfoBoxContent>
      </InfoBox>
    );
  }

  if (item.nextAction === NextBookingAction.PayDeposit) {
    return (
      <InfoBox>
        <InfoBoxContent>
          <InfoIcon className="icon-icon-action-time" />
          <InfoText>
            <Trans id="banners.request.notReserved.payDeposit" />
          </InfoText>
        </InfoBoxContent>
        <CTAWrapper>
          <PrimaryButton
            size="sm"
            onClick={() => {
              if (activeTab === INBOX_TABS.RENTER) {
                goToBookingPage(item.referenceCode, settings, true);
              } else {
                goToOperatorBookingPage(item.referenceCode, settings);
              }
            }}
          >
            <Trans id="button.labels.pay" />{' '}
            {getFormattedPriceWithForcedCurrency(item.remainingBalance || 0, item.currency, settings)}
          </PrimaryButton>
        </CTAWrapper>
      </InfoBox>
    );
  }

  if (item.nextAction === NextBookingAction.PayBalance) {
    const date = dayjs(item?.paymentDeadlineDate);
    const hours = date.diff(dayjs(), 'hour');
    const minutes = date.diff(dayjs(), 'minute') % 60;
    const days = date.diff(dayjs(), 'day');

    if (hours < 1) {
      return (
        <InfoBox>
          <InfoBoxContent>
            <InfoIcon className="icon-icon-action-time" />
            <InfoText>
              <Trans
                id="banners.reservation.expires.minutes"
                components={{
                  0: <BoldRed />,
                  1: <>{minutes}</>,
                  2: <>{date.format('DD.MM.YYYY, HH:mm')}</>,
                }}
                message="Máte <0><1></1> minut</0> (do <2></2>) na potvrzení (zaplacení) poptávky."
              />
            </InfoText>
          </InfoBoxContent>
          <CTAWrapper>
            <PrimaryButton
              size="sm"
              onClick={() => {
                if (activeTab === INBOX_TABS.RENTER) {
                  goToBookingPage(item.referenceCode, settings, true);
                } else {
                  goToOperatorBookingPage(item.referenceCode, settings);
                }
              }}
            >
              <Trans id="button.labels.pay" />{' '}
              {getFormattedPriceWithForcedCurrency(item.remainingBalance || 0, item.currency, settings)}
            </PrimaryButton>
          </CTAWrapper>
        </InfoBox>
      );
    }

    if (hours > 120) {
      return (
        <InfoBox>
          <InfoBoxContent>
            <InfoIcon className="icon-icon-action-time" />
            <InfoText>
              <Trans
                id="banners.reservation.expires.days"
                components={{
                  1: <BoldRed />,
                  0: <>{days}</>,
                }}
                message="You have <1><0></0></1> days to pay the reservation."
              />
            </InfoText>
          </InfoBoxContent>
          <CTAWrapper>
            <PrimaryButton
              size="sm"
              onClick={() => {
                if (activeTab === INBOX_TABS.RENTER) {
                  goToBookingPage(item.referenceCode, settings, true);
                } else {
                  goToOperatorBookingPage(item.referenceCode, settings);
                }
              }}
            >
              <Trans id="button.labels.pay" />{' '}
              {getFormattedPriceWithForcedCurrency(Math.round(item.remainingBalance || 0), item.currency, settings)}
            </PrimaryButton>
          </CTAWrapper>
        </InfoBox>
      );
    }

    return (
      <InfoBox>
        <InfoBoxContent>
          <InfoIcon className="icon-icon-action-time" />
          <InfoText>
            <Trans
              id="banners.reservation.expires.hours"
              components={{
                0: <>{hours}</>,
                1: <b />,
                2: <>{minutes}</>,
              }}
              message="Máte <1><0></0> hodin a <2></2> minut</1> na potvrzení (zaplacení) poptávky."
            />
          </InfoText>
        </InfoBoxContent>
        <CTAWrapper>
          <PrimaryButton
            size="sm"
            onClick={() => {
              if (activeTab === INBOX_TABS.RENTER) {
                goToBookingPage(item.referenceCode, settings, true);
              } else {
                goToOperatorBookingPage(item.referenceCode, settings);
              }
            }}
          >
            <Trans id="button.labels.pay" />{' '}
            {getFormattedPriceWithForcedCurrency(item.remainingBalance || 0, item.currency, settings)}
          </PrimaryButton>
        </CTAWrapper>
      </InfoBox>
    );
  }
};

export const getMessagingMemberByGuid = (guid: string, members: Array<ConversationMemberResult>) =>
  members?.find((member) => member.guid === guid);
export const getMessagingMemberByGuidClosure = (members: Array<ConversationMemberResult>) => (guid: string) =>
  getMessagingMemberByGuid(guid, members);
export const getMessagingMemberPicture = (
  picture: PictureModel,
  altImage: React.ReactNode,
  alt?: string,
  classString?: string
) => {
  if (picture) {
    return getLayoutFillNextImage(picture, alt, classString);
  }
  return altImage;
};

const InfoBox = styled.div`
  padding: 16px 12px;
  background: #edeae3;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 4px;
`;

const InfoIcon = styled.div`
  font-size: 20px;
`;

const InfoText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  align-items: center;
  color: #1f2244;
`;

const BoldRed = styled.b`
  color: var(--color-error-red);
`;

const InfoBoxContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const CTAWrapper = styled.div`
  margin-top: 14px;
`;
