import { FC, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Trans } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { MenuDescriptionLink } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/MenuDescriptionLink';
import { ComplementaryHeading, LeadHeading, MobileMenuContentWrapper } from '../menu.styles';
import { LoginIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/resources-icons/LoginIcon';

import { languageSelector } from '~/src/redux/selectors/settings';
import { getCountryLocaleOwnersLinks } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/ownersMenu/getCountryLocaleOwnerLinks';
import { openDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { ModalType } from '~/src/components/modal/modalTypes';
import { RootState } from '~/src/redux/reducers/rootReducer';
import { screenSelector } from '~/src/redux/selectors/screen';
import { userSelector } from '~/src/redux/selectors/user';
import { routes } from '~/src/utils/routing';
import { useCodebase } from 'features/hooks/useCodebase';
import { trackMainMenu } from '~/src/utils/dataLayer';
import { FlexCol } from 'ui/primitives/flex';
import tokens from 'ui/design/tokens/tokens';

export const OwnersMenuMobile: FC = () => {
  const lang = useSelector(languageSelector);
  const { isAuthenticated, customerData } = useSelector(userSelector) || {};
  const { companyLinks, resourcesLinks } = getCountryLocaleOwnersLinks(lang);
  const dispatch = useDispatch<RootState>();
  const screen = useSelector(screenSelector);
  const isFWC = useCodebase('fwc');

  useEffect(() => trackMainMenu({ text: t({ id: 'menu.owners' }) }), []);

  return (
    <>
      <MobileMenuContentWrapper>
        <LeadHeading>
          {isFWC ? (
            <Trans id="menu.fwc.resources" message="Resources" />
          ) : (
            <Trans id="menu.resources" message="Resources" />
          )}
        </LeadHeading>
        <FlexCol $gap={tokens.spacing.spacingL}>
          {resourcesLinks.map((data) => (
            <MenuDescriptionLink
              href={data.href}
              leftIcon={data.leftIcon}
              boldText={data.boldText}
              key={data.id}
              isDisplayedAsMobileVersion={true}
            />
          ))}

          {isAuthenticated &&
            (customerData?.isOperator || customerData?.isFleetManager || customerData?.isStationManager) && (
              <MenuDescriptionLink
                leftIcon={<LoginIcon fillColor="#1F2244" />}
                boldText={i18n._(t({ id: 'dashboard.heading.listings' }))}
                href={routes.operatorDashboard}
                isDisplayedAsMobileVersion={true}
              />
            )}
        </FlexCol>

        {!isAuthenticated && (
          <LoginButton
            onClick={() => {
              openDesktopOrPhoneModal(ModalType.LOGIN, screen, dispatch);
            }}
          >
            <LoginIcon fillColor="#1F2244" />
            <Text isDisplayedAsMobileVersion={true}>
              <Trans id="menu.ownersLogin" message="Owner Login" />
            </Text>
          </LoginButton>
        )}
      </MobileMenuContentWrapper>

      {companyLinks.length > 0 && (
        <MobileMenuContentWrapper isDividerDisplayed={true}>
          <ComplementaryHeading>
            <Trans id="optionalEquipment.extras" />
          </ComplementaryHeading>
          {companyLinks.map((data) => (
            <MenuDescriptionLink
              isDisplayedAsMobileVersion={true}
              href={data.href}
              externalURL={data.externalURL}
              newWindow={data.newWindow}
              boldText={data.boldText}
              key={data.id}
            />
          ))}
        </MobileMenuContentWrapper>
      )}
    </>
  );
};

const LoginButton = styled.button`
  display: flex;
  margin-top: 20px;
`;

const Text = styled.p<{ isDisplayedAsMobileVersion?: boolean }>`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  margin-left: 12px;
  position: relative;
  color: #1f2244;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #1f2244;
    transform: scaleX(0);
    transition: transform 0.2s ease-out;
  }

  :hover {
    ::before {
      transform: scaleX(1);
      transition: transform 0.2s ease-in;
    }
  }

  ${({ isDisplayedAsMobileVersion }) =>
    isDisplayedAsMobileVersion &&
    css`
      font-size: 14px;
    `}
`;
