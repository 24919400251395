/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Trans } from '@lingui/react';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { SettingsProps } from '~/src/redux/reducers/settings';
import { openDesktopOrPhoneModal } from '../../modal/utils';
import { ModalType } from '../../modal/modalTypes';
import { screenSelector } from '~/src/redux/selectors/screen';
import ToolTip from '../../ToolTip';
import { TypedIBrowser } from '~/src/redux/reducers/rootReducer';
import { useCodebase } from 'features/hooks/useCodebase';

declare global {
  interface Window {
    gtag: any;
  }
}

interface Props {
  settings: SettingsProps;
  screen: TypedIBrowser;
  dispatch: any;
}

const cookiesIcon = (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter x="-107.8%" y="-107.8%" width="315.6%" height="315.6%" filterUnits="objectBoundingBox" id="a">
        <feOffset dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="16" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix
          values="0 0 0 0 0.121568627 0 0 0 0 0.133333333 0 0 0 0 0.266666667 0 0 0 0.2 0"
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g filter="url(#a)" transform="translate(-16 -16)" fill="#FFF" fillRule="evenodd">
      <path d="M31.401 16c.323 0 .628.163.815.434a.996.996 0 0 1 .094.963c-.216.5-.327 1.04-.325 1.584a3.974 3.974 0 0 0 1.174 2.828 3.974 3.974 0 0 0 2.825 1.17 3.973 3.973 0 0 0 2.4-.82c.303-.23.71-.27 1.052-.104.343.167.56.517.559.899v.033a4.005 4.005 0 0 0 3.998 3.997 3.944 3.944 0 0 0 1.822-.456 1.003 1.003 0 0 1 1.421.582c.44 1.37.692 2.796.75 4.235a15.9 15.9 0 0 1-4.224 11.498A15.892 15.892 0 0 1 31.992 48c-8.59.02-15.654-6.77-15.977-15.357a15.906 15.906 0 0 1 4.223-11.499 15.895 15.895 0 0 1 11.113-5.143L31.4 16zm-1.357 2.127a13.951 13.951 0 0 0-8.334 4.375 13.921 13.921 0 0 0-3.696 10.06 13.963 13.963 0 0 0 4.486 9.74 13.951 13.951 0 0 0 10.068 3.683 13.908 13.908 0 0 0 9.724-4.5 13.915 13.915 0 0 0 3.694-10.06c-.037-.9-.16-1.793-.367-2.669-.526.151-1.068.23-1.616.232h-.007c-2.712 0-5.095-1.867-5.793-4.439a5.972 5.972 0 0 1-6.457-1.32 5.97 5.97 0 0 1-1.702-5.102zm4.945 17.871a3.006 3.006 0 0 1 3.003 3.004 3.006 3.006 0 0 1-3.003 3.004 3.006 3.006 0 0 1-3.003-3.004 3.006 3.006 0 0 1 3.003-3.004zm-9.506 1.001a1.502 1.502 0 1 1-.001 3.004 1.502 1.502 0 0 1 0-3.004zm9.506 1.002a1.001 1.001 0 1 0 0 2.002 1.001 1.001 0 0 0 0-2.002zm6.657-6.005a1.503 1.503 0 1 1-.304 2.991 1.503 1.503 0 0 1 .304-2.99zM25.98 25.984a4.01 4.01 0 0 1 4.004 4.006 4.01 4.01 0 0 1-4.004 4.005 4.01 4.01 0 0 1-4.003-4.005 4.01 4.01 0 0 1 4.003-4.006zm0 2.003a2.005 2.005 0 0 0-2.002 2.003c0 1.104.899 2.003 2.002 2.003a2.005 2.005 0 0 0 2.002-2.003 2.005 2.005 0 0 0-2.002-2.003zm7.507.001a1.495 1.495 0 0 1 1.482 1.262 1.498 1.498 0 0 1-2.36 1.46 1.493 1.493 0 0 1-.604-.98 1.487 1.487 0 0 1 .261-1.115 1.49 1.49 0 0 1 1.221-.627z" />
    </g>
  </svg>
);

const Cookies = ({ settings, dispatch, screen }: Props) => {
  const [isVisible, setIsVisible] = useState(false);
  const isFWC = useCodebase('fwc');

  useEffect(() => {
    if ((settings.marketingCookies === false || settings.analyticCookies === false) && !settings.cookieBarVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [settings.cookieBarVisible, settings.marketingCookies, settings.analyticCookies]);

  const onBoxClick = () => {
    openDesktopOrPhoneModal(ModalType.COOKIES_SETTINGS, screen, dispatch);
  };

  return (
    <>
      {isVisible && (
        <CookiesNoticeWrapper onClick={onBoxClick}>
          <ToolTip
            hoverTrigger={true}
            placementProp="right"
            heading={<Trans id="cookieNotice.tooltip.heading" message="Omezené cookies" />}
            text={
              isFWC ? (
                <Trans
                  id="cookieNotice.fwc.tooltip.description"
                  message="The set cookies are restricted and your experience with FreewayCamper does not have to be 100%"
                />
              ) : (
                <Trans
                  id="cookieNotice.tooltip.description"
                  message="Nastavené cookies jsou omezené a vaše zkušenost s Campiri nemusí být 100%"
                />
              )
            }
          >
            <CookiesNoticeInner>{cookiesIcon}</CookiesNoticeInner>
          </ToolTip>
        </CookiesNoticeWrapper>
      )}
    </>
  );
};

const CookiesNoticeWrapper = styled.div`
  position: fixed;
  left: 64px;
  bottom: 64px;
  z-index: 9;

  @media ${deviceMaxWidth.tablet} {
    bottom: max(90px, 10%);
    left: 10px;
  }
`;

const CookiesNoticeInner = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 10px;
  background-color: #fd253f;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media ${deviceMaxWidth.phone} {
    bottom: 0px;
  }
`;

const mapStateToProps = (state) => ({
  settings: state.settings,
  screen: screenSelector(state),
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(Cookies);
