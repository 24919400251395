import React, { FC } from 'react';

interface ArrowDownIconProps {
  width?: number;
  height?: number;
  fill?: string;
}

export const ArrowDownIcon: FC<ArrowDownIconProps> = ({ width = 12, height = 7, fill = 'currentColor' }) => (
  <svg width={width} height={height} viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      /* eslint-disable-next-line max-len */
      d="M0.109823 0.390121C0.256262 0.243667 0.493699 0.243656 0.640153 0.390096L6 5.74944L11.3598 0.390096C11.5063 0.243656 11.7437 0.243667 11.8902 0.390121C12.0366 0.536574 12.0366 0.774011 11.8902 0.920451L6.53035 6.27975C6.53029 6.27982 6.53022 6.27988 6.53015 6.27995C6.46058 6.34957 6.37798 6.4048 6.28707 6.4425C6.19606 6.48023 6.09851 6.49965 6 6.49965C5.90149 6.49965 5.80394 6.48023 5.71293 6.4425C5.62202 6.4048 5.53942 6.34957 5.46985 6.27995C5.46978 6.27988 5.46971 6.27982 5.46965 6.27975L0.109847 0.920451C-0.0366061 0.774011 -0.0366172 0.536574 0.109823 0.390121Z"
      fill={fill}
    />
  </svg>
);
