import { FC } from 'react';
import { NavigationMenuIconProps } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/types';

export const ListRVIcon: FC<NavigationMenuIconProps> = ({ fillColor = 'currentColor', height = 16, width = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_849_642)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M13.5 1.8667C14.8801 1.86817 15.9985 2.9866 16 4.3667C15.9985 5.57593 15.1331 6.61134 13.9433 6.82737L15.616 9.16937C15.8665 9.5183 16.0008 9.93718 16 10.3667V12.3667C15.9993 13.1948 15.3281 13.866 14.5 13.8667H13.914C13.7008 14.4649 13.1351 14.865 12.5 14.8667C11.86 14.8667 11.2947 14.4567 11.086 13.8667H4.914C4.70467 14.4567 4.13933 14.8667 3.5 14.8667C2.86067 14.8667 2.29467 14.4567 2.086 13.8667H1.5C0.671878 13.866 0.000735129 13.1948 0 12.3667V4.3667C0.00146976 2.9866 1.1199 1.86817 2.5 1.8667H13.5ZM12.5 12.8667C12.2239 12.8667 12 13.0906 12 13.3667C12 13.6428 12.2239 13.8667 12.5 13.8667C12.7761 13.8667 13 13.6428 13 13.3667C13 13.0906 12.7761 12.8667 12.5 12.8667ZM3.5 12.8667C3.22386 12.8667 3 13.0906 3 13.3667C3 13.6428 3.22386 13.8667 3.5 13.8667C3.77614 13.8667 4 13.6428 4 13.3667C4 13.0906 3.77614 12.8667 3.5 12.8667ZM15 10.8667H10V12.8667H11.086C11.2953 12.2767 11.8607 11.8667 12.5 11.8667C13.1393 11.8667 13.7053 12.2767 13.914 12.8667H14.5C14.7761 12.8667 15 12.6428 15 12.3667V10.8667ZM9 8.8667H1V12.3667C1 12.6427 1.224 12.8667 1.5 12.8667H2.086C2.29533 12.2767 2.86067 11.8667 3.5 11.8667C4.13933 11.8667 4.70533 12.2767 4.914 12.8667H9V8.8667ZM12.7433 6.8667H10V9.8667H14.8747C14.8532 9.82666 14.8294 9.78793 14.8033 9.7507L12.7433 6.8667ZM13.5 2.8667H2.5C1.67188 2.86743 1.00074 3.53858 1 4.3667V7.8667H9V6.3667C9 6.09056 9.22386 5.8667 9.5 5.8667H13.5C14.3273 5.8667 15 5.19403 15 4.3667C15 3.53937 14.3273 2.8667 13.5 2.8667ZM13.5 3.8667C13.7761 3.8667 14 4.09056 14 4.3667C14 4.64284 13.7761 4.8667 13.5 4.8667H12.5C12.2239 4.8667 12 4.64284 12 4.3667C12 4.09056 12.2239 3.8667 12.5 3.8667H13.5ZM8.5 3.8667C8.77614 3.8667 9 4.09056 9 4.3667C9 4.64284 8.77614 4.8667 8.5 4.8667H2.5C2.22386 4.8667 2 4.64284 2 4.3667C2 4.09056 2.22386 3.8667 2.5 3.8667H8.5Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_849_642">
        <rect width={width} height={height} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
