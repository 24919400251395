import axios from 'axios';
import { CookieBarVariantType } from '~/src/redux/reducers/settings';

export const sendCookieBarVisibleRequest = (variant: CookieBarVariantType, token: string) => {
  const payload = {
    action: 'request',
    var: variant,
    par1: token,
  };

  axios
    .request({
      method: 'post',
      url: 'https://europe-west1-campiri.cloudfunctions.net/cmp',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
      },
      data: payload,
    })
    .catch(e => {
      console.error(e);
    });
};

export const saveCookieConsentSet = (
  analytics: boolean,
  marketing: boolean,
  source: string,
  variant: CookieBarVariantType,
  token: string
) => {
  const payload = {
    action: 'response',
    consents: {
      analytics,
      marketing,
    },
    source,
    var: variant,
    par1: token,
  };

  axios
    .request({
      method: 'post',
      url: 'https://europe-west1-campiri.cloudfunctions.net/cmp',
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
      },
      data: JSON.stringify(payload),
    })
    .catch(e => {
      console.error(e);
    });
};
