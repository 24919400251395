import styled, { css } from 'styled-components';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

export const FilterCard = styled.a<{ hasBackgroundImage?: boolean; isInScrollContainer?: boolean }>`
  @media ${deviceMaxWidth.phone} {
    width: 148px;
    flex-shrink: 0;
  }
  overflow: hidden;
  position: relative;
  cursor: pointer;

  ${({ isInScrollContainer }) =>
    isInScrollContainer &&
    css`
      width: 148px !important;
      height: 104px;
      flex-shrink: 0;

      cursor: grab;
    `}

  background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, '#EDEAE3')};
  height: 104px;
  display: flex;
  flex-grow: 1;
  align-items: end;
  color: #1f2244;
  border-radius: 8px;
  width: 100%;
  padding: 0 !important;
  /*to solve webkit IOS bug, we need to add extra painting layer with isolate or translateZ*/
  transform: translateZ(0);

  &[data-bg-image='false'] {
    .isFWC & p {
      color: ${resolveThemeValue(tokens.color.text.textPrimary)};
    }
  }

  p {
    font-weight: 600;
    padding: 12px;
    text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.32);
    font-size: 16px;
    line-height: 20px;
    position: relative;
    z-index: 3;
    pointer-events: none;
    .isFWC & {
      color: ${resolveThemeValue(tokens.color.text.textContrastPrimary)};
    }
  }

  ${({ hasBackgroundImage }) =>
    hasBackgroundImage &&
    css`
      color: #edeae3 !important;
    `}
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  :hover {
    ::before {
      opacity: 1 !important;
    }
  }

  ::before {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(2, 0, 36, 0.36458333333333337) 0%, rgba(5, 7, 46, 0.66234243697479) 88%);
    .isFWC & {
      opacity: 0.4;
      background: linear-gradient(0deg, rgba(0, 22, 41, 0.4) 0%, rgba(0, 22, 41, 0.4) 100%);
    }
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
`;

export const FlagImage = styled.img`
  margin-left: 8px;
  width: 18px;
  height: 18px;
`;
