/* eslint-disable max-len */
const ImageAvatar = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <g fill="#1F2244">
        <g>
          <path
            d="M10 0c5.514 0 10 4.486 10 10 0 3.062-1.38 5.912-3.786 7.826l-.012.013c-.036.036-.073.058-.094.07C14.363 19.27 12.213 20.006 10 20c-2.23 0-4.343-.723-6.11-2.092-.04-.021-.075-.05-.105-.082C1.379 15.912 0 13.062 0 10 0 4.486 4.486 0 10 0zm0 15.63c-1.737-.003-3.422.6-4.763 1.704 1.415.927 3.071 1.419 4.763 1.416 1.692.003 3.348-.49 4.764-1.416-1.341-1.104-3.026-1.707-4.763-1.704zm0-14.38c-4.825 0-8.75 3.925-8.75 8.75 0 2.52 1.078 4.896 2.968 6.562 1.596-1.41 3.653-2.185 5.782-2.181 2.144 0 4.186.772 5.782 2.18 1.89-1.667 2.968-4.043 2.968-6.562 0-4.824-3.925-8.749-8.75-8.749zm0 1.875c2.757 0 5 2.243 5 5s-2.243 5-5 5-5-2.243-5-5c0-.876.232-1.74.67-2.497l.008-.015C6.577 4.077 8.22 3.131 10 3.125zM6.442 6.947c-.127.38-.192.778-.192 1.178 0 2.068 1.683 3.75 3.75 3.75 1.958-.003 3.586-1.51 3.74-3.462-.738.224-1.5.336-2.275.337h-.005c-1.83-.009-3.6-.645-5.018-1.803zM10 4.375c-1.158 0-2.244.535-2.954 1.443C8.263 6.895 9.83 7.493 11.456 7.5c.74 0 1.465-.122 2.16-.362C13.17 5.51 11.69 4.378 10 4.375z"
            transform="translate(-320 -22) translate(320 22)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ImageAvatar;
