import { FC } from 'react';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import styled, { css } from 'styled-components';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

interface DesktopListItemProps {
  shouldAnimateIn: boolean;
  itemOrder: 1 | 2 | 3;
  openMenuItem: 'vehicleRental' | 'owners' | 'support' | null;
  isOpen: boolean;
}

const MenuActivationVariant: Variants = {
  prepare: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const MenuSlideUpVariant: Variants = {
  prepare: { y: 40 },
  animate: { y: 0, transition: { type: 'tween', ease: 'easeOut', duration: 0.2 } },
  exit: { y: 10 },
};

export const DesktopItemMenuLayout: FC<DesktopListItemProps> = ({
  shouldAnimateIn,
  children,
  itemOrder,
  openMenuItem,
  isOpen,
}) => (
  <AnimatePresence custom={shouldAnimateIn} exitBeforeEnter={false} initial={false} key={itemOrder}>
    {shouldAnimateIn && (
      <FloatMenu
        variants={MenuActivationVariant}
        initial="prepare"
        animate="animate"
        exit="exit"
        itemOrder={itemOrder}
        id="floatMenu"
      >
        <motion.div variants={MenuSlideUpVariant}>
          <AnimatePresence exitBeforeEnter={false} initial={false}>
            {isOpen && (
              <FloatMenuContainer key={openMenuItem} layoutId="menuItem" layout={true}>
                {/* this is mandatory to prevent distortion of child components */}
                <motion.div layout={true}>{children}</motion.div>
              </FloatMenuContainer>
            )}
          </AnimatePresence>
        </motion.div>
      </FloatMenu>
    )}
  </AnimatePresence>
);

const FloatMenuContainer = styled(motion.div)`
  background: white;
  color: black;
  background-clip: padding-box;
  overflow: hidden;
  border-radius: ${resolveThemeValue(tokens.radius.radiusL, '16px')};
  filter: drop-shadow(0px 8px 36px rgba(0, 0, 0, 0.1));
  border: 1px solid #f4f2f2;
`;

const FloatMenu = styled(motion.div)<{ itemOrder: 1 | 2 | 3 }>`
  position: absolute;
  left: 100%;
  top: calc(100% + 20px);

  ${({ itemOrder }) =>
    itemOrder === 1 &&
    css`
      transform: translateX(-60%);
    `}
  ${({ itemOrder }) =>
    itemOrder === 2 &&
    css`
      transform: translateX(calc(-50% - 80px));
    `}
  ${({ itemOrder }) =>
    itemOrder === 3 &&
    css`
      transform: translateX(calc(-50% - 181px));
    `}

  .isFWC & {
    z-index: 100;
  }
`;
