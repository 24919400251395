import { Country } from './routing';

export const getFormattedPriceWithForcedCurrency = (
  // eslint-disable-next-line default-param-last
  amount = 0,
  forcedCurrency: string,
  settings: any,
  withDecimals = false
) =>
  new Intl.NumberFormat(settings?.country === Country.pl ? 'pl' : 'cs-CZ', {
    style: 'currency',
    currency: forcedCurrency?.toUpperCase() || 'CZK',
    currencyDisplay: 'symbol',
    maximumFractionDigits: withDecimals ? 2 : 0,
    minimumFractionDigits: 0,
  }).format(amount);

// eslint-disable-next-line default-param-last
export const getFormattedPrice = (amount = 0, settings?: any, forcedCurrency = null, decimalPlaces = 0) => {
  if (!amount) {
    return 0;
  }

  let currency = settings?.currency?.toUpperCase() || 'CZK';
  if (forcedCurrency) {
    currency = forcedCurrency;
  }
  const price = new Intl.NumberFormat(settings?.country === Country.pl && forcedCurrency !== 'CZK' ? 'pl' : 'cs-CZ', {
    style: 'currency',
    currency: currency || 'USD',
    currencyDisplay: 'symbol',
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: 0,
  }).format(Number(amount.toFixed(decimalPlaces)));
  return price;
};

export const getPriceSymbol = (currency: string, settings?: any) =>
  new Intl.NumberFormat(settings?.country === Country.pl ? 'pl' : 'cs-CZ', {
    style: 'currency',
    currency: currency || 'USD',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  })
    .format(0)
    .replace('0', '');
