import React, { FC } from 'react';
import { Trans } from '@lingui/react';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';
import { openDesktopOrPhoneModal } from '~/src/components/modal/utils';
import { ModalType } from '~/src/components/modal/modalTypes';
import { LoginIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/resources-icons/LoginIcon';
import { RootState, TypedIBrowser } from '~/src/redux/reducers/rootReducer';

interface LoginButtonProps {
  screen: TypedIBrowser;
  isLightColorVersion?: boolean;
}

export const LoginButton: FC<LoginButtonProps> = ({ screen, isLightColorVersion }) => {
  const dispatch = useDispatch<RootState>();

  return (
    <StyledButton
      id="ab_menu_test_login_btn_new"
      onClick={() => {
        openDesktopOrPhoneModal(ModalType.LOGIN, screen, dispatch);
      }}
      isLightVersion={isLightColorVersion}
      className="menuAbTestHidden">
      <IconWrapper>
        <LoginIcon width={20} height={20} />
      </IconWrapper>
      <StyledSpan>
        <span data-testid="login-button">
          <Trans id="labels.login" message="Přihlášení" />
        </span>
      </StyledSpan>
    </StyledButton>
  );
};

const StyledButton = styled.button<{ isLightVersion?: boolean }>`
  display: flex;
  color: #9c8c8c;
  height: 20px;
  margin-bottom: 5px;

  ${({ isLightVersion }) =>
    !isLightVersion &&
    css`
      :hover {
        color: #1f2244;
      }
    `};

  ${({ isLightVersion }) =>
    isLightVersion &&
    css`
      color: var(--color-beige);

      :hover {
        color: white;
      }
    `};

  position: relative;

  &.menuAbTestHidden {
    display: none;
  }
`;

const StyledSpan = styled.span`
  font-size: 16px;
  user-select: none;
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;
