'use client';

import styled, { css } from 'styled-components';
import { Property } from 'csstype';
import { mediaLgMin, mediaMdMax, mediaMdMin, mediaSmMax } from '../utils/breakpoints';
import { Box, BoxProps } from './box';
import { resolveThemeValue } from '../design/utils';
import { ColorString } from './mixins';

interface TextProps extends BoxProps {
  $fWeight?: Property.FontWeight;
  $fSize?: Property.FontSize;
  $lHeight?: Property.LineHeight;
  $color?: ColorString;
  $tAlign?: Property.TextAlign;
  $tTransform?: Property.TextTransform;
  $tDecoration?: Property.TextDecoration;

  $letterSpacing?: Property.LetterSpacing;
}

export interface TextPropsResponsive extends TextProps {
  $mdMin?: TextProps;
  $smMax?: TextProps;
  $mdMax?: TextProps;
  $lgMin?: TextProps;
}

const buildTextCSS = ({
  $fWeight,
  $fSize,
  $lHeight,
  $color,
  $tTransform,
  $tAlign,
  $letterSpacing,
  $tDecoration,
}: TextProps) => css`
  font-weight: ${$fWeight};
  font-size: ${$fSize};
  line-height: ${$lHeight};
  color: ${resolveThemeValue($color)};
  text-decoration: ${$tDecoration};
  text-transform: ${$tTransform};
  text-align: ${$tAlign};
  letter-spacing: ${$letterSpacing};
`;

export const Typography = styled(Box)<TextPropsResponsive>`
  ${(props) => buildTextCSS(props)};

  ${mediaMdMin} {
    ${({ $mdMin = {} }) => buildTextCSS($mdMin)};
  }

  ${mediaMdMax} {
    ${({ $mdMax = {} }) => buildTextCSS($mdMax)};
  }

  ${mediaSmMax} {
    ${({ $smMax = {} }) => buildTextCSS($smMax)};
  }

  ${mediaLgMin} {
    ${({ $lgMin = {} }) => buildTextCSS($lgMin)};
  }
`;
