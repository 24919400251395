import React, { Dispatch, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import { connect } from 'react-redux';
import { Trans } from '@lingui/react';
import useOnClickOutside from 'features/hooks/useOnClickOutside';
import { TypedIBrowser } from 'features/redux/reducers/rootReducer';
import { searchInfoSelector } from 'features/redux/selectors/search';
import { getFormattedDate, getFormattedDateWithoutYear } from 'features/utils/date';
import useWindowSize from 'features/hooks/useWindowSize';
import { screenSelector } from 'features/redux/selectors/screen';
import { useCodebase } from 'features/hooks/useCodebase';
import { SearchInfoReduxData } from 'features/redux/reducers/search';
import { RouteType } from 'features/routing/types';
import CalendarIcon from 'ui/icons/ic_calendar.svg';
import useModal from 'features/components/modals/useModal';
import useLocalizedRouterFreeway from 'features/routing/useLocalizedRouterFreeway';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';
import { makeQueryForSearchFromHP } from 'features/utils/search/utils';
import { changeDateSelection, useSearchParams } from 'public-web/src/utils/query';
import { getBookingMinStartDate, SearchInputValue, SearchPlaceholder, ValueCleaner } from './shared';
import { mediaSmMax } from '../../utils/breakpoints';
import DateRange from './DateRange';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';
import { Box } from '../../primitives/box';
import SearchWidgetModal from './modal/SearchWidgetModal';
import { PlaceType } from './utils/utils';
import { Flex, FlexCenterBoth, FlexJustifySpaceBetween } from '../../primitives/flex';
import CloseIcon from '../../icons/ic_close.svg';
import { staticHeadlineTextStyles } from '../primitives/typography/CustomTypography';
import FlexibleSearchButton from './FlexibleSearchButton';
import FlexibleSearchButtonWithQuery from './FlexibleSearchButtonWithQuery';
import { setFlexibleSearchOffset } from 'features/redux/actions/search';

// const DateRange: any = dynamic(import('~/src/components/inputs/DateRange'));

interface Props {
  screen: TypedIBrowser;
  searchInfo: SearchInfoReduxData;
  dispatch: any;
  popularPlaces: PlaceType;
  popularSearches: PlaceType;
  dynamicSegmentRoute?: RouteType;
  monthsBreakPoint?: number;
  showSpecialOffer?: boolean;
  className?: string;
  redirectAfterSelectingBothDate?: boolean;
  onDateChange: (date: { selection: any }, dispatch: Dispatch<any>) => void;
}

function DateRangeCalendar({
  screen,
  searchInfo,
  dispatch,
  popularPlaces,
  popularSearches,
  monthsBreakPoint = 760,
  dynamicSegmentRoute,
  showSpecialOffer,
  redirectAfterSelectingBothDate = false,
  className = '',
  onDateChange,
}: Props) {
  const size = useWindowSize();
  const isFWC = useCodebase('fwc');
  const searchParams = useSearchParams();
  const getMonthsToShow = () => {
    if (isFWC) return 2;
    return (size.width || 0) < monthsBreakPoint ? 1 : 2;
  };
  const router = useRouter();
  const FlexibleSearchButtonVariable =
    router.route === '/search' ? FlexibleSearchButtonWithQuery : FlexibleSearchButton;

  const [showCalendar, setShowCalendar] = useState(false);
  const [monthsToShow, setMonthsToShow] = useState(getMonthsToShow());
  const calendarRef = useRef(null);
  useOnClickOutside(calendarRef, () => setShowCalendar(false));

  useEffect(() => {
    const { flexibleDate } = router.query;
    if (flexibleDate) {
      dispatch(setFlexibleSearchOffset(Number(flexibleDate)));
    }
  }, []);

  useEffect(() => {
    setMonthsToShow(getMonthsToShow());
  }, [size.width]);

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const clean = () => {
    dispatch({
      type: 'SET_SEARCH_DATES',
      payload: null,
    });
    dispatch(setFlexibleSearchOffset(0));

    if (router.route === '/search') {
      searchParams.set({ dateFrom: undefined, dateTo: undefined, flexibleDate: undefined });
    }
  };

  const [tmpSelectedDays, setTmpSelectedDays] = useState(
    searchInfo.selectedDays
      ? searchInfo.selectedDays
      : {
          startDate: null,
          endDate: new Date(''),
          key: 'selection',
        }
  );
  useEffect(() => {
    setTmpSelectedDays(
      searchInfo.selectedDays
        ? searchInfo.selectedDays
        : {
            startDate: null,
            endDate: new Date(''),
            key: 'selection',
          }
    );
  }, [searchInfo.selectedDays]);

  const [selectedBothDates, setSelectedBothDates] = useState(false);
  const localizedRouter = useLocalizedRouterFreeway();

  useEffect(() => {
    if (selectedBothDates) {
      onDateChange?.({ selection: tmpSelectedDays }, dispatch);
      setShowCalendar(false);
    }
  }, [selectedBothDates]);

  useEffect(() => {
    if (selectedBothDates && redirectAfterSelectingBothDate) {
      changeDateSelection({ selection: tmpSelectedDays }, dispatch);
      setShowCalendar(false);
    }
  }, [selectedBothDates, redirectAfterSelectingBothDate]);

  const [modal, openModal, , isOpen] = useModal(
    <SearchWidgetModal
      popularPlaces={popularPlaces}
      popularSearches={popularSearches}
      goToSearchPageWithQuery={(searchInfoLocal, selectedDays) => {
        localizedRouter.push(
          freewayRoutesTyped.search,
          makeQueryForSearchFromHP(
            {
              ...searchInfoLocal,
              selectedDays,
            },
            {}
          )
        );
      }}
      type="date"
    />,
    {
      title: 'Your trip',
    }
  );

  return (
    <Wrapper className={className} ref={calendarRef}>
      {isFWC && (
        <Box className="icon" $mr={tokens.spacing.spacingL}>
          <CalendarIcon height={20} />
        </Box>
      )}
      {modal}
      <CalendarInput
        data-testid="date-picker-from"
        onClick={() => {
          if (screen.lessThan.medium) {
            if (isFWC) {
              if (!isOpen) {
                openModal();
              }
            } else {
              dispatch({
                type: 'SHOW_MODAL',
                payload: {
                  type: 'SEARCH_WIDGET_MODAL_FWC_PORT',
                  isVisible: true,
                  modalProps: {
                    type: 'date',
                  },
                },
              });
            }
          } else {
            toggleCalendar();
          }
        }}
      >
        <Placeholder>
          <Trans id="datePicker.term.label" message="Termín" />
        </Placeholder>
        {searchInfo.selectedDays &&
          searchInfo.selectedDays.startDate &&
          searchInfo.selectedDays.endDate &&
          showCalendar &&
          !isFWC && (
            <ValueCleaner
              onClick={() => {
                clean();
              }}
            >
              <Trans id="button.labels.clear" />
            </ValueCleaner>
          )}
        <InputValue className={searchInfo.selectedDays ? 'hasValue' : ''} data-testid="date-picker-dates">
          {searchInfo.selectedDays && searchInfo.selectedDays.startDate && searchInfo.selectedDays.endDate ? (
            <>
              {getFormattedDateWithoutYear(searchInfo.selectedDays.startDate, 'cs')}
              {' - '}
              {getFormattedDate(searchInfo.selectedDays.endDate, 'cs')}
              {isFWC && searchInfo.flexibleSearchOffset !== 0 && ` (±${searchInfo.flexibleSearchOffset})`}
            </>
          ) : (
            <Trans id="datePicker.term.placeholder" message="Vyberte datum" />
          )}
        </InputValue>
      </CalendarInput>
      {searchInfo.selectedDays && isFWC && (
        <Box
          $position="absolute"
          $offset={{
            right: '5px',
          }}
          onClick={() => {
            clean();
          }}
          className="closeIcon"
        >
          <FlexCenterBoth
            $cursor="pointer"
            $borderRadius="50%"
            $background={tokens.color.backgrounds.surfaceBg}
            $width="20px"
            $height="20px"
          >
            <CloseIcon height={7} />
          </FlexCenterBoth>
        </Box>
      )}

      {showCalendar && (
        <CalendarWrapper id="date-dropdown" className={monthsToShow === 1 ? 'oneColumn' : ''}>
          <DateRange
            isLoading={false}
            disabledDates={[]}
            showSpecialOffer={showSpecialOffer}
            className="date-range-picker"
            months={monthsToShow}
            minDate={getBookingMinStartDate(isFWC)}
            showMonthAndYearPickers={false}
            moveRangeOnFirstSelection={false}
            rangeColors={[isFWC ? '#001629' : '#119383']}
            dynamicSegmentRoute={dynamicSegmentRoute}
            onChange={(item) => {
              setTmpSelectedDays(item.selection);
            }}
            onRangeFocusChange={(item) => {
              setSelectedBothDates(item[0] === 0 && item[1] === 0);
            }}
            ranges={[tmpSelectedDays]}
            direction="horizontal"
          />
          {isFWC && (
            <Flex $mx={tokens.spacing.spacing3Xl} $mb={tokens.spacing.spacing2Xl} $gap={tokens.spacing.spacingL}>
              <FlexibleSearchButtonVariable offset={0}>
                <Trans id="flexibleSearch.exact" message="Exact date" />
              </FlexibleSearchButtonVariable>
              <FlexibleSearchButtonVariable offset={1}>
                ± <Trans id="flexibleSearch.1day" message="1 day" />
              </FlexibleSearchButtonVariable>
              <FlexibleSearchButtonVariable offset={2}>
                ± <Trans id="flexibleSearch.2days" message="2 days" />
              </FlexibleSearchButtonVariable>
            </Flex>
          )}
        </CalendarWrapper>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 29%;
  transition: 300ms all;

  .isFWC & {
    height: 100%;
  }

  .closeIcon {
    display: none;
  }

  ${resolveInCodebase(
    'fwc',
    css`
      border-radius: ${resolveThemeValue(tokens.radius.radiusL)} !important;
      padding: 0 ${resolveThemeValue(tokens.spacing.spacingL)};
      margin-left: ${resolveThemeValue(tokens.spacing.spacingL)};
    `
  )}

  ${mediaSmMax} {
    grid-area: date;
    padding: 16px;
    border-right: 1px solid var(--color-beige);
    max-width: unset;
    border-bottom: 1px solid ${resolveThemeValue(tokens.color.border.borderLight, 'var(--color-beige)')};
    background: white;
    ${resolveInCodebase(
      'fwc',
      css`
        margin-left: 0;
      `
    )}
  }

  &:hover {
    background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, 'var(--color-beige)')};
    .closeIcon {
      display: block;
    }
    .icon svg {
      fill: #001629;
    }
  }
`;

export const CalendarInput = styled.div`
  width: 100%;
  background-color: white;
  color: hsl(0, 0%, 50%);
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0px;
  padding: 0px 24px;
  transition: 300ms all;

  ${resolveInCodebase(
    'fwc',
    css`
      padding: 0 24px 0 0;
    `
  )}

  &.selected {
    color: black;
  }

  ${Wrapper}:hover & {
    background-color: var(--color-beige);
  }

  ${mediaSmMax} {
    padding: 0px;
  }
`;

const Placeholder = styled(SearchPlaceholder)``;
const InputValue = styled(SearchInputValue)``;

export const CalendarWrapper = styled.div`
  position: absolute;
  top: 104px;
  background-color: white;
  z-index: 9;
  cursor: pointer;
  box-shadow: 0 4px 32px 0 rgba(31, 34, 68, 0.2);
  border-radius: 8px;
  width: 100%;
  width: 700px;

  ${resolveInCodebase(
    'fwc',
    css`
      left: -3px;
      top: 47px;
      border-radius: ${resolveThemeValue(tokens.radius.radiusL)} !important;
    `
  )}

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrMonth {
    width: 50%;

    &:first-child {
      border-right: solid 1px var(--color-beige);
    }
  }

  .rdrCalendarWrapper {
    width: 100%;
  }

  .rdrMonthName {
    text-align: center;
    font-family: var(--font-poppins);

    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--color-dark-blue);
    text-transform: capitalize;
    margin-top: -40px;
    margin-bottom: 30px;
    .isFWC & {
      ${staticHeadlineTextStyles('h6')}
    }
  }

  &.oneColumn {
    width: 350px;

    .rdrCalendarWrapper,
    .rdrMonth {
      width: 100%;
    }
  }
`;

const mapStateToProps = (state: any) => ({
  searchInfo: searchInfoSelector(state),
  screen: screenSelector(state),
});

export default connect(mapStateToProps, (dispatch) => ({ dispatch }))(DateRangeCalendar);
