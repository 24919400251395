import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/react';
import { screenSelector } from 'features/redux/selectors/screen';
import { useCodebase } from 'features/hooks/useCodebase';
import { searchInfoSelector } from 'features/redux/selectors/search';
import PlaceIcon from 'ui/icons/ic_pin.svg';
import useModal from 'features/components/modals/useModal';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';
import { makeQueryForSearchFromHP } from 'features/utils/search/utils';
import useLocalizedRouterFreeway from 'features/routing/useLocalizedRouterFreeway';
import CloseIcon from 'ui/icons/ic_close.svg';
import { mediaSmMax } from '../../utils/breakpoints';
import { SearchInputValue, SearchPlaceholder } from './shared';
import MapBoxAutocomplete from './MapBoxAutocomplete';
import { PlaceType } from './utils/utils';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';
import { Box } from '../../primitives/box';
import SearchWidgetModal from './modal/SearchWidgetModal';
import { FlexCenterBoth } from '../../primitives/flex';

interface PlaceAutocompleteProps {
  placeError: boolean;
  setPlaceError: (boolean: boolean) => void;
  customClearSearchSideEffect?: () => void;
  popularPlaces: PlaceType;
  className?: string;

  popularSearches: PlaceType;
}

export default function PlaceAutocomplete({
  placeError,
  setPlaceError,
  customClearSearchSideEffect,
  popularPlaces,
  className,
  popularSearches,
}: PlaceAutocompleteProps) {
  const searchInfo = useSelector(searchInfoSelector);
  const screen = useSelector(screenSelector);
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const isFWC = useCodebase('fwc');
  const [inputHasFocus, setInputHasFocus] = useState(false);
  const localizedRouter = useLocalizedRouterFreeway();

  const [modal, openModal, , isOpen] = useModal(
    <SearchWidgetModal
      popularPlaces={popularPlaces}
      popularSearches={popularSearches}
      goToSearchPageWithQuery={(searchInfoLocal, selectedDays) => {
        localizedRouter.push(
          freewayRoutesTyped.search,
          makeQueryForSearchFromHP(
            {
              ...searchInfoLocal,
              selectedDays,
            },
            {}
          )
        );
      }}
      type="place"
    />,
    {
      title: 'Your trip',
    }
  );

  useEffect(() => {
    if (placeError) {
      inputRef?.current?.focus();
    }
  }, [placeError]);

  return (
    <Wrapper
      className={placeError ? `hasError ${className} wrapper` : `wrapper ${className}`}
      onClick={() => {
        if (screen.lessThan.medium) {
          if (isFWC) {
            if (!isOpen) openModal();
          } else {
            dispatch({
              type: 'SHOW_MODAL',
              payload: {
                type: 'SEARCH_WIDGET_MODAL_FWC_PORT',
                isVisible: true,
                modalProps: {
                  type: 'place',
                },
              },
            });
          }
        } else {
          setInputHasFocus(true);
          inputRef?.current?.focus();
        }
      }}
    >
      {modal}
      {isFWC && (
        <Box className="icon" $mr={tokens.spacing.spacingL}>
          <PlaceIcon height={20} />
        </Box>
      )}
      <AutocompleteInput>
        <Placeholder>
          <Trans id="autoComplete.startPlace.label" message="Odkud vyrazíte" />
        </Placeholder>
        <InputValue>
          <MapBoxAutocomplete
            popularPlaces={popularPlaces}
            popularSearches={popularSearches}
            customClearSearchSideEffect={customClearSearchSideEffect}
            inputHasFocus={inputHasFocus}
            setInputHasFocus={setInputHasFocus}
            selectedPlaceName={searchInfo?.place?.place_name ? searchInfo.place.place_name : null}
            inputRef={inputRef}
            onSelect={(place: any) => {
              setPlaceError(false);
              dispatch({ type: 'SET_SEARCH_PLACE', payload: place });
            }}
          />
        </InputValue>
      </AutocompleteInput>
      {isFWC && searchInfo?.place?.place_name && (
        <Box
          $position="absolute"
          $offset={{
            right: '5px',
          }}
          onClick={() => {
            setPlaceError(true);
            dispatch({ type: 'SET_SEARCH_PLACE', payload: null });
          }}
          className="closeIcon"
        >
          <FlexCenterBoth
            $cursor="pointer"
            $borderRadius="50%"
            $background={tokens.color.backgrounds.surfaceBg}
            $width="20px"
            $height="20px"
          >
            <CloseIcon height={7} />
          </FlexCenterBoth>
        </Box>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 48%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  padding: 0px 24px;
  background-color: white;
  transition: 300ms all;

  .isFWC & {
    height: 100%;
  }

  .closeIcon {
    display: none;
  }

  &:hover,
  &:has(input:focus) {
    background-color: ${resolveThemeValue(tokens.color.backgrounds.surfaceContrast, 'var(--color-beige)')};
    .icon svg {
      fill: #001629;
    }
    .closeIcon {
      display: block;
    }
  }

  ${resolveInCodebase(
    'fwc',
    css`
      border-radius: ${resolveThemeValue(tokens.radius.radiusL)};
      padding: 0 ${resolveThemeValue(tokens.spacing.spacingL)};
      margin-right: ${resolveThemeValue(tokens.spacing.spacingL)};
      input {
        font-size: 16px;
        &::placeholder {
          color: ${resolveThemeValue(tokens.color.text.textSecondary)};
        }
      }
    `
  )}

  .isCAMPIRI & {
    &.hasError {
      background: #f5d3d3;
    }
  }

  ${mediaSmMax} {
    grid-area: place;
    max-width: unset;
    margin: 0 auto;
    border-bottom: 1px solid ${resolveThemeValue(tokens.color.border.borderLight, 'var(--color-beige)')};
    border-radius: 0px;

    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    ${resolveInCodebase(
      'fwc',
      css`
        border-radius: 0;
      `
    )}
  }
`;

const AutocompleteInput = styled.div`
  width: 100%;
  color: hsl(0, 0%, 50%);
  height: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 0px;
`;

const Placeholder = styled(SearchPlaceholder)`
  margin-top: 13px;

  ${mediaSmMax} {
    margin-top: 0px;
    margin-bottom: 6px;
  }
`;

const InputValue = styled(SearchInputValue)`
  position: relative;
  width: 100%;
  margin-top: 0px;
`;
