'use client';

import tokens from 'ui/design/tokens/tokens';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from 'ui/primitives/box';
import { Flex, FlexAlignCenter, FlexJustifySpaceBetweenAlignCenter } from 'ui/primitives/flex';
import FreewayLogo from 'ui/icons/FreeWayLogo.svg';
import FreeWayLogoDark from 'ui/icons/FreeWayLogoDark.svg';
import styled, { css } from 'styled-components';
import { resolveThemeValue } from 'ui/design/utils';
import { HiddenOnMobile } from 'ui/primitives/hidden';
import LocalizedLink from 'features/routing/LocalizedLink';
import { usePathname } from 'next/navigation';
import { routes } from '~/src/utils/routing';
import NavSearchBar from '~/src/freeway/components/layout/components/NavSearchBar';
import UserMenuDropdown from '~/src/components/layout/headers/basicHeader/UserMenuDropdown';
import { MemoizedMenuControl } from '~/src/components/layout/headers/basicHeader/MenuItemsControl';
import { useTypedSelector } from '~/src/redux/store';
import { selectIsUserAuthenticated } from '~/src/redux/selectors/user';
import { getPageType, Menu } from '~/src/components/layout/headers/utils';
import { useRouter } from 'next/router';
import MobileMenuComponent from '~/src/components/layout/headers/navigation-menu/MobileMenuComponent';
import { useScrollBlock } from '~/src/hooks/useScrollBlock';
import MobileMenuExpanded from '~/src/components/layout/headers/navigation-menu/MobileMenuExpanded';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';
import { deviceMaxWidth } from '~/src/styles/breakpoints';

interface Props {
  isWhiteVersion: boolean;
  showFullMenu: boolean;
  isRenterPage: boolean;
  isSettingsPage: boolean;
  isBookingPage: boolean;
  isOperatorPage: boolean;
  isOperatorBookingPage: boolean;
}

export default function Header({
  isWhiteVersion,
  isOperatorPage,
  isOperatorBookingPage,
  isBookingPage,
  isSettingsPage,
  isRenterPage,
  showFullMenu,
}: Props) {
  const pathname = usePathname();
  const isSearch = useMemo(
    () => Object.values<string>(routes.search.urls).some((searchUrl) => pathname.includes(searchUrl)),
    [pathname]
  );

  const isUserAuthenticated = useTypedSelector(selectIsUserAuthenticated);
  const router = useRouter();
  const pageType = useMemo(
    () => getPageType(router.pathname, isUserAuthenticated),
    [router.pathname, isUserAuthenticated]
  );

  const [blockScroll, allowScroll] = useScrollBlock(false);
  const settings = useSelector(settingsSelector);

  const toggleMenu = useCallback((value: Menu | null) => {
    if (value === null) {
      setMenuExpanded(null);
      allowScroll();
    }

    setMenuExpanded((prev) => {
      if (value === prev) {
        allowScroll();
        return null;
      }
      blockScroll();

      return value;
    });
  }, []);

  const [showMenuExpanded, setMenuExpanded] = useState<Menu | null>(null);
  useEffect(() => {
    if (!showMenuExpanded) {
      allowScroll(true, false);
    }
  }, [showMenuExpanded]);

  const displayRenterMenu =
    showFullMenu && (isRenterPage || isSettingsPage || isBookingPage) && !isOperatorPage && !isOperatorBookingPage;

  const displayOperatorMenu = showFullMenu && !isRenterPage && (isOperatorPage || isOperatorBookingPage);

  const specialMenuToDisplay = (): 'renter' | 'operator' | '' => {
    if (displayRenterMenu) return 'renter';
    if (displayOperatorMenu) return 'operator';

    return '';
  };

  return (
    <Wrapper
      isWhiteVersion={isWhiteVersion}
      $height="80px"
      $mdMax={{
        $padding: tokens.spacing.spacingL,
      }}
      $background={isWhiteVersion ? tokens.color.backgrounds.surfaceBg : tokens.color.backgrounds.surfaceSection}
    >
      <FlexJustifySpaceBetweenAlignCenter $height="100%" $maxWidth="1200px" $margin="auto">
        <FlexAlignCenter $gap={tokens.spacing.spacing4Xl}>
          <LocalizedLink href={routes.index}>
            {isWhiteVersion ? <FreeWayLogoDark width="72" height="60" /> : <FreewayLogo width="72" height="60" />}
          </LocalizedLink>
          <HiddenOnMobile>{isSearch && <NavSearchBar />}</HiddenOnMobile>
        </FlexAlignCenter>
        <FlexAlignCenter $gap={tokens.spacing.spacing5Xl}>
          <StyledMenuComponent
            showMenuExpanded={showMenuExpanded}
            isWhiteVersion={isWhiteVersion}
            toggleMenu={toggleMenu}
            isSticky={false}
            isSearchPage={false}
            messagesCount={0}
            messagingEnabled={false}
          />
          <Box
            $position="absolute"
            $width="100%"
            $offset={{
              left: '0px',
            }}
          >
            <HeaderContainer className={showMenuExpanded ? 'expanded' : ''}>
              <nav>
                <MobileMenuExpanded
                  messagesCount={0}
                  messagingEnabled={false}
                  isOperatorPage={isOperatorPage}
                  isRenterPage={isRenterPage}
                  showMenuExpanded={showMenuExpanded}
                  settings={settings}
                  isMessagePage={false}
                />
              </nav>
            </HeaderContainer>
          </Box>
          <HiddenOnMobile>
            <MemoizedMenuControl specialMenuType={specialMenuToDisplay()} isWhiteVersion={isWhiteVersion} />
          </HiddenOnMobile>
          <HiddenOnMobile>
            <Flex $position="relative">
              <UserMenuDropdown
                isBookingPage={isBookingPage}
                isOperatorPage={isOperatorPage}
                isRenterPage={isRenterPage}
                isWhiteVersion={isWhiteVersion}
                messagingEnabled={false}
              />
            </Flex>
          </HiddenOnMobile>
        </FlexAlignCenter>
      </FlexJustifySpaceBetweenAlignCenter>
    </Wrapper>
  );
}

const HeaderContainer = styled.div`
  box-shadow: 0 1px 0 0 var(--color-beige);
  background-color: var(--color-white);
  position: relative;

  &.expanded {
    @media ${deviceMaxWidth.phone} {
      height: 65px;
      nav {
        align-items: flex-start;
        flex-direction: column;

        > div {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100%;
          top: 65px;
          left: 0;
          background: #fff;
          border-top: 1px solid var(--color-beige);
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
          padding: 8px 0px 16px;
          opacity: 1; /* transition: opacity 1s ease-in-out; */

          a {
            margin: 0;
            font-family: var(--font-poppins);
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            color: var(--color-dark-blue);

            ::before {
              display: none !important;
            }
          }
        }
      }
    }

    .search-page {
      .menu {
        display: flex;
      }
    }

    .showed {
      width: calc(100% - 62px);
    }
  }
`;

const StyledMenuComponent = styled(MobileMenuComponent)`
  position: unset;
  height: auto;
  width: auto;
`;

const Wrapper = styled(Box)<{ isWhiteVersion: boolean }>`
  ${({ isWhiteVersion }) =>
    isWhiteVersion &&
    css`
      border-bottom: 1px solid ${resolveThemeValue(tokens.color.items.separatorLight)};
    `}
`;
