import { useRef, useState } from 'react';

const safeDocument = typeof document !== 'undefined' ? document : {};

/**
 * Usage:
 * const [blockScroll, allowScroll] = useScrollBlock();
 */
export const useScrollBlock = (applyPadding = true) => {
  const scrollBlocked = useRef();
  const html = safeDocument.documentElement;
  const { body } = safeDocument;
  const [scrollPosition, setScrollPosition] = useState(0);

  const blockScroll = (scrollToTop = true) => {
    if (!body || !body.style || scrollBlocked.current) return;

    setScrollPosition(window.pageYOffset);

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight = parseInt(window.getComputedStyle(body).getPropertyValue('padding-right')) || 0;

    /**
     * 1. Fixes a bug in iOS and desktop Safari whereby setting
     *    `overflow: hidden` on the html/body does not prevent scrolling.
     * 2. Fixes a bug in desktop Safari where `overflowY` does not prevent
     *    scroll if an `overflow-x` style is also applied to the body.
     */
    if (scrollToTop) {
      html.style.position = 'relative'; /* [1] */
      html.style.overflow = 'hidden'; /* [2] */
    }
    body.style.position = 'relative'; /* [1] */
    body.style.overflow = 'hidden'; /* [2] */
    body.style.top = `-${scrollPosition}px`;
    if (applyPadding) body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowScroll = (force = false, scrollTo = true) => {
    if (!force && (!body || !body.style || !scrollBlocked.current)) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.removeProperty('top');
    body.style.paddingRight = '';

    if (scrollTo) {
      window.scrollTo(0, scrollPosition);
    }

    setScrollPosition(0);

    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
};
