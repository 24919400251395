import { useSelector } from 'react-redux';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { useCodebase } from 'features/hooks/useCodebase';

export const useGoogleReviews = () => {
  const settings = useSelector(settingsSelector);
  const { specialOfferBarVisible } = settings;
  const isFWC = useCodebase('fwc');
  const reviewScore = settings.googleReviews?.stars;
  const reviewsLink = isFWC
    ? 'https://www.google.com/maps/place/FreewayCamper+-+Wohnmobil+%26+Camper+mieten+Berlin/@52.5337127,13.3305122,15z/data=!4m6!3m5!1s0x47a851d428e8025d:0x1fa3384f4dc614db!8m2!3d52.5337127!4d13.3305122!16s%2Fg%2F11r33tb58q?entry=ttu'
    : 'https://www.google.com/maps/place/Campiri/data=!3m1!4b1!4m5!3m4!1s0x470b9525a07bd303:0x40e279432f3b8ab2!8m2!3d49.8037434!4d15.4749126';
  let fullStarsCount = Math.floor(reviewScore);
  let hasHalfStar = false;

  if (reviewScore - Math.floor(reviewScore) >= 0.25 && reviewScore - Math.floor(reviewScore) < 0.75) {
    hasHalfStar = true;
  }
  if (reviewScore - Math.floor(reviewScore) >= 0.75) {
    fullStarsCount++;
  }

  const emptyStarsCount = 5 - Math.floor(fullStarsCount) - (hasHalfStar ? 1 : 0);
  const fullStarsArr = Array(Math.floor(fullStarsCount)).fill('x');
  const emptyStarsArr = Array(Math.floor(emptyStarsCount)).fill('x');

  return {
    fullStarsArr,
    emptyStarsArr,
    reviewsLink,
    specialOfferBarVisible,
    hasHalfStar,
    reviewScore,
  };
};
