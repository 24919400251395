import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import ClientOnlyPortal from 'features/providers/ClientOnlyPortal';
import Modal, { ModalProps } from './Modal';

const ModalContext = createContext<{
  closeModal: () => void;
  isModalOpen: boolean;
}>({
  closeModal: () => ({}),
  isModalOpen: false,
});

export const useModalContext = () => useContext(ModalContext);

export const useCloseModal = (onCloseModal?: () => void) => {
  const modalContext = useModalContext();
  const { closeModal } = modalContext ?? {};

  const handleCloseModal = useCallback(() => {
    onCloseModal?.();
    closeModal?.();
  }, [closeModal, onCloseModal]);

  if (!closeModal) {
    return undefined;
  }

  return handleCloseModal;
};

type ModalControl = [ReactNode, () => void, () => void, boolean];

export default function useModal(modalContent: ReactNode, modalProps: ModalProps = {}): ModalControl {
  const [isModalOpen, setIsModalOpen] = useState(modalProps.open || false);
  const [overrideModalProps, setOverrideModalProps] = useState<ModalProps | null>(null);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setOverrideModalProps(null);

    modalProps.onClose?.();
  }, []);

  const providerValue = useMemo(() => ({ isModalOpen, closeModal }), [isModalOpen, closeModal]);

  const modal = (
    <ClientOnlyPortal selector="#modalPortal">
      <ModalContext.Provider value={providerValue}>
        <Modal
          modalProps={{ ...modalProps, ...overrideModalProps }}
          modalContent={modalContent}
          isVisible={isModalOpen}
          handleCloseModal={closeModal}
        />
      </ModalContext.Provider>
    </ClientOnlyPortal>
  );

  return [modal, openModal, closeModal, isModalOpen];
}
