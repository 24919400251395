import { FC, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Trans } from '@lingui/react';
import { VehicleRentalMenuMobile } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/vehicleRentalMenu/VehicleRentalMenuMobile';
import { OwnersMenuMobile } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/ownersMenu/OwnersMenuMobile';
import { SupportMenuMobile } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/supportMenu/SupportMenuMobile';
import { MobileMenuItemLayout } from './MobileItemMenuLayout';
import { deviceMinWidth } from '~/src/styles/breakpoints';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { Country } from '~/src/utils/routing';
import { useCodebase } from 'features/hooks/useCodebase';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import LocalizedLink from 'features/routing/LocalizedLink';
import freewayRoutesTyped from 'features/routing/routes/freewayRoutesTyped';
import { t } from '@lingui/macro';
import { trackMainMenuLink } from '~/src/utils/dataLayer';

export const MobileMenuList: FC<{ isInSearchPageMenu?: boolean; isHidden?: boolean }> = ({
  isInSearchPageMenu,
  isHidden,
}) => {
  const [maxMenuHeight, setMaxMenuHeight] = useState(() => window.innerHeight);
  const settings = useTypedSelector(settingsSelector);
  const isFWC = useCodebase('fwc');

  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    let mounted = true;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 150 milliseconds
      timeoutId = setTimeout(() => {
        if (mounted) {
          setMaxMenuHeight(window.innerHeight);
        }
      }, 150);
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // prevent memory leak
      mounted = false;
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  const ref = useRef<HTMLDivElement>();

  return (
    <MenuWrapper
      ref={ref}
      maxHeight={maxMenuHeight}
      isInSearchPageMenu={isInSearchPageMenu}
      id="ab_menu_test_menu_items_mobile_new"
    >
      <MobileMenuItemLayout
        localisedText={isFWC ? <Trans message="Campervan" id="menu.campervans" /> : <Trans id="menu.vehicleRental" />}
        isOpenByDefault={true}
        noPaddingSides={true}
      >
        <VehicleRentalMenuMobile />
      </MobileMenuItemLayout>

      {isFWC && (
        <LocalizedLink
          href={freewayRoutesTyped.buyCamper}
          onClick={(e) => {
            if (!('href' in e.currentTarget)) return;
            trackMainMenuLink({
              link_url: e.currentTarget.href,
              text: t({ id: 'menu.buyCamper', message: 'Buy your camper' }),
            });
          }}
        >
          <LinkWrapper>
            <Trans id="menu.buyCamper" message="Buy your camper" />
          </LinkWrapper>
        </LocalizedLink>
      )}

      {settings?.country === Country.cz && !isFWC && (
        <MobileMenuItemLayout localisedText={<Trans id="menu.owners" />}>
          <OwnersMenuMobile />
        </MobileMenuItemLayout>
      )}

      <MobileMenuItemLayout localisedText={<Trans id="tabs.support" />}>
        <SupportMenuMobile />
      </MobileMenuItemLayout>
    </MenuWrapper>
  );
};

const LinkWrapper = styled.div`
  border-top: 1px solid;
  border-bottom: 1px solid;
  margin: 0 ${resolveThemeValue(tokens.spacing.spacingL)};
  margin-top: ${resolveThemeValue(tokens.spacing.spacing2Xl)};
  border-color: ${resolveThemeValue(tokens.color.items.separatorLight)};
  padding: ${resolveThemeValue(tokens.spacing.spacing2Xl)} 0;
  font-weight: 600;
`;

const MenuWrapper = styled.div<{ isInSearchPageMenu?: boolean; maxHeight: number | null }>`
  overflow: auto;

  height: 100vh;

  .isFWC & {
    height: calc(100dvh - 75px);
  }

  .isCAMPIRI & {
    ${({ maxHeight }) =>
      maxHeight &&
      css`
        max-height: calc(${maxHeight}px - 200px);
      `} @media ${deviceMinWidth.phone} {
      max-height: calc(100dvh - 200px);
    }
  }
  ${({ isInSearchPageMenu }) =>
    isInSearchPageMenu &&
    css`
      @media ${deviceMinWidth.phone} {
        height: 100%;
        width: 320px;
      }
    `}
  --scrollbarBG: #ffffff;
  --thumbBG: #e8e8e8;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }

  &.menuAbTestHidden {
    display: none;
  }
`;
