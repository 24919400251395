import { FC } from 'react';

interface FirstTimersIconProps {
  width?: number;
  height?: number;
  fillColor?: string;
}

export const FirstTimersIcon: FC<FirstTimersIconProps> = ({ fillColor = 'currentColor', height = 16, width = 16 }) => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_849_539)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        /* eslint-disable-next-line max-len */
        d="M6.5 0C6.75104 0 6.95887 0.185006 6.99458 0.426114L7 0.5V1.9984L11.5777 1.998C11.9375 1.9978 12.2838 2.12686 12.5549 2.3593L12.653 2.45127L15.5608 5.439C15.8423 5.72033 16.0005 6.10201 16.0005 6.5C16.0005 6.89799 15.8423 7.27967 15.5655 7.55619L12.6527 10.547C12.3702 10.8369 11.9825 11.0002 11.578 11L7 11V15.5C7 15.7761 6.77614 16 6.5 16C6.24896 16 6.04113 15.815 6.00542 15.5739L6 15.5V11L1.5 11C0.711022 11 0.064315 10.3909 0.00451294 9.61722L0 9.5V3.5C0 2.67157 0.671573 2 1.5 2L6 1.9992V0.5C6 0.223858 6.22386 0 6.5 0ZM11.5781 2.998L6.52118 2.99956C6.51415 2.99985 6.50709 3 6.5 3L6.4816 2.9984L1.5001 3C1.24897 3 1.04113 3.18501 1.00542 3.42611L1 3.5V9.5C1 9.77614 1.22386 10 1.5 10H11.5783C11.7132 10.0001 11.8424 9.94562 11.9365 9.84915L14.8539 6.85367C14.9477 6.75989 15.0005 6.63266 15.0005 6.5C15.0005 6.36734 14.9477 6.24011 14.849 6.1414L11.9366 3.149C11.8424 3.05238 11.7132 2.99792 11.5781 2.998ZM7.82925 4.12371C8.01629 4.28737 8.05205 4.5596 7.92477 4.76391L7.87629 4.82925L7.164 5.6424L9.37629 8.17075C9.64149 8.47383 9.45628 8.93747 9.07813 8.99424L9 9H4C3.59727 9 3.37031 8.55531 3.5766 8.23334L3.62371 8.17075L5.8352 5.6424L5.12371 4.82925C4.96005 4.64222 4.96075 4.36765 5.11402 4.18204L5.17075 4.12371C5.35778 3.96005 5.63235 3.96075 5.81796 4.11402L5.87629 4.17075L6.5 4.8832L7.12371 4.17075C7.30555 3.96293 7.62143 3.94187 7.82925 4.12371ZM6.5 6.4016L5.1016 8H7.8976L6.5 6.4016Z"
        fill={fillColor}
      />
    </g>
    <defs>
      <clipPath id="clip0_849_539">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
