import { useContext } from 'react';
import { queries as defaultQueries } from 'ui/utils/breakpoints';
import { BreakpointContext } from 'features/providers/BreakpointProvider';

type queryKeyType = keyof typeof defaultQueries;

export default function useBreakpoints(...queries: queryKeyType[][]): boolean[] {
  const context: any = useContext(BreakpointContext);
  if (!context) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }

  const resolvedQueries = queries.map((breakpoints) =>
    breakpoints.map((breakpoint) => context[breakpoint]).some((val) => val === true)
  );

  return resolvedQueries;
}

export const SMALL_SCREEN_QUERY: queryKeyType[] = ['xs', 'sm'];
export const MEDIUM_AND_SMALL_SCREEN_QUERY: queryKeyType[] = ['xs', 'sm', 'md'];
