/* tslint:disable */
/* eslint-disable */
/**
 * API 1.0
 * REST API for Marketplace
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enum describing the next action required in booking process
 * @export
 * @enum {string}
 */

export const NextBookingAction = {
    Request: 'Request',
    Approve: 'Approve',
    PayDeposit: 'PayDeposit',
    PayBalance: 'PayBalance',
    None: 'None'
} as const;

export type NextBookingAction = typeof NextBookingAction[keyof typeof NextBookingAction];



