import tokens from 'ui/design/tokens/tokens';
import { Box } from 'ui/primitives/box';
import LocalizedLink from 'features/routing/LocalizedLink';
import { makeQueryForSearchFromHP } from 'features/utils/search/utils';
import { Flex } from 'ui/primitives/flex';
import React, { useState } from 'react';
import styled from 'styled-components';
import PrimaryButton from 'ui/components/buttons/PrimaryButton';
import {
  SearchInputValue,
  SearchPlaceholder,
  ValueCleaner as ValueCleanerShared,
} from 'ui/components/searchBar/shared';
import { resolveThemeValue } from 'ui/design/utils';
import DateRangeCalendar, { CalendarInput } from 'ui/components/searchBar/DateRangeCalendar';
import PlaceAutocomplete from 'ui/components/searchBar/PlaceAutocomplete';
import { ValueCleaner } from 'ui/components/searchBar/MapBoxAutocomplete';
import { useDispatch, useSelector } from 'react-redux';
import { searchInfoSelector } from 'features/redux/selectors/search';
import { getPopularPlaces, popularSearches } from '~/src/freeway/utils/popularDefinitions';
import { routes } from '~/src/utils/routing';
import { Trans } from '@lingui/react';
import { useCodebase } from 'features/hooks/useCodebase';

export default function NavSearchBar() {
  const dispatch = useDispatch();
  const [placeError, setPlaceError] = useState(false);
  const searchInfo = useSelector(searchInfoSelector);
  const isFWC = useCodebase('fwc');

  return (
    <Flex $height="40px" $gap={tokens.spacing.spacingS}>
      <InputWrapper
        $maxWidth="360px"
        $border="1px solid"
        $borderColor={tokens.color.border.border}
        $borderRadius={tokens.radius.radiusL}
        $background={tokens.color.backgrounds.surfaceBg}
      >
        <Box $width={isFWC ? '90%' : '100%'} $borderRight="1px solid" $borderColor={tokens.color.border.border}>
          <StyledPlaceAutocomplete
            popularSearches={popularSearches}
            popularPlaces={getPopularPlaces()}
            placeError={placeError}
            setPlaceError={setPlaceError}
          />
        </Box>
        <StyledDateRangeCalendar
          popularPlaces={getPopularPlaces()}
          popularSearches={popularSearches}
          dynamicSegmentRoute={routes.index}
          redirectAfterSelectingBothDate={true}
          onDateChange={(e: any) => {
            dispatch({
              type: 'SET_SEARCH_DATES',
              payload: e.selection,
            });
          }}
          monthsBreakPoint={1400}
        />
      </InputWrapper>
      <Box $maxWidth="105px">
        <LocalizedLink href={routes.search} query={makeQueryForSearchFromHP(searchInfo, {})}>
          <StyledPrimaryButton>
            <Trans id="button.searchCaravan" message="Vyhledat karavan" />
          </StyledPrimaryButton>
        </LocalizedLink>
      </Box>
    </Flex>
  );
}

const StyledPrimaryButton = styled(PrimaryButton)`
  padding: 12px 28px !important;
`;

const InputWrapper = styled(Flex)`
  ${SearchPlaceholder} {
    display: none;
  }

  .icon {
    margin-right: ${resolveThemeValue(tokens.spacing.spacingS)};
    height: 16px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

const StyledDateRangeCalendar = styled(DateRangeCalendar)`
  max-width: 100%;
  padding: ${resolveThemeValue(tokens.spacing.spacingS)} ${resolveThemeValue(tokens.spacing.spacingL)};
  margin-left: 0;

  .isFWC & {
    padding-left: 10px;
  }

  ${SearchInputValue} {
    margin-top: 2px;
    font-size: 14px;
  }

  ${ValueCleanerShared} {
    display: none;
  }

  ${CalendarInput} {
    padding: 0;
  }
`;

const StyledPlaceAutocomplete = styled(PlaceAutocomplete)`
  .isFWC & {
    padding-left: 8px;
    padding-right: 8px;
  }

  input {
    font-size: 14px;
  }

  ${ValueCleaner} {
    display: none;
  }

  padding: ${resolveThemeValue(tokens.spacing.spacingS)} ${resolveThemeValue(tokens.spacing.spacingL)};
  max-width: 100%;
  margin-right: 0;
`;
