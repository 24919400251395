import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { routes } from '~/src/utils/routing';

export const getExperienceCategoriesFWC = () =>
  [
    {
      id: 1,
      name: t({
        id: 'nav.fwc.familyCamping.title',
        message: 'Family camping',
      }),
      image: '/static/images/homepage/nav/family.jpg',
      links: {
        de: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'wohnmobile-fuer-familien',
            id: 576,
          },
        },
        en: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'campers-for-families',
            id: 575,
          },
        },
        it: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'camper-per-famiglia',
            id: 577,
          },
        },
      },
    },
    {
      id: 2,
      name: t({
        id: 'nav.fwc.adventureForTwo.title',
        message: 'Adventure for two',
      }),
      image: '/static/images/homepage/nav/for-two.jpg',
      links: {
        de: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'wohnmobile-fuer-2-personen',
            id: 580,
          },
        },
        en: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'campers-for-2-people',
            id: 578,
          },
        },
        it: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'camper-per-due-persone',
            id: 579,
          },
        },
      },
    },
    {
      id: 3,
      name: t({
        id: 'nav.fwc.campingForBeginners.title',
        message: 'Camping for beginners',
      }),
      image: '/static/images/homepage/nav/begginers.jpg',
      links: {
        de: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'wohnmobile-fuer-anfaenger',
            id: 573,
          },
        },
        en: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'campers-for-beginners',
            id: 572,
          },
        },
        it: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'prima-volta-in-camper',
            id: 574,
          },
        },
      },
    },
    {
      id: 4,
      name: t({
        id: 'nav.fwc.campingWithDog.title',
        message: 'Camping with dog',
      }),
      image: '/static/images/homepage/nav/dog.jpg',
      links: {
        de: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'wohnmobil-mieten-mit-hund',
            id: 267,
          },
        },
        en: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'dog-friendly-rv-rental',
            id: 341,
          },
        },
        it: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'noleggio-camper-con-cane',
            id: 266,
          },
        },
      },
    },
    {
      id: 5,
      name: t({
        id: 'nav.fwc.van.title',
        message: 'Vans & VW',
      }),
      image: '/static/images/homepage/nav/van.jpg',
      links: {
        de: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'bulli-van-mieten',
            id: 257,
          },
        },
        en: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'rent-bulli-van',
            id: 354,
          },
        },
        it: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'noleggio-bulli-van',
            id: 258,
          },
        },
      },
    },
    {
      id: 6,
      name: t({
        id: 'nav.fwc.campervans.title',
        message: 'Campervans',
      }),
      image: '/static/images/homepage/nav/campervan.jpg',
      links: {
        de: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'campervan-mieten',
            id: 260,
          },
        },
        en: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'rent-campervan-class-b',
            id: 351,
          },
        },
        it: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'noleggio-camper-classe-b',
            id: 261,
          },
        },
      },
    },
    {
      id: 7,
      name: t({
        id: 'nav.fwc.rv.title',
        message: "RV's (Class A & C)",
      }),
      image: '/static/images/homepage/nav/rv.jpg',
      links: {
        de: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'wohnmobil-mieten',
            id: 263,
          },
        },
        en: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'rent-rv-class-a-c',
            id: 347,
          },
        },
        it: {
          path: routes.dynamicSegmentNew,
          linkProps: {
            slug: 'noleggio-camper-classe-a-c',
            id: 264,
          },
        },
      },
    },
    {
      id: 8,
      name: t({
        id: 'mainPage.campiriLifestyle.tab.allCamperVans',
      }),
      image: null,
      links: {
        de: {
          path: routes.blogDetail,
          linkProps: {
            slug: 'vermietstationen',
            id: 799,
          },
        },
        en: {
          path: routes.blogDetail,
          linkProps: {
            slug: 'rental-locations',
            id: 680,
          },
        },
        it: {
          path: routes.blogDetail,
          linkProps: {
            slug: 'sedi-di-noleggio',
            id: 879,
          },
        },
      },
    },
  ] as const;

export const getExperienceCategories = () =>
  [
    {
      id: 1,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.familyExperiences' })),
      image: '/static/compressedImages/experiences-menu/family.png',
      searchQuery: 'licenseCategory=NotSet&manufacturer&vehicleType=%5B"NotSet"%2C"Alcove"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/family-blur.jpg',
    },
    {
      id: 2,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.adventureForTwo' })),
      image: '/static/compressedImages/experiences-menu/adventure.png',
      searchQuery: 'vehicleType=%5B%22NotSet%22%2C%22BuiltIn%22%2C%22Campervan%22%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/adventure-blur.jpg',
    },
    {
      id: 3,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.firstWithCaravan' })),
      image: '/static/compressedImages/experiences-menu/first-timer.png',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"BuiltIn"%2C"SemiIntegrated"%2C"Alcove"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/first-timer-blur.jpg',
    },
    {
      id: 4,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.tripWithDog' })),
      image: '/static/compressedImages/experiences-menu/pet-friendly.png',
      searchQuery: 'pets=%5B"Allowed"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/pet-friendly-blur.jpg',
    },
    {
      id: 5,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.luxusOnWheels' })),
      image: '/static/compressedImages/experiences-menu/luxury.png',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"Integrated"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/luxury-blur.jpg',
    },
    {
      id: 6,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.EcologicalTravel' })),
      image: '/static/compressedImages/experiences-menu/eco-travel.png',
      searchQuery:
        'vehicleType=["NotSet","BuiltIn","SemiIntegrated","Trailer"]&solarPanels=true&yearBuildFrom=2018&yearBuildTo=2021',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/eco-travel-blur.jpg',
    },
    {
      id: 7,
      name: i18n._(t({ id: 'mainPage.campiriLifestyle.tab.carTrailers' })),
      image: '/static/compressedImages/experiences-menu/trailers.png',
      searchQuery: 'vehicleType=%5B"NotSet"%2C"Trailer"%5D',
      blurData: '/static/compressedImages/experiences-menu/blur-placeholders/trailers-blur.jpg',
    },
  ] as const;
