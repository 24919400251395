'use client';

import { FlexCenterBoth } from 'ui/primitives/flex';
import React, { ReactNode, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { mediaLgMin, mediaMdMin } from 'ui/utils/breakpoints';
import useOnClickOutside from 'features/hooks/useOnClickOutside';
import useBreakpoints, { SMALL_SCREEN_QUERY } from 'features/hooks/useBreakpoints';
import CloseIcon from 'ui/icons/ic_close.svg';
import { Headline } from 'ui/components/primitives/typography/CustomTypography';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';

type FullScreen = boolean | { height: string };

export interface ModalProps {
  size?: string;
  padding?: string;
  isFullScreen?: FullScreen;
  onClose?: () => void;
  open?: boolean;
  title?: ReactNode;
  roundedAtTop?: boolean;
  allowOverflow?: boolean;
}

interface Props {
  modalProps: ModalProps;
  isVisible: boolean;
  modalContent: ReactNode;
  handleCloseModal: () => void;
}

export default function Modal({ modalProps, modalContent, isVisible, handleCloseModal }: Props) {
  const refOverlay = useRef(null);
  const refContent = useRef(null);
  const refWrapper = useRef(null);

  const [smallScreen] = useBreakpoints(SMALL_SCREEN_QUERY);

  useEffect(() => {
    // disable scroll on body when modal is open
    document.documentElement.classList[isVisible ? 'add' : 'remove']('no-scroll');
  }, [isVisible]);

  useOnClickOutside(refContent, handleCloseModal);

  return (
    <>
      <CSSTransition in={isVisible} classNames="overlay" nodeRef={refOverlay} unmountOnExit mountOnEnter timeout={300}>
        <OverLay ref={refOverlay} />
      </CSSTransition>
      <CSSTransition nodeRef={refWrapper} in={isVisible} classNames="content" unmountOnExit mountOnEnter timeout={300}>
        <ContentWrapper isFullScreen={modalProps.isFullScreen ?? smallScreen} ref={refWrapper}>
          <Content
            roundedAtTop={modalProps.roundedAtTop}
            ref={refContent}
            allowOverflow={modalProps.allowOverflow}
            isFullScreen={modalProps.isFullScreen ?? smallScreen}
            padding={modalProps.padding}
            size={modalProps.size}
          >
            {modalProps.title && (
              <ModalWrapper>
                <StyledClose
                  height={20}
                  width={20}
                  onClick={() => {
                    handleCloseModal();
                  }}
                />
                <Headline $tAlign="center" $size="h5">
                  {modalProps.title}
                </Headline>
              </ModalWrapper>
            )}
            {modalContent}
          </Content>
        </ContentWrapper>
      </CSSTransition>
    </>
  );
}

const ModalWrapper = styled.div`
  border-bottom: 1px solid #d0d5ddff;
  position: relative;
  padding: 20px 16px;
`;

const StyledClose = styled(CloseIcon)`
  position: absolute;
  cursor: pointer;
`;

const Content = styled.div<{
  size?: string;
  padding?: string;
  isFullScreen?: FullScreen;
  roundedAtTop?: boolean;
  allowOverflow?: boolean;
}>`
  z-index: 1001;

  width: calc(100vw - 30px);
  border-radius: 16px;
  position: fixed;
  overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden')};
  background: white;
  bottom: auto;

  ${({ isFullScreen }) =>
    isFullScreen &&
    css`
      border-radius: 0;
      width: 100vw;
      bottom: 0;
      height: ${typeof isFullScreen === 'object' ? isFullScreen.height : '100%'};
    `}
  ${({ roundedAtTop }) =>
    roundedAtTop &&
    css`
      max-height: calc(100% - 10dvh);
      border-radius: 16px 16px 0px 0px;
      bottom: 0;
    `}
  ${mediaMdMin} {
    padding: ${({ padding }) => padding || '20px'};
    height: auto;
    width: auto;
    border-radius: 16px;
  }
`;

const ContentWrapper = styled(FlexCenterBoth)<{ roundedAtTop?: boolean; isFullScreen: FullScreen }>`
  z-index: 1003;
  position: fixed;
  height: 100dvh;
  width: 100vw;
  max-height: 100dvh;
  background: transparent;
  height: -webkit-fill-available;
  -webkit-overflow-scrolling: touch;

  ${mediaLgMin} {
    &.content-enter {
      right: 2000px;
    }

    &.content-enter-active {
      right: 0;
      transition: right 300ms ease-in-out;
    }

    &.content-exit {
      right: 0;
    }

    &.content-exit-active {
      right: -2000px;
      transition: right 300ms ease-in-out;
    }
  }
`;

const OverLay = styled.div`
  position: fixed;
  z-index: 1002;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0.8;
  background: ${resolveThemeValue(tokens.color.backgrounds.imageOverlay, '#00162966')};

  &.overlay-enter {
    opacity: 0;
  }

  &.overlay-enter-active {
    opacity: 0.8;
    transition: opacity 300ms ease-in-out;
  }

  &.overlay-exit {
    opacity: 0.8;
  }

  &.overlay-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
  }
`;
