import { FC, useEffect } from 'react';
import { Trans } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { MenuDescriptionLink } from '../MenuDescriptionLink';
import { SummerSpainIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/special-offers-icons/SummerSpainIcon';
import { VouchersIcon } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/special-offers-icons/VouchersIcon';
import { GridContainer, MenuContentHeading, MenuContentWrapper } from '../menu.styles';

import { ExperienceGrid } from './ExperienceGrid';
import { Language, routes } from '~/src/utils/routing';
import { languageSelector } from '~/src/redux/selectors/settings';
import { FadeSlideVariant } from '../grid.animation';
import { GoogleReviews } from './GoogleReviews';
import { FlagImage } from './rentalMenu.styles';
import { SummerPortugalIcon } from '../../../../icons-and-images/navigation-menu-icons/special-offers-icons/SummerPortugalIcon';
import { useCodebase } from 'features/hooks/useCodebase';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import AdsIcon from 'ui/icons/ic_ads.svg';
import BeachIcon from 'ui/icons/ic_beach.svg';
import TimeIcon from 'ui/icons/ic_time.svg';
import ExtensionCordIcon from 'ui/icons/ic_extension_cord.svg';
import ForestIcon from 'ui/icons/ic_forest.svg';
import { trackMainMenu } from '~/src/utils/dataLayer';
import { t } from '@lingui/macro';
import { FlexCol } from 'ui/primitives/flex';

export const VehicleRentalMenuDesktop: FC = () => {
  const language = useSelector(languageSelector);
  const isFWC = useCodebase('fwc');

  useEffect(() => {
    if (isFWC) trackMainMenu({ text: t({ id: 'menu.campers' }) });
    else trackMainMenu({ text: t({ id: 'menu.vehicleRental' }) });
  }, []);

  return (
    <GridContainer
      variants={FadeSlideVariant}
      initial="prepare"
      animate="animate"
      exit="exit"
      transition={{ type: 'tween' }}
      onlyOneColumn={language !== 'cs' && language !== 'pl' && !isFWC}
    >
      <MenuContentWrapper isLarge={true}>
        <MenuContentHeading>
          <Trans id="menu.experiences" message="Experiences" />
        </MenuContentHeading>
        <ExperienceGrid />
      </MenuContentWrapper>
      {isFWC && (
        <MenuWrapper isBackgroundFilled={true} isDividerDisplayedAtMobileView={true}>
          <CustomMenuContentHeading>
            <Trans id="menu.specialOffers" message="Special Offers" />
          </CustomMenuContentHeading>
          <FlexCol $gap={tokens.spacing.spacingXl}>
            <MenuDescriptionLink
              links={{
                [Language.en]: {
                  href: routes.dynamicSegmentNew,
                  linkParams: {
                    slug: 'summer-special',
                    id: 689,
                  },
                },
                [Language.de]: {
                  href: routes.dynamicSegmentNew,
                  linkParams: {
                    slug: 'summer-special',
                    id: 688,
                  },
                },
                [Language.it]: {
                  href: routes.dynamicSegmentNew,
                  linkParams: {
                    slug: 'summer-special',
                    id: 691,
                  },
                },
              }}
              boldText={<Trans id="menu.fwc.specialoffer.title.option1" message="10% discount on all campers" />}
              leftIcon={<AdsIcon height={20} />}
              additionalText={
                <Trans
                  id="menu.fwc.specialoffer.text.option1" // switch between option 1 and option 2
                  message="With the code SUNNY10, you save 10% on all trips in 2024"
                />
              }
            />
            <MenuDescriptionLink
              links={{
                [Language.en]: {
                  href: routes.blogDetail,
                  linkParams: {
                    slug: 'long-term-rental',
                    id: 536,
                  },
                },
                [Language.de]: {
                  href: routes.blogDetail,
                  linkParams: {
                    slug: 'langzeitmiete',
                    id: 537,
                  },
                },
                [Language.it]: {
                  href: routes.blogDetail,
                  linkParams: {
                    slug: 'noleggio-lungo-termine',
                    id: 538,
                  },
                },
              }}
              boldText={<Trans id="menu.fwc.specialDeals" message="Special deals" />}
              leftIcon={<BeachIcon height={20} />}
              additionalText={<Trans id="menu.fwc.specialDeals.description" message="Find our best deals" />}
            />
            <MenuDescriptionLink
              links={{
                [Language.de]: {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 558,
                    slug: 'fruehbucher',
                  },
                },
                [Language.en]: {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 557,
                    slug: 'early-booking',
                  },
                },
                [Language.it]: {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 559,
                    slug: 'prenotazioni-anticipate',
                  },
                },
              }}
              boldText={<Trans id="menu.fwc.photoChallenge" message="Photo challenge!" />}
              leftIcon={<TimeIcon height={20} />}
            />
            <MenuDescriptionLink
              boldText={<Trans id="menu.fwc.extrasAndServices" message="Extras and services" />}
              links={{
                'de-de': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 546,
                    slug: 'unsere-zusatzservices',
                  },
                },
                'en-de': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 545,
                    slug: 'our-additional-services',
                  },
                },
                'en-it': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 919,
                    slug: 'additional-services',
                  },
                },
                'it-it': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 547,
                    slug: 'attrezzatura-e-servizi',
                  },
                },
              }}
              leftIcon={<ExtensionCordIcon height={20} />}
            />
            <MenuDescriptionLink
              links={{
                'de-de': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 549,
                    slug: 'klimabewusst-reise',
                  },
                },
                'en-de': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 548,
                    slug: 'climate-conscious-travel',
                  },
                },
                'en-it': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 920,
                    slug: 'climate-conscious',
                  },
                },
                'it-it': {
                  href: routes.blogDetail,
                  linkParams: {
                    id: 550,
                    slug: 'attenti-all-ambiente',
                  },
                },
              }}
              boldText={<Trans id="menu.fwc.climate" message="Climate conscious travel" />}
              leftIcon={<ForestIcon height={20} />}
            />
          </FlexCol>
          <GoogleReviews />
        </MenuWrapper>
      )}

      {language === 'cs' && (
        <MenuWrapper isBackgroundFilled={true} isDividerDisplayedAtMobileView={true}>
          <MenuContentHeading>
            <Trans id="menu.specialOffers" message="Special Offers" />
          </MenuContentHeading>
          <FlexCol $gap={tokens.spacing.spacingXl}>
            <MenuDescriptionLink
              href={routes.superGiveawayJuneLP}
              boldText="Letní Soutěž"
              leftIcon={<AdsIcon height={20} />}
              additionalText="Vyhrajte letní dovolenou v obytném autě v hodnotě 10 000 Kč!"
            />

            <MenuDescriptionLink
              href={routes.campiriSpain}
              boldText={
                <>
                  <Trans id="menu.spain" message="Spain" />
                  <FlagImage src="/static/images/experiences-menu/Spain.svg" alt="spain flag" />
                </>
              }
              leftIcon={<SummerSpainIcon />}
              additionalText={
                <Trans
                  id="menu.spain.additional"
                  message="Beaches, mountains, cities and remote villages. We are opening the Campiri base in Málaga!"
                />
              }
            />

            {language === Language.cs && (
              <MenuDescriptionLink
                href={routes.campiriPortugal}
                boldText={
                  <>
                    Portugalsko
                    <FlagImage src="/static/images/experiences-menu/Portugal1.svg" alt="portuguese flag" />
                  </>
                }
                leftIcon={<SummerPortugalIcon />}
                additionalText="Malá země s pestrou historií, malebnou krajinou a velkým bohatstvím."
              />
            )}

            {/* <MenuDescriptionLink
            href={routes.voucher}
            externalURL="https://www.campiri.com/cs-cz/za-zimnimi-radovankami-karavanem--clanek-330"
            boldText={<>Zimní dovolená v karavanu</>}
            leftIcon={<SnowIcon />}
            additionalText="Vyjeďte na hory v obytném voze. Poradíme, jak na to."
          /> */}
            <MenuDescriptionLink
              externalURL="https://shop.campiri.com/"
              href={null}
              boldText="Vouchery"
              leftIcon={<VouchersIcon />}
            />
          </FlexCol>
          <GoogleReviews />
        </MenuWrapper>
      )}

      {language === 'pl' && (
        <MenuWrapper isBackgroundFilled={true} isDividerDisplayedAtMobileView={true}>
          <MenuContentHeading>
            <Trans id="menu.specialOffers" message="Special Offers" />
          </MenuContentHeading>
          <FlexCol $gap={tokens.spacing.spacingXl}>
            <MenuDescriptionLink
              href={routes.campiriSpain}
              boldText={
                <>
                  <Trans id="menu.spain" message="Spain" />
                  <FlagImage src="/static/images/experiences-menu/Spain.svg" alt="spain flag" />
                </>
              }
              leftIcon={<SummerSpainIcon />}
              additionalText={
                <Trans
                  id="menu.spain.additional"
                  message="Beaches, mountains, cities and remote villages. We are opening the Campiri base in Málaga!"
                />
              }
            />
          </FlexCol>
          <GoogleReviews />
        </MenuWrapper>
      )}
    </GridContainer>
  );
};

const CustomMenuContentHeading = styled(MenuContentHeading)`
  margin-bottom: 0;
`;

const MenuWrapper = styled(MenuContentWrapper)`
  display: flex;
  flex-direction: column;

  .isFWC & {
    gap: ${resolveThemeValue(tokens.spacing.spacingXl)};
  }
`;
