import React from 'react';
import { Trans } from '@lingui/react';
import styled from 'styled-components';
import { useGoogleReviews } from '~/src/components/homepage/useGoogleReviews';
import ImageGoogleLogo from '~/src/components/layout/ImageGoogleLogo';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { useCodebase } from 'features/hooks/useCodebase';
import { Box } from 'ui/primitives/box';

interface Props {
  spanishLPVersion?: boolean;
}

const GoogleComponent = () => {
  const isFWC = useCodebase('fwc');

  return (
    <ReviewsHeadingWrapper>
      <Logo>
        <ImageGoogleLogo />
      </Logo>{' '}
      {!isFWC && <VL />}
      {!isFWC && (
        <ReviewsText>
          <Trans id="footer.section.review" message="Recenze" />
        </ReviewsText>
      )}
    </ReviewsHeadingWrapper>
  );
};

export const GoogleReviews = ({ spanishLPVersion }: Props) => {
  const { specialOfferBarVisible, reviewsLink, reviewScore } = useGoogleReviews();

  const isFWC = useCodebase('fwc');

  return (
    <Wrapper className={spanishLPVersion ? 'spanishLPVersion' : ''}>
      <ContentWrapper>
        {isFWC ? (
          <GoogleComponent />
        ) : (
          <a href={reviewsLink} target="_blank" rel="noreferrer">
            <GoogleComponent />
          </a>
        )}
        {isFWC ? (
          <Box $ml="auto">
            <StarsWrapper>
              <Star className="icon icon-favorite-active" />
              <StarsNumber>{reviewScore}</StarsNumber>
              <div className="textWrap">
                <Trans id="header.fwc.reviews" message="(3000 reviews)" />
              </div>
            </StarsWrapper>
          </Box>
        ) : (
          <a href={reviewsLink} target="_blank" rel="noreferrer">
            <StarsWrapper>
              <Star className="icon icon-favorite-active" />
              <StarsNumber>{reviewScore}</StarsNumber>
            </StarsWrapper>
          </a>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  font-size: 16px;
  margin-top: auto;

  &.spanishLPVersion {
    margin-bottom: 50px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
`;

const ReviewsText = styled.span`
  font-weight: 600;
  .isFWC & {
    color ${resolveThemeValue(tokens.color.text.textSecondary)}
  }
`;

const Logo = styled.div`
  height: 20px;
  width: 59px;
`;

const VL = styled.div`
  margin: 0 14px;
  height: 20px;
  width: 1px;
  background-color: var(--color-dark-grey);
`;

const StarsNumber = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-top: 2px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${resolveThemeValue(tokens.color.text.textSecondary, '#1f2244')};
  .isCAMPIRI & {
    margin-right: 14px;
  }
`;

const StarsWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  gap: 5px;

  .textWrap {
    padding-top: 2px;
    color: ${resolveThemeValue(tokens.color.text.textSecondary)};
  }
`;

const ReviewsHeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-dark-blue);
  font-size: 16px;
`;

const Star = styled.i`
  font-size: 16px;
  margin: 0 2px;
  color: ${resolveThemeValue(tokens.color.text.textLink, 'var(--color-green)')};
  cursor: pointer;
`;
