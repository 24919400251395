import getConfig from 'next/config';
import { PictureModel } from '~/libs/api/models/picture-model';
import {
  generatePreload,
  getImageRequest,
  getLayoutFillNextImage,
  getNextImageElement,
  myLoader,
  placeholderImage,
} from 'features/utils/images';

const { publicRuntimeConfig } = getConfig();

if (typeof btoa === 'undefined') {
  global.btoa = (str) => Buffer.from(str, 'binary').toString('base64');
}

const getHashedImageSrc = (imageObj: PictureModel, desiredWidth?: number, desiredHeight?: number) => {
  const { host, name, extension } = imageObj;

  if (host === null || name === null || extension === null) {
    return '';
  }

  const src = `${name}.${extension}`;

  const imageRequest = getImageRequest(src, desiredWidth, desiredHeight);

  return `${publicRuntimeConfig.IMAGES_CLOUDFRONT_HOST}${btoa(imageRequest)}`;
};

export { placeholderImage, getNextImageElement, getHashedImageSrc, getLayoutFillNextImage, generatePreload, myLoader };
