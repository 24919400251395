import { PlaceType, SearchLocationType } from 'ui/components/searchBar/utils/utils';
import { Country } from 'features/utils/routing';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

export const BERLIN_CENTER = {
  lat: 52.52,
  lng: 13.405,
};
export const popularSearches: PlaceType = {
  [Country.it]: [
    {
      name: 'test',
      title: 'title',
      type: SearchLocationType.City,
      description: 'dsds',
      popupText: 'dsds',
    },
  ],
  [Country.de]: [
    {
      name: 'test',
      title: 'title',
      type: SearchLocationType.City,
      description: 'dsds',
      popupText: 'dsds',
    },
  ],
  [Country.cz]: [],
  [Country.sk]: [],
  [Country.es]: [],
  [Country.pl]: [],
};

export const getPopularPlaces = () =>
  ({
    // [Country.de]: [
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Munich', message: 'Munich' })),
    //     title: i18n._(t({ id: 'popularPlaces.Munich', message: 'Munich' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Berlin', message: 'Berlin' })),
    //     title: i18n._(t({ id: 'popularPlaces.Berlin', message: 'Berlin' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Frankfurt', message: 'Frankfurt' })),
    //     title: i18n._(t({ id: 'popularPlaces.Frankfurt', message: 'Frankfurt' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Stuttgart', message: 'Stuttgart' })),
    //     title: i18n._(t({ id: 'popularPlaces.Stuttgart', message: 'Stuttgart' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Cologne', message: 'Cologne' })),
    //     title: i18n._(t({ id: 'popularPlaces.Cologne', message: 'Cologne' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Hamburg', message: 'Hamburg' })),
    //     title: i18n._(t({ id: 'popularPlaces.Hamburg', message: 'Hamburg' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Milan', message: 'Milan' })),
    //     title: i18n._(t({ id: 'popularPlaces.Milan', message: 'Milan' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Sardinia', message: 'Sardinia' })),
    //     title: i18n._(t({ id: 'popularPlaces.Sardinia', message: 'Sardinia' })),
    //     type: SearchLocationType.City,
    //   },
    // ],
    // [Country.it]: [
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Milan', message: 'Milan' })),
    //     title: i18n._(t({ id: 'popularPlaces.Milan', message: 'Milan' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Rome', message: 'Rome' })),
    //     title: i18n._(t({ id: 'popularPlaces.Rome', message: 'Rome' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Turin', message: 'Turin' })),
    //     title: i18n._(t({ id: 'popularPlaces.Turin', message: 'Turin' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Modena', message: 'Modena' })),
    //     title: i18n._(t({ id: 'popularPlaces.Modena', message: 'Modena' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Vicenza', message: 'Vicenza' })),
    //     title: i18n._(t({ id: 'popularPlaces.Vicenza', message: 'Vicenza' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Sardinia', message: 'Sardinia' })),
    //     title: i18n._(t({ id: 'popularPlaces.Sardinia', message: 'Sardinia' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Matera', message: 'Matera' })),
    //     title: i18n._(t({ id: 'popularPlaces.Matera', message: 'Matera' })),
    //     type: SearchLocationType.City,
    //   },
    //   {
    //     name: i18n._(t({ id: 'popularPlaces.Monaco', message: 'Monaco' })),
    //     title: i18n._(t({ id: 'popularPlaces.Monaco', message: 'Monaco' })),
    //     type: SearchLocationType.City,
    //   },
    // ],
    [Country.es]: [],
    [Country.cz]: [],
    [Country.sk]: [],
    [Country.pl]: [],
  }) as PlaceType;
