import React, { FC } from 'react';
import styled from 'styled-components';
import { Trans } from '@lingui/react';
import Image from 'next/legacy/image';
import { FilterCard, ImageContainer } from './rentalMenu.styles';
import { deviceMinWidth } from '~/src/styles/breakpoints';
import {
  getExperienceCategories,
  getExperienceCategoriesCS,
} from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/vehicleRentalMenu/experienceCategories';
import LocalizedLink from '~/src/components/LocalizedLink';
import { Country, Language, routes } from '~/src/utils/routing';
import { useTypedSelector } from '~/src/redux/store';
import { settingsSelector } from '~/src/redux/selectors/settings';
import { useCodebase } from 'features/hooks/useCodebase';
import { getExperienceCategoriesFWC } from '~/src/components/layout/headers/navigation-menu/V2/navigationItems/vehicleRentalMenu/fwcExperienceCategories';
import { trackMainMenuLink } from '~/src/utils/dataLayer';

export const ExperienceGrid: FC = () => {
  const settings = useTypedSelector(settingsSelector);
  const isCS = settings.country === Country.cz && settings.language === Language.cs;
  const isFWC = useCodebase('fwc');

  if (isFWC) {
    return (
      <ExperiencesGridContainer>
        {getExperienceCategoriesFWC().map((category) => {
          const link = category.links?.[settings.language];

          if (!link) return null;

          return (
            <LocalizedLink
              key={category.id}
              linkParams={link.linkProps}
              href={link.path}
              onClick={(e) => trackMainMenuLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText })}
              legacyBehavior={false}
              forceLegacyFlag={true}
            >
              <FilterCard hasBackgroundImage={!!category.image} data-bg-image={!!category.image}>
                {category.image && (
                  <ImageContainer>
                    <Image src={category.image} objectFit="cover" layout="fill" alt={category.name} />
                  </ImageContainer>
                )}
                <p>
                  <Trans id={category.name} />
                </p>
              </FilterCard>
            </LocalizedLink>
          );
        })}
      </ExperiencesGridContainer>
    );
  }

  return (
    <ExperiencesGridContainer>
      {(isCS ? getExperienceCategoriesCS() : getExperienceCategories()).map((category) => (
        <LocalizedLink
          key={category.id}
          linkParams={
            isCS
              ? {
                  slug: category.slug,
                  id: category.dynamicSegmentId,
                }
              : {}
          }
          query={category?.searchQuery}
          href={isCS ? routes.dynamicSegmentNew : routes.search}
          legacyBehavior={false}
          forceLegacyFlag={true}
          onClick={(e) => trackMainMenuLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText })}
        >
          <FilterCard as="div" hasBackgroundImage={true}>
            <ImageContainer>
              <Image
                src={category.image}
                objectFit="cover"
                layout="fill"
                placeholder="blur"
                blurDataURL={category.blurData}
                alt={category.name}
              />
            </ImageContainer>
            <p>
              <Trans id={category.name} />
            </p>
          </FilterCard>
        </LocalizedLink>
      ))}
      <LocalizedLink href={routes.search}>
        <FilterCard data-bg-image={false}>
          <p>
            <Trans id="mainPage.campiriLifestyle.tab.allCamperVans" message="All campervans" />
          </p>
        </FilterCard>
      </LocalizedLink>
    </ExperiencesGridContainer>
  );
};

const ExperiencesGridContainer = styled.div`
  display: none;

  @media ${deviceMinWidth.phone} {
    display: grid;
    grid-template-columns: repeat(2, 160px);
    gap: 8px;
  }
`;
