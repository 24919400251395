/* eslint-disable max-len */
import { FC } from 'react';
import { NavigationMenuIconProps } from '~/src/components/layout/headers/icons-and-images/navigation-menu-icons/types';

export const SummerPortugalIcon: FC<NavigationMenuIconProps> = ({
  fillColor = 'currentColor',
  height = 16,
  width = 16,
}) => (
  <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.48596 12.0056C6.66806 12.0058 6.84098 12.0855 6.95952 12.2237C7.33562 12.6623 7.80214 13.0143 8.32708 13.2557C8.85201 13.4971 9.42294 13.622 10.0007 13.622C10.5785 13.622 11.1494 13.4971 11.6743 13.2557C12.1993 13.0143 12.6658 12.6623 13.0419 12.2237C13.1605 12.0854 13.3335 12.0058 13.5157 12.0056C13.6978 12.0054 13.871 12.0847 13.9899 12.2227C14.5397 12.8611 15.2759 13.311 16.0948 13.5091C16.9137 13.7072 17.7742 13.6436 18.555 13.3271C18.8749 13.1975 19.2394 13.3517 19.369 13.6716C19.4987 13.9915 19.3444 14.3559 19.0245 14.4856C18.0014 14.9002 16.8739 14.9837 15.8009 14.7241C14.9486 14.5179 14.1646 14.1035 13.5171 13.5225C13.1242 13.8762 12.6792 14.1695 12.1965 14.3914C11.5078 14.7081 10.7587 14.872 10.0007 14.872C9.24267 14.872 8.4936 14.7081 7.80488 14.3914C7.32209 14.1694 6.87695 13.8761 6.48394 13.5223C5.83596 14.1029 5.05156 14.5168 4.1989 14.7223C3.12561 14.9809 1.99822 14.8964 0.975484 14.4806C0.655719 14.3506 0.50188 13.986 0.631874 13.6663C0.761868 13.3465 1.12647 13.1927 1.44623 13.3227C2.22669 13.6399 3.087 13.7044 3.90603 13.507C4.72507 13.3097 5.46155 12.8603 6.01179 12.2224C6.13072 12.0845 6.30387 12.0053 6.48596 12.0056Z"
      fill="#1F2244"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.48495 15.3759C6.66712 15.3761 6.84015 15.4557 6.95874 15.594C7.33484 16.0326 7.80136 16.3847 8.32629 16.626C8.85123 16.8674 9.42216 16.9924 9.99992 16.9924C10.5777 16.9924 11.1486 16.8674 11.6735 16.626C12.1985 16.3847 12.665 16.0326 13.0411 15.594C13.1597 15.4557 13.3327 15.3761 13.5149 15.3759C13.697 15.3757 13.8702 15.455 13.9891 15.593C14.539 16.2314 15.2751 16.6813 16.094 16.8794C16.913 17.0775 17.7734 17.0139 18.5542 16.6974C18.8741 16.5678 19.2386 16.722 19.3682 17.0419C19.4979 17.3618 19.3436 17.7262 19.0237 17.8559C18.0006 18.2706 16.8732 18.354 15.8001 18.0944C14.9478 17.8882 14.1639 17.4738 13.5164 16.8929C13.1234 17.2465 12.6784 17.5398 12.1957 17.7617C11.507 18.0784 10.758 18.2424 9.99992 18.2424C9.24189 18.2424 8.49282 18.0784 7.8041 17.7617C7.32144 17.5398 6.87642 17.2465 6.4835 16.8929C5.83599 17.4739 5.05199 17.8882 4.19959 18.0943C3.12646 18.3538 1.99896 18.2701 0.975896 17.855C0.656039 17.7253 0.50194 17.3608 0.631706 17.0409C0.761472 16.7211 1.12596 16.567 1.44582 16.6967C2.22653 17.0135 3.08692 17.0773 3.90583 16.8793C4.72475 16.6813 5.46093 16.2314 6.0107 15.593C6.12958 15.455 6.30277 15.3757 6.48495 15.3759Z"
      fill="#1F2244"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99992 6.52353C8.92248 6.52353 7.88917 6.95154 7.1273 7.71341C6.36543 8.47527 5.93742 9.50859 5.93742 10.586C5.93742 10.9312 5.6576 11.211 5.31242 11.211C4.96724 11.211 4.68742 10.9312 4.68742 10.586C4.68742 9.17707 5.24713 7.82581 6.24342 6.82952C7.2397 5.83324 8.59096 5.27353 9.99992 5.27353C11.4089 5.27353 12.7601 5.83324 13.7564 6.82952C14.7527 7.82581 15.3124 9.17707 15.3124 10.586C15.3124 10.9312 15.0326 11.211 14.6874 11.211C14.3422 11.211 14.0624 10.9312 14.0624 10.586C14.0624 9.50859 13.6344 8.47527 12.8725 7.71341C12.1107 6.95154 11.0774 6.52353 9.99992 6.52353Z"
      fill="#1F2244"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.58664 10.586C0.58664 10.2409 0.866462 9.96103 1.21164 9.96103H2.96945C3.31463 9.96103 3.59445 10.2409 3.59445 10.586C3.59445 10.9312 3.31463 11.211 2.96945 11.211H1.21164C0.866462 11.211 0.58664 10.9312 0.58664 10.586Z"
      fill="#1F2244"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.407 10.586C16.407 10.2409 16.6868 9.96103 17.032 9.96103H18.7898C19.1349 9.96103 19.4148 10.2409 19.4148 10.586C19.4148 10.9312 19.1349 11.211 18.7898 11.211H17.032C16.6868 11.211 16.407 10.9312 16.407 10.586Z"
      fill="#1F2244"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0007 1.7579C10.3459 1.7579 10.6257 2.03773 10.6257 2.3829V4.14072C10.6257 4.48589 10.3459 4.76572 10.0007 4.76572C9.65552 4.76572 9.3757 4.48589 9.3757 4.14072V2.3829C9.3757 2.03773 9.65552 1.7579 10.0007 1.7579Z"
      fill="#1F2244"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.302 4.28471C16.5461 4.52879 16.5461 4.92452 16.302 5.1686L15.1301 6.34047C14.8861 6.58455 14.4903 6.58455 14.2463 6.34047C14.0022 6.09639 14.0022 5.70066 14.2463 5.45659L15.4181 4.28471C15.6622 4.04063 16.0579 4.04063 16.302 4.28471Z"
      fill="#1F2244"
    />
    <path
      d="M5.31323 5.89853L4.14136 4.72665"
      stroke="#1F2244"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
