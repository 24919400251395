import React, { FC, ReactElement } from 'react';
import styled, { css } from 'styled-components';

import LocalizedLink, { LocalizedLinkProps } from '~/src/components/LocalizedLink';
import { resolveThemeValue } from 'ui/design/utils';
import tokens from 'ui/design/tokens/tokens';
import { Country, Language } from 'features/utils/routing';
import { useSelector } from 'react-redux';
import { settingsSelector } from 'features/redux/selectors/settings';
import { RouteType } from 'features/routing/types';
import { routes } from '~/src/utils/routing';
import { trackMainMenuLink } from '~/src/utils/dataLayer';

interface MenuDescriptionLinkProps extends Pick<LocalizedLinkProps, 'newWindow' | 'hash'> {
  leftIcon?: ReactElement;
  boldText: ReactElement | string;
  additionalText?: string | ReactElement;
  isDisplayedAsMobileVersion?: boolean;
  externalURL?: string;
  dataTestId?: string;
  href?: RouteType;
  links?: { [key in Language | `${Language}-${Country}`]?: { href: RouteType; linkParams?: any } };
}

export const MenuDescriptionLink: FC<MenuDescriptionLinkProps> = ({
  leftIcon,
  boldText,
  additionalText,
  isDisplayedAsMobileVersion,
  href,
  hash,
  newWindow,
  externalURL,
  links,
  dataTestId = `${'menu'}${href?.template.replaceAll('/', '-')}`,
}) => {
  const settings = useSelector(settingsSelector);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    trackMainMenuLink({ link_url: e.currentTarget.href, text: e.currentTarget.innerText });
  };

  if (externalURL) {
    return (
      <LinkContainer
        onClick={handleClick}
        data-testid={dataTestId}
        isDisplayedAsMobileVersion={isDisplayedAsMobileVersion}
        href={externalURL}
        target={newWindow ? '_blank' : ''}
      >
        {leftIcon && <LeftIconContainer>{leftIcon}</LeftIconContainer>}
        <TextContainer>
          <LinkText className="linkText" isDisplayedAsMobileVersion={isDisplayedAsMobileVersion}>
            {boldText}
          </LinkText>
          {additionalText && <AdditionalText className="additionalText">{additionalText}</AdditionalText>}
        </TextContainer>
      </LinkContainer>
    );
  }

  if (!links && !href) throw new Error('Please specify a links or href prop');

  const finalHref = !links
    ? href
    : links?.[settings.language]?.href || links?.[`${settings.language}-${settings.country}`]?.href || routes.index;

  return (
    <LocalizedLink
      onClick={handleClick}
      href={finalHref}
      hash={hash}
      linkParams={
        links?.[settings.language]?.linkParams || links?.[`${settings.language}-${settings.country}`]?.linkParams || {}
      }
      legacyBehavior={false}
      forceLegacyFlag={true}
      newWindow={newWindow}
      passHref={true}
    >
      <LinkContainer as="div" isDisplayedAsMobileVersion={isDisplayedAsMobileVersion} data-testid={dataTestId}>
        {leftIcon && <LeftIconContainer>{leftIcon}</LeftIconContainer>}
        <TextContainer>
          <LinkText className="linkText" isDisplayedAsMobileVersion={isDisplayedAsMobileVersion}>
            {boldText}
          </LinkText>
          {additionalText && <AdditionalText className="additionalText">{additionalText}</AdditionalText>}
        </TextContainer>
      </LinkContainer>
    </LocalizedLink>
  );
};

const LinkContainer = styled.a<{ isDisplayedAsMobileVersion?: boolean }>`
  display: flex;
  margin-bottom: 0 !important;
  color: currentColor;
  svg {
    height: 20px;
    width: 20px;
  }

  ${({ isDisplayedAsMobileVersion }) =>
    isDisplayedAsMobileVersion &&
    css`
      padding: 0px !important;
      svg {
        width: 16px;
        height: 16px;
      }
    `}

  :hover {
    text-decoration: none !important;
  }
`;

const LeftIconContainer = styled.div`
  margin-right: 12px;
`;

const LinkText = styled.p<{ isDisplayedAsMobileVersion?: boolean }>`
  display: flex;
  align-items: start;
  width: fit-content;

  ${({ isDisplayedAsMobileVersion }) =>
    isDisplayedAsMobileVersion &&
    css`
      font-size: 14px !important;
    `}

  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  position: relative;
  cursor: pointer;
  color: #1f2244;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #1f2244;
    transform: scaleX(0);
    transition: transform 0.2s ease-out;
  }

  :hover {
    ::before {
      transform: scaleX(1);
      transition: transform 0.2s ease-in;
    }
  }
`;

const AdditionalText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0;
  text-align: left;
  color: ${resolveThemeValue(tokens.color.text.textSecondary, '#8e7e7f')};
  margin-top: 8px;
`;

const TextContainer = styled.div`
  transition: transform 0.2s ease-in;
  :hover .additionalText {
    color: #1f2244 !important;
  }

  :hover .linkText {
    ::before {
      transform: scaleX(1);
    }
  }
`;
