/* eslint-disable max-len */
const ImageLogin = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <g fill="#EDEAE3">
        <g>
          <g>
            <path
              d="M14.5.5c.414.01.806.187 1.089.49.279.297.427.694.411 1.102v11.837c.028.832-.637 1.54-1.482 1.57H6.5l-.002.001c-.787-.002-1.44-.61-1.497-1.395L5 14.067V12c0-.276.224-.5.5-.5s.5.224.5.5v2.044c.028.258.243.453.505.456H14.5c.281-.01.51-.26.5-.555V2.074c.01-.148-.043-.294-.143-.403-.097-.105-.232-.167-.374-.172H6.519c-.148.014-.285.084-.383.197-.102.116-.153.267-.142.42l.003.03.003.06V4c0 .276-.224.5-.5.5S5 4.276 5 4V2.232l-.004-.05c-.029-.417.11-.83.387-1.144C5.665.713 6.069.518 6.5.5h8zm-12 5.003c1.189-.002 2.213.835 2.45 2h7.55c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1.5h-1v1.5c0 .276-.224.5-.5.5s-.5-.224-.5-.5v-1.5H4.95c-.237 1.164-1.261 2-2.45 2-1.378 0-2.5-1.122-2.5-2.5 0-1.379 1.122-2.5 2.5-2.5zm0 1c-.827 0-1.5.672-1.5 1.5 0 .827.673 1.5 1.5 1.5S4 8.83 4 8.003c0-.828-.673-1.5-1.5-1.5z"
              transform="translate(-1139 -52) translate(1106 44) translate(33 8)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ImageLogin;
