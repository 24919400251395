import React, { FC, useMemo } from 'react';
import { useRouter } from 'next/router';
import { AnimatePresence, motion, Variants } from 'framer-motion';
import styled from 'styled-components';

import { Country, Language } from '~/src/utils/routing';
import {
  getOperatorMenuItems,
  getRenterMenuItems,
  getTopMenuItemsCSCZ,
  getTopMenuItemsENCZ,
  getTopMenuItemsENPL,
  getTopMenuItemsES,
  getTopMenuItemsPLPL,
  getTopMenuItemsSK,
  IMenuItems,
} from '~/src/components/layout/headers/utils';
import { useTypedSelector } from '~/src/redux/store';
import { countrySelector, languageSelector } from '~/src/redux/selectors/settings';
import MenuItemsComponent from '~/src/components/layout/headers/navigation-menu/V1/MenuItems';
import { DesktopMenuItemsList } from '~/src/components/layout/headers/navigation-menu/V2/DesktopMenuItemsList';
import { useCodebase } from 'features/hooks/useCodebase';
import { useSelector } from 'react-redux';
import { userSelector } from '~/src/redux/selectors/user';

const flickerVariant: Variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { delay: 0.12, duration: 0.17, ease: 'easeIn' } },
};

interface MenuItemsControlProps {
  specialMenuType: 'renter' | 'operator' | '';
  isWhiteVersion: boolean;
}

const getCorrectSSRMenu = (cultureToken: string) => {
  switch (cultureToken) {
    case 'cs-cz':
      return getTopMenuItemsCSCZ();
    case 'pl-pl':
      return getTopMenuItemsPLPL();
    case 'en-pl':
      return getTopMenuItemsENPL();
    case 'en-sk':
    case 'sk-sk':
      return getTopMenuItemsSK();
    case 'en-cz':
      return getTopMenuItemsENCZ();
    case 'es-es':
    case 'en-es':
      return getTopMenuItemsES();
    default:
      return [];
  }
};

const clientMenuItems = (
  language: Language,
  country: Country,
  specialMenuType: 'renter' | 'operator' | '',
  isFWC: boolean,
  isStationManager = false
) => {
  // these are superior to language - order matters
  if (specialMenuType === 'renter') return getRenterMenuItems();
  if (specialMenuType === 'operator') return getOperatorMenuItems(isFWC, isStationManager);

  if (country === Country.pl && language === Language.pl) {
    return getTopMenuItemsPLPL();
  }
  if (country === Country.pl && language === Language.en) {
    return getTopMenuItemsENPL();
  }
  if (country === Country.sk) {
    return getTopMenuItemsSK();
  }
  if (country === Country.cz && language === Language.cs) {
    return getTopMenuItemsCSCZ();
  }
  if (country === Country.cz && language === Language.en) {
    return getTopMenuItemsENCZ();
  }
  if (country === Country.es || language === Language.es) {
    return getTopMenuItemsES();
  }
};

export const MenuItemsControl: FC<MenuItemsControlProps> = ({ specialMenuType, isWhiteVersion }) => {
  const country = useTypedSelector(countrySelector);
  const language = useTypedSelector(languageSelector);
  const user = useSelector(userSelector);

  const router = useRouter();
  const isFWC = useCodebase('fwc');

  const menuItems = useMemo(() => {
    if (language && country)
      return clientMenuItems(language, country, specialMenuType, isFWC, user?.customerData?.isStationManager);
    return getCorrectSSRMenu(router.query.cultureToken as string);
  }, [
    country,
    language,
    router.query.cultureToken,
    router.pathname,
    specialMenuType,
    isFWC,
    user?.customerData?.isStationManager,
  ]);

  return <TopNavigation menuItems={menuItems} isWhiteVersion={isWhiteVersion} isSpecialMenu={specialMenuType !== ''} />;
};

export const MemoizedMenuControl = React.memo(MenuItemsControl);

const TopNavigation: FC<{ menuItems: IMenuItems[]; isWhiteVersion: boolean; isSpecialMenu: boolean }> = ({
  menuItems,
  isWhiteVersion,
  isSpecialMenu,
}) => (
  <Container>
    <AnimatePresence exitBeforeEnter={true} presenceAffectsLayout={true}>
      {!isSpecialMenu && (
        <motion.div variants={flickerVariant} initial="hidden" animate="visible" exit="hidden" key="new">
          <DesktopMenuItemsList areLinksInDarkColor={isWhiteVersion} />
        </motion.div>
      )}

      {isSpecialMenu && (
        <motion.div
          style={{ display: 'flex' }}
          variants={flickerVariant}
          initial="hidden"
          animate="visible"
          exit="hidden"
          key="old"
        >
          <MenuItemsComponent menuItems={menuItems} isLightColor={!isWhiteVersion} />
        </motion.div>
      )}
    </AnimatePresence>
  </Container>
);

const Container = styled.div`
  min-width: fit-content;

  > div {
    display: flex;
  }
`;

const Bar = styled(motion.div)`
  width: 100px;
  height: 12px;
  background-color: rgb(183, 183, 183);
  margin-right: 12px;
  border-radius: 8px;
`;
